import React from 'react';
import OneForYouImage from 'src/assets/images/dashboard/for-you.svg';
import ForYouVoucherHandImage from 'src/assets/images/dashboard/for-you-voucher-hand.svg';
import EarnQuickPhoneImage from 'src/assets/images/dashboard/earn-quick-phone.svg';
import EarnQuickSurbeyImage from 'src/assets/images/dashboard/earn-quick-surbey.svg';

const RightContent = () => {
  return (
    <div className="dashboard_training_right_content">
      <div className="dashboard_training_right_content_extra_container">
        <div className="dashboard_training_right_content_extra_header">
          <img src={OneForYouImage} alt="" />
          <span>Earn extra imali with 1ForYou!</span>
        </div>
        <div className="dashboard_training_right_content_extra_description">
          When your customer downloads the 1ForYou app, registers and tops up R50, you earn imali!
        </div>
        <div className="dashboard_training_right_content_start_training_btn" role={'button'}>
          Read More
        </div>
        <div className="dashboard_training_right_content_extra_bottom_circle" />
        <img
          src={ForYouVoucherHandImage}
          alt=""
          className="dashboard_training_right_content_bottom_image"
        />
      </div>
      <div className="dashboard_training_right_content_extra_container dashboard_training_right_content_extra_earn_quick">
        <div className="dashboard_training_right_content_extra_header">
          <img src={EarnQuickSurbeyImage} alt="" />
          <span>Earn quick cash with our online surveys</span>
        </div>
        <div className="dashboard_training_right_content_extra_description">
          Earn cash when you participate in our quick surveys. There are many to choose from!
        </div>
        <div className="dashboard_training_right_content_start_training_btn" role={'button'}>
          Start training now
        </div>
        <div className="dashboard_training_right_content_extra_bottom_circle" />
        <img
          src={EarnQuickPhoneImage}
          alt=""
          className="dashboard_training_right_content_bottom_image"
        />
      </div>
    </div>
  );
};

export default RightContent;
