import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/api';

const initialState = {
  loading: 'idle',
  tier: null,
  error: null
};

export const fetchTier = createAsyncThunk('Tier', async (tierID, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PROXY_API_URL}api/proxy_get/Tier/${tierID}`
    );
    return response.data;
  } catch (error) {
    return rejectWithValue({
      data: error.response.data,
      status: error.response.status
    });
  }
});

export const taskSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      state.user = null;
      localStorage.clear();
      action.payload.history.push('/auth/login/cellphone');
    }
  },
  extraReducers: {
    [fetchTier.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    },
    [fetchTier.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.tier = action.payload;
      }
    },
    [fetchTier.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.user = null;
        state.error = action.payload;
      }
    }
  }
});

export default taskSlice.reducer;
