import React from 'react';
import { makeStyles } from '@mui/styles';
import footerLogo from 'src/assets/images/footer-logo.svg';
import { Container } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    position: 'relative',
    zIndex: 1
  },
  container: {
    paddingLeft: 12,
    paddingRight: 12,
    position: 'relative',
    zIndex: 10,
    display: 'flex',
    columnGap: 8,
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('mobile')]: {
      height: 130,
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      height: 68
    }
  },
  logo: {
    [theme.breakpoints.up('mobile')]: {
      width: 117,
      height: 30
    },
    [theme.breakpoints.up('sm')]: {
      width: 79,
      height: 20
    },
    [theme.breakpoints.up('desktop')]: {
      width: 106,
      height: 27
    }
  },
  logoContainer: {
    position: 'relative',
    [theme.breakpoints.up('mobile')]: {
      marginTop: 20,
      marginBottom: 14
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 4
    },
    [theme.breakpoints.up('desktop')]: {
      marginLeft: 0
    }
  },
  footerText: {
    color: theme.palette.gray.secondary,
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 400,
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('mobile')]: {
      justifyContent: 'flex-start',
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      gap: '2px',
      flexDirection: 'row'
    },
    letterSpacing: '-0.01em'
  },
  rightSpace: {
    [theme.breakpoints.up('sm')]: {
      width: 106
    },
    [theme.breakpoints.up('desktop')]: {
      width: 79
    }
  },
  underline: {
    textDecoration: 'underline',
    color: '#5B5B62'
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="xl">
        <div className={classes.container}>
          <div className={classes.logoContainer}>
            <img src={footerLogo} className={classes.logo} alt="logo" />
          </div>
          <div className={classes.footerText}>
            <div>© 2022 Go121 powered by Flash. </div>
            <div>
              Visit the{' '}
              <a className={classes.underline} href="https://go121.co.za/">
                Go121 website{' '}
              </a>
              for more information
            </div>
          </div>
          <div className={classes.rightSpace}></div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
