import React from 'react';
import { useState } from 'react';
import ChecklistGroupItem from './GroupItem';
import CheckGreenIcon from 'src/assets/icons/check-green.svg';
import { useEffect } from 'react';

const ChecklistImmediateGroupWrapper = ({ group, onCompleteToggle }) => {
  const [checkedItem, setCheckedItem] = useState({});

  const handleCheck = (index) => {
    if (checkedItem[index]) {
      setCheckedItem({ ...checkedItem, [index]: null });
    } else {
      setCheckedItem({ ...checkedItem, [index]: true });
    }
  };

  useEffect(() => {
    let isCompleted = true;
    group.lessonStepItemGroupItems.forEach((itemGroupItem, index) => {
      if (!checkedItem[index]) {
        isCompleted = false;
      }
    });
    if (isCompleted) {
      onCompleteToggle(group.lessonStepItemGroupId, true);
    } else {
      onCompleteToggle(group.lessonStepItemGroupId, false);
    }
  }, [checkedItem, group]);

  return (
    <>
      <div className="training_course_lesson_message message_success">
        <img src={CheckGreenIcon} alt="Icon" className="training_course_lesson_message_icon" />
        <pre className="training_course_lesson_message_text">Tick off the content as you go!</pre>
      </div>
      {group.lessonStepItemGroupItems.map((itemGroupItem, key) => (
        <ChecklistGroupItem
          item={itemGroupItem}
          key={key}
          onChange={() => handleCheck(key)}
          checked={checkedItem[key]}
        />
      ))}
    </>
  );
};

export default ChecklistImmediateGroupWrapper;
