import React from 'react';
import Content from 'src/components/Dashboard/Settings/Content';
import RightContent from 'src/components/Dashboard/Settings/RightContent';

const Settings = () => {
  return (
    <>
      <Content />
      <RightContent />
    </>
  );
};

export default Settings;
