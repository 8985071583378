import React from 'react';
import CellPhoneContent from 'src/components/Auth/Login/CellphoneContent';
import AuthLayout from 'src/layout/Auth';

const Home = () => {
  return (
    <AuthLayout>
      <CellPhoneContent />
    </AuthLayout>
  );
};

export default Home;
