import React from 'react';

export const PrimaryButton = ({ type = 'button', text, className, noIcon, onClick, disabled }) => {
  return (
    <button
      type={type}
      className={`btn btn_primary ${className}`}
      style={{ justifyContent: noIcon ? 'center' : 'space-between' }}
      onClick={onClick}
      disabled={disabled}>
      <span>{text}</span>
      {!noIcon && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
          <path
            d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18"
            stroke="#FFBF00"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </button>
  );
};

export const SecondaryButton = ({ text, className, noIcon, onClick, disabled }) => {
  return (
    <button
      className={`btn btn_secondary ${className}`}
      style={{ justifyContent: noIcon ? 'center' : 'space-between' }}
      onClick={onClick}
      disabled={disabled}>
      <span>{text}</span>
      {!noIcon && (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 24" fill="none">
          <path
            d="M4.88892 12H19.1111M19.1111 12L13.4971 6M19.1111 12L13.4971 18"
            stroke="#FFBF00"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </button>
  );
};
