import React from 'react';
import { useEffect } from 'react';
import MenuIcon from 'src/assets/icons/menu.svg';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { hideMenu, menuVisible, showMenu } from 'src/store/setting/settingSlice';
import SideBar from './SideBar';

const Header = ({ title }) => {
  const isOpenMenu = useAppSelector(menuVisible);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpenMenu) {
      document.getElementById('root').classList.add('position-fixed');
    } else {
      document.getElementById('root').classList.remove('position-fixed');
    }
  }, [isOpenMenu]);

  useEffect(() => {
    dispatch(hideMenu());
  }, []);

  return (
    <div className="dashboard_layout_header">
      <div className="dashboard_layout_header_left">
        <button className="dashboard_layout_header_menu_btn" style={{ visibility: 'hidden' }}>
          <img src={MenuIcon} alt="" className="training_layout_header_menu_icon" />
        </button>
      </div>
      <div className="dashboard_layout_header_title">{title}</div>
      <div className="dashboard_layout_header_right">
        <button className="dashboard_layout_header_menu_btn" onClick={() => dispatch(showMenu())}>
          <img src={MenuIcon} alt="" className="training_layout_header_menu_icon" />
        </button>
      </div>
      <div className={`dashboard_layout_header_sidebar_wrapper ${isOpenMenu ? 'show' : 'hide'}`}>
        <SideBar menu />
      </div>
    </div>
  );
};

export default Header;
