import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Avatar from 'src/components/Common/Avatar';
import AvatarPlaceholderImage from 'src/assets/images/dashboard/avatar-placeholder.svg';
import SideBarProgressBar from 'src/components/Dashboard/SideBarProgressBar';
import DashboardIcon from 'src/assets/images/dashboard/dashboard-icon.svg';
import DashboardIconActive from 'src/assets/images/dashboard/dashboard-icon-active.svg';
import TrainingCourseIcon from 'src/assets/images/dashboard/training-course-icon.svg';
import CloseIconWhite from 'src/assets/images/training/close-icon-white.svg';
import TrainingCourseIconActive from 'src/assets/images/dashboard/training-course-icon-active.svg';
import ReportsIcon from 'src/assets/images/dashboard/reports-icon.svg';
import ReportsIconActive from 'src/assets/images/dashboard/reports-icon-active.svg';
import QRIcon from 'src/assets/images/dashboard/qr-icon.svg';
import QRIconActive from 'src/assets/images/dashboard/qr-icon-active.svg';
import ProfileIcon from 'src/assets/images/dashboard/profile-icon.svg';
import ProfileIconActive from 'src/assets/images/dashboard/profile-icon-active.svg';
import SettingIcon from 'src/assets/images/dashboard/setting-icon.svg';
import SettingIconActive from 'src/assets/images/dashboard/setting-icon-active.svg';
import HelpIcon from 'src/assets/images/dashboard/help-icon.svg';
import HelpIconActive from 'src/assets/images/dashboard/help-icon-active.svg';
import LogoutIcon from 'src/assets/images/dashboard/logout-icon.svg';
import SidebarMenuItem from 'src/components/Dashboard/SidebarMenuItem';
import { useAppDispatch } from 'src/hooks';
import { hideMenu, showLogoutModal } from 'src/store/setting/settingSlice';

const SideBar = ({ menu }) => {
  const menuItems = [
    {
      path: '/dashboard',
      title: 'Dashboard',
      icon: DashboardIcon,
      activeIcon: DashboardIconActive
    },
    {
      path: '/training-course',
      title: 'Training courses',
      icon: TrainingCourseIcon,
      activeIcon: TrainingCourseIconActive
    },
    {
      path: '/reports',
      title: 'Reports',
      icon: ReportsIcon,
      activeIcon: ReportsIconActive
    },
    {
      path: '/profile',
      title: 'Profile',
      icon: ProfileIcon,
      activeIcon: ProfileIconActive
    },
    {
      path: '/qr-codes',
      title: 'QR Codes',
      icon: QRIcon,
      activeIcon: QRIconActive
    },
    {
      path: '/settings',
      title: 'Settings',
      icon: SettingIcon,
      activeIcon: SettingIconActive
    },
    { path: '/help', title: 'Help', icon: HelpIcon, activeIcon: HelpIconActive }
  ];

  const location = useLocation();
  const dispatch = useAppDispatch();

  const isActive = (path) => {
    return path == location.pathname;
  };

  const handleLogout = () => {
    dispatch(showLogoutModal());
  };

  // Temporary solution before complete authentication start
  const [firstName, setFirstName] = useState('Kabelo');
  const [surName, setSurName] = useState('Nkosi');
  const [trainingPoints, setTrainingPoints] = useState(0);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if (accessToken === 'true' && user) {
      setFirstName(user.firstName);
      setSurName(user.surname);
      setTrainingPoints(user.trainingPoints);
    }
  }, []);
  // Temporary solution before complete authentication end

  return (
    <div className="dashboard_layout_sidebar">
      <div className="dashboard_layout_sidebar_content">
        <div className="dashboard_layout_sidebar_content_header">
          <Avatar
            src={AvatarPlaceholderImage}
            showBadge
            badge="0"
            className="dashboard_layout_sidebar_content_header_avatar"
          />
          <div className="dashboard_layout_sidebar_content_header_name">
            {firstName} {surName}
          </div>
          <div className="dashboard_layout_sidebar_content_header_total_point">
            Total points: {trainingPoints}
          </div>
          <SideBarProgressBar total={4} done={0} />
          {menu && (
            <button
              className="dashboard_layout_sidebar_close_btn"
              onClick={() => dispatch(hideMenu())}>
              <img src={CloseIconWhite} alt="" className="dashboard_layout_sidebar_close_icon" />
            </button>
          )}
        </div>
        <div className="dashboard_layout_sidebar_content_body">
          <div className="dashboard_layout_sidebar_menu_lists">
            {menuItems.map((menu, index) => (
              <SidebarMenuItem {...menu} active={isActive(menu.path)} key={index} />
            ))}
            <div className={`dashboard_layout_sidebar_menu_list`} onClick={() => handleLogout()}>
              <div className="dashboard_layout_sidebar_menu_list_icon">
                <img src={LogoutIcon} alt="Icon" />
              </div>
              <div className="dashboard_layout_sidebar_menu_list_text">Log out</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
