import React, { useEffect, useState } from 'react';
import Notification from '../../Notification';
import Logo from 'src/assets/images/login pages/go121-logo.png';
import BackgroundDesktop from 'src/assets/images/login pages/background-desktop.png';
import BackgroundTablet from 'src/assets/images/login pages/background-tablet.png';
import BackgroundMobile from 'src/assets/images/login pages/background-mobile.png';
import ReactCodeInput from 'react-verification-code-input';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'src/utils/api';
import { formatPhoneNumber } from 'src/utils/formatstring';
import OtpNotifierModal from './OtpNotifierModal';
import { useAppDispatch } from 'src/hooks';
import { VerifyOtp } from 'src/store/auth/authSlice';

const OTPContent = () => {
  const proxyApi = process.env.REACT_APP_PROXY_API_URL;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading, setIsLoading] = useState(false);
  const [otpCode, setOtpCode] = useState('');
  const [visible, setVisible] = useState(false);
  const [isResentCode, setIsResentCode] = useState(false);
  const [resendLoading, setIsResendLoading] = useState(false);

  const [notification, setNotification] = useState({
    text: '',
    type: 'success',
    visible: false
  });

  useEffect(() => {
    if (!location.state?.otpId || !location.state?.agentId) {
      history.push('/auth/login/cellphone');
    }
    setTimeout(() => {
      setVisible(true);
    }, 60000);
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);
    dispatch(
      VerifyOtp({ agentId: location.state?.agentId, otpId: location.state?.otpId, pin: otpCode })
    )
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setNotification({
          text: 'Invalid OTP entered. Please try again.',
          type: 'warn',
          visible: true
        });
      });
  };

  const reSendOTP = () => {
    setVisible(false);
    setIsResendLoading(true);
    axios
      .post(`${proxyApi}api/proxy_post/Agent/${location.state?.agentId}/OtpLogin`)
      .then(() => {
        setIsResentCode(true);
        setIsResendLoading(false);
      })
      .catch((error) => {
        setIsResendLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="nabar-section">
      <div className="login_otp_form_notification_container">
        <div className="grid">
          {notification.visible && (
            <Notification
              type={notification.type}
              text={notification.text}
              onClose={() => setNotification({ ...notification, visible: false })}
            />
          )}
        </div>
      </div>
      <div
        className={`login_otp_form grid ${notification.visible ? 'login_otp_form_padding' : ''}`}>
        <div className="login_otp_form_left_side_form">
          <div className="login_otp_form_container">
            <div className="login_otp_form_header">
              <img src={Logo} alt="" className="login_otp_form_logo" />
              <div className="login_otp_form_step">AGENT</div>
            </div>
            <div className="login_otp_form_title">Enter one-time pin</div>
            <div className="login_otp_form_group">
              <p className="otp_form_description">
                We’ve sent a one-time PIN to the cellphone number{' '}
                {formatPhoneNumber(location.state?.phoneNumber, true)}.
              </p>
            </div>
            <div className="login_otp_form_group">
              <ReactCodeInput
                type="number"
                fields={6}
                className={`login_otp_input_container`}
                onChange={(e) => setOtpCode(e)}
              />
            </div>
            <div className="login_otp_form_group">
              <div className="login_otp_resend_btn" onClick={() => reSendOTP()}>
                <span>Resend OTP</span>
                {resendLoading && (
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50C100 22.3913 77.6087 0 50 0C22.3913 0 0 22.3913 0 50M8.47826 50C8.47826 27.1739 26.9565 8.47826 50 8.47826C73.0435 8.47826 91.5217 27.1739 91.5217 50"
                      fill="currentColor">
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                )}
                {!resendLoading && isResentCode && <span style={{ color: '#FF4600' }}>Sent!</span>}
              </div>
            </div>
            <div className="login_otp_form_group">
              <button
                type="submit"
                className="login_otp_form_submit_btn"
                onClick={() => handleSubmit()}
                disabled={otpCode.length !== 6 || loading}>
                <span>Next</span>
                {loading ? (
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50C100 22.3913 77.6087 0 50 0C22.3913 0 0 22.3913 0 50M8.47826 50C8.47826 27.1739 26.9565 8.47826 50 8.47826C73.0435 8.47826 91.5217 27.1739 91.5217 50"
                      fill="white">
                      <animateTransform
                        attributeName="transform"
                        attributeType="XML"
                        type="rotate"
                        dur="1s"
                        from="0 50 50"
                        to="360 50 50"
                        repeatCount="indefinite"
                      />
                    </path>
                  </svg>
                ) : (
                  <svg
                    width="22"
                    height="24"
                    viewBox="0 0 22 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18"
                      stroke="#FFBF00"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="login_otp_form_footer">
              <a href="/auth/login/cellphone">Change cellphone number</a>
            </div>
          </div>
        </div>
        <div className="login_otp_form_background_img_desktop">
          <img src={BackgroundDesktop} alt="loading" />
        </div>
        <div className="login_otp_form_background_img_tablet">
          <img src={BackgroundTablet} alt="loading" />
        </div>
        <div className="login_otp_form_background_img_mobile">
          <img src={BackgroundMobile} alt="loading" />
        </div>
      </div>
      <OtpNotifierModal
        visible={visible}
        cellphone={formatPhoneNumber(location.state?.phoneNumber, true)}
        onClose={() => setVisible(false)}
        onResend={() => reSendOTP()}
      />
    </div>
  );
};

export default OTPContent;
