import React from 'react';
import ArrowLeftRedIcon from 'src/assets/icons/arrow-left-red.svg';

const LocationCard = (props) => {
  return (
    <div
      className={`task_find_kiosk_main_location_card ${props.active ? 'active' : ''}`}
      onClick={() => props.onClick()}>
      <div className="task_find_kiosk_main_location_card_top">
        <div className="task_find_kiosk_main_location_card_title">{props.name}</div>
        <div
          className="task_find_kiosk_main_location_card_description"
          dangerouslySetInnerHTML={{ __html: props.address }}></div>
      </div>
      <div className="task_find_kiosk_main_location_card_action">
        <span>Get directions</span>
        <img src={ArrowLeftRedIcon} alt="" />
      </div>
    </div>
  );
};

export default LocationCard;
