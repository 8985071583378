import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    circularStd: 'Circular Std, sans-serif !important',
    azoSans: 'Azo Sans, sans-serif !important'
  },
  breakpoints: {
    values: {
      mobile: 0,
      sm: 480,
      tablet: 600,
      desktop: 840,
      md: 1024,
      lg: 1200,
      xl: 1600
    }
  },
  palette: {
    blue: {
      primary: '#1C1CA8',
      secondary: '#055CF5',
      light: '#69BFED',
      third: '#CDDEFD'
    },
    white: {
      primary: '#FFFFFF',
      secondary: '#FFFFFF33'
    },
    orange: {
      primary: '#FF4600'
    },
    red: {
      secondary: '#FF1200',
      dark: '#E90808',
      light: '#FFD0CC'
    },
    yellow: {
      secondary: '#FFBF00',
      third: '#FFC200'
    },
    green: {
      secondary: '#41AD49',
      light: '#D9EFDB',
      third: '#C2F83C'
    },
    pink: {
      secondary: '#F20066'
    },
    gray: {
      main: '#EEEEF0',
      secondary: '#5B5B62',
      light: '#8F9095',
      third: '#A9AAAE',
      fource: '#C4C4C4',
      fifth: '#DDDEE1',
      sixth: '#878A91',
      seventh: '#666666'
    },
    black: {
      main: '#27272F',
      dark: '#0D0D16'
    }
  }
});

export default theme;
