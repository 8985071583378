import React from 'react';

const RadioItem = ({ name, text, selected, onClick, value }) => {
  return (
    <div className={`training_quiz_question_radio_list ${selected ? 'selected' : ''}`}>
      <input
        type="radio"
        name={name}
        id={`multi-choice-single-${value}`}
        value={value}
        onChange={() => onClick && onClick(name)}
      />
      <label htmlFor={`multi-choice-single-${value}`}>{text}</label>
    </div>
  );
};

export default RadioItem;
