import React from 'react';
import { Link } from 'react-router-dom';
import ArrowBlueIcon from 'src/assets/images/training/arrow-blue-icon.svg';
import LockIcon from 'src/assets/images/training/lock-icon.svg';
import QuizIcon from 'src/assets/images/training/quiz-icon.svg';
import { useAppSelector } from 'src/hooks';

const MenuItem = ({ id, title, name, type = 'locked', quiz }) => {
  const course = useAppSelector((state) => state.training.currentCourse);
  const icon = {
    ongoing: ArrowBlueIcon,
    locked: LockIcon,
    quiz: QuizIcon
  };

  return (
    <div className={`training_layout_sidebar_lesson type_${type}`}>
      <div className="training_layout_sidebar_lesson_body">
        <div className="training_layout_sidebar_lesson_title">{title}</div>
        <span className="training_layout_sidebar_lesson_name">{name}</span>
      </div>
      <Link
        to={
          quiz
            ? `/training/course/${course.courseCode}/quiz-start`
            : `/training/course/${course.courseCode}/lesson/${id}`
        }
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }}>
        <img src={icon[type]} alt="Icon" className="training_layout_sidebar_lesson_icon" />
      </Link>
    </div>
  );
};

export default MenuItem;
