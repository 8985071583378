import React from 'react';
import { Link } from 'react-router-dom';
import ArrowIcon from 'src/assets/images/training/arrow-icon.svg';

const BreadCrumb = ({ items }) => {
  return (
    <div className="training_layout_breadcrumb grid">
      {items?.length &&
        items.map((item, index) => (
          <Link
            to={item.path ? item.path : '#'}
            key={index}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <div
              className={`training_layout_breadcrumb_item ${
                index === items.length - 1 ? 'active' : ''
              }`}>
              <span className="training_layout_breadcrumb_label">{item.title}</span>
              {index < items.length - 1 && (
                <img
                  src={ArrowIcon}
                  alt="Arrow"
                  className="training_layout_breadcrumb_arrow_icon"
                />
              )}
            </div>
          </Link>
        ))}
    </div>
  );
};

export default BreadCrumb;
