import React from 'react';
import AccordinGroupItem from './GroupItem';

const AccordinGroupWrapper = ({ group }) => {
  return (
    <div className="training_course_lesson_accordin_list">
      {group.lessonStepItemGroupItems.map((itemGroupItem, key) => (
        <AccordinGroupItem item={itemGroupItem} key={key} />
      ))}
    </div>
  );
};

export default AccordinGroupWrapper;
