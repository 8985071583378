import React from 'react';
import CircleRedIcon from 'src/assets/images/training/close-circle-red.svg';
import TaskPointIcon from 'src/assets/images/tasks/task-point.svg';
import { SecondaryButton } from 'src/components/Common/Button';

const FindKioskModal = ({ visible, onCloseModal }) => {
  return (
    <div className="task_find_kiosk_modal" style={{ display: visible ? 'block' : 'none' }}>
      <div className="task_find_kiosk_modal_backdrop" onClick={() => onCloseModal()} />
      <div className="task_find_kiosk_modal_body">
        <div className="task_find_kiosk_modal_content">
          <button className="task_find_kiosk_modal_close_btn" onClick={() => onCloseModal()}>
            <img src={CircleRedIcon} alt="Close" />
          </button>
          <img src={TaskPointIcon} alt="point" className="task_find_kiosk_modal_img" />
          <div className="task_find_kiosk_modal_title">Find a kiosk</div>
          <div className="task_find_kiosk_modal_description">
            Find your nearest Flash Kiosk that has stock or alternatively, select the kiosk where
            you would like your starter kit to be delivered to (this may take up to 2 weeks).
            <br />
            <br />
            Remember to take your cellphone with you that you used for registration, as you will be
            required to give an OTP to the Flash kiosk staff when they ask for it.
          </div>
          <SecondaryButton
            className="task_find_kiosk_modal_btn"
            text="Find a Kiosk"
            onClick={() => onCloseModal()}
          />
        </div>
      </div>
    </div>
  );
};

export default FindKioskModal;
