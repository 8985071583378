import React, { useState } from 'react';
import { baseURL } from 'src/utils/api';
import UpIcon from 'src/assets/images/dropdown-up-icon.svg';
import DownIcon from 'src/assets/images/dropdown-down-icon.svg';
import { markDownFormat } from 'src/utils/formatstring';

const AccordinGroupItem = ({ item }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="training_course_lesson_accordion_item">
        <div
          className={`training_course_lesson_accordion_item_select ${open ? 'open' : ''}`}
          onClick={() => setOpen(!open)}>
          <div className="training_course_lesson_accordion_item_select_text">
            <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.title) }} />
          </div>
          <img
            src={open ? UpIcon : DownIcon}
            className="training_course_lesson_accordion_item_select_icon"
            alt="Icon"
          />
        </div>
        {open && (
          <div
            className={`training_course_lesson_accordion_item_body ${
              item.desktopImage && item.desktopImagePlacement?.toLowerCase()
            }`}>
            <div className="training_course_lesson_accordion_item_body_text">
              <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.body) }} />
            </div>
            {item.desktopImage && (
              <img
                src={`${baseURL}image/${item.desktopImage}`}
                alt="Icon"
                className="training_course_lesson_accordion_item_body_image"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AccordinGroupItem;
