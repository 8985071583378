import React from 'react';

const SideBarProgressBar = ({ total = 4, done = 0 }) => {
  return (
    <div className="dashboard_layout_sidebar_progress_bar">
      {[...Array(total)].map((e, index) => (
        <div
          className={`dashboard_layout_sidebar_progress_item ${index < done ? 'active' : ''}`}
          key={index}
        />
      ))}
    </div>
  );
};

export default SideBarProgressBar;
