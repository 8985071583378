import React from 'react';

const SizeItem = ({ active, text, onSelect }) => {
  return (
    <div
      className={`task_buy_starter_kit_size_item ${active ? 'active' : ''}`}
      onClick={() => onSelect()}>
      {text}
    </div>
  );
};

export default SizeItem;
