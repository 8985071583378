import React from 'react';
import PropTypes from 'prop-types';
import { baseURL } from 'src/utils/api';
import { Link } from 'react-router-dom';

const CourseItem = ({ task }) => {
  const ActionButton = ({ status, courseCode }) => {
    switch (status) {
      case 'go': {
        return (
          <Link
            to={`/training/course/${courseCode}/dashboard`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <div className="course_item_action_btn go_btn">
              <span>Go!</span>
              <img src={'/assets/icons/yellow-arrow-right.svg'} alt="Left" />
            </div>
          </Link>
        );
      }
      case 'done': {
        return (
          <Link
            to={`/training/course/${courseCode}/dashboard`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <div className="course_item_action_btn done_btn">
              <span>Done</span>
              <img src={'/assets/icons/yellow_checked_icon.svg'} alt="Left" />
            </div>
          </Link>
        );
      }
      case 'locked': {
        return (
          <Link
            to={`/training/course/${courseCode}/dashboard`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <div className="course_item_action_btn locked_btn">
              <span>Locked</span>
              <img src={'/assets/icons/green-lock-icon.svg'} alt="Left" />
            </div>
          </Link>
        );
      }
      case 'hold': {
        return (
          <Link
            to={`/training/course/${courseCode}/dashboard`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <div className="course_item_action_btn hold_btn">
              <span>On hold</span>
              <img src={'/assets/icons/yellow-spread-icon.svg'} alt="Left" />
            </div>
          </Link>
        );
      }
      case 'retry': {
        return (
          <Link
            to={`/training/course/${courseCode}/dashboard`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <div className="course_item_action_btn retry_btn">
              <span>Retry</span>
              <img src={'/assets/icons/yellow-arrow-right.svg'} alt="Left" />
            </div>
          </Link>
        );
      }
      case 'Completed and passed': {
        return (
          <Link
            to={`/training/course/${courseCode}/dashboard`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <div className="course_item_action_btn review_btn">
              <span>Review</span>
              <img src={'/assets/icons/yellow_checked_icon.svg'} alt="Left" />
            </div>
          </Link>
        );
      }
      case 'Course content in progress': {
        return (
          <Link
            to={`/training/course/${courseCode}/dashboard`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <div className="course_item_action_btn inprogress_btn">
              <span>In progress</span>
              <img src={'/assets/icons/yellow-spread-icon.svg'} alt="Left" />
            </div>
          </Link>
        );
      }
      default: {
        return (
          <Link
            to={`/training/course/${courseCode}/dashboard`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <div className="course_item_action_btn go_btn">
              <span>Go!</span>
              <img src={'/assets/icons/yellow-arrow-right.svg'} alt="Left" />
            </div>
          </Link>
        );
      }
    }
  };

  return (
    <div className="course_item">
      <div className="course_item_divider"></div>
      <div className="course_item_container">
        <div className="course_item_icon" style={{ backgroundColor: task.defaultBackgroundColour }}>
          <img src={`${baseURL}image/${task.defaultIcon}`} alt="Icon" />
        </div>
        <div className="course_item_right_container">
          <div className="course_item_text_container">
            <div className="course_item_top_title">{task.points} Points earned</div>
            <div className="course_item_title">{task.name}</div>
            <div className="course_item_description">{task.body}</div>
          </div>
          <ActionButton status={task.status} courseCode={task.courseCode} />
        </div>
      </div>
    </div>
  );
};

CourseItem.propTypes = {
  task: PropTypes.any
};

export default CourseItem;
