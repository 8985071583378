import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { CircularProgress } from '@mui/material';
import NumberOneImage from 'src/assets/images/dashboard/number-one.svg';
import { PrimaryButton } from 'src/components/Common/Button';
import PrimaryInput from 'src/components/Common/Input';
import DeleteProfileModal from './DeleteProfileModal';
import { useAppSelector, useAppDispatch } from 'src/hooks';
import { findAgentById, putAgentById } from 'src/store/auth/authSlice';
import * as Yup from 'yup';
import AutoComplete from './AutoComplete';
import { LoadScript } from '@react-google-maps/api';

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('Please enter First name'),
  nickname: Yup.string().required('Please enter Nickname'),
  surname: Yup.string().required('Please enter Last name'),
  cellphone: Yup.string()
    .max(10, 'Please enter a valid 10-digit number.')
    .min(10, 'Please enter a valid 10-digit number.')
    .matches(new RegExp('[0-9]{10}'), 'Please enter a valid 10-digit number.')
    .test('no-leading-zero', 'Cellphone number should start with 0', (value) => {
      return Boolean(value && value.startsWith('0'));
    })
    .required('Please enter a valid 10-digit number.')
    .not(['0000000000'], 'Please enter a valid 10-digit number.'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address')
});

const Content = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const [visible, setVisible] = useState(false);
  const [tierID, setTierID] = useState(1);

  useEffect(() => {
    if (user) {
      dispatch(findAgentById(user.id));
      setTierID(user.tierId);
    }
  }, []);

  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues: {
      firstName: user.firstName,
      nickname: user.nickname,
      surname: user.surname,
      email: user.email,
      cellphone: user.cellphone.startsWith('27') ? '0' + user.cellphone.slice(2) : user.cellphone
    },
    onSubmit: (data) => {
      dispatch(
        putAgentById({
          userId: user.id,
          formData: { ...data, oAuthClientId: 0, oAuthClientUserId: 0 }
        })
      );
    }
  });

  return (
    <div className="profile_center_content">
      <div className="profile_center_content_statistic_container">
        <div className="profile_center_content_top_user_level_container">
          <div className="profile_center_content_top_user_level">Level {tierID - 1}</div>
        </div>
        <div className="profile_center_content_user_level_mobile">
          <div className="profile_center_content_user_level">Tier {tierID - 1}</div>
        </div>
        <div className="profile_center_content_statistic_container_div">
          <div className="profile_center_content_statistic_percent_container">
            <CircularProgress
              className="profile_center_content_bg_circular_pg"
              thickness={4}
              variant="determinate"
              value={100}
              size={137}
            />
            <div className="profile_center_content_text_pg_container">
              <div className="profile_center_content_statistic_percent_text">0</div>
              <div className="profile_center_content_statistic_percent_symbol">%</div>
            </div>
          </div>
          <div className="profile_center_content_static_right_container">
            <div className="profile_center_content_static_text_container">
              <div className="profile_center_content_user_level">Tier {tierID - 1}</div>
              <div className="profile_center_content_points_earned">
                0/300
                <br className="profile_center_content_mobile_enter" /> Points earned
              </div>
              <div className="profile_center_content_tasks_done">
                0/7
                <br className="profile_center_content_mobile_enter" /> Tasks done
              </div>
            </div>
            <div className="profile_center_content_statistic_icon_container">
              <img src={NumberOneImage} alt="Number one" />
            </div>
          </div>
        </div>
      </div>
      <div className="profile_center_content_form_container">
        <div className="profile_center_content_form_title">My Profile</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="profile_center_content_form_row">
            <div className="profile_center_content_form_col">
              <div className="profile_center_content_form_group">
                <label htmlFor="firstname" className="profile_center_content_form_label">
                  First Name
                </label>
                <PrimaryInput
                  id="firstname"
                  value={user.firstName}
                  placeholder=""
                  className={'profile_center_content_form_control'}
                  locked={user.tierId == '1' ? true : false}
                  lockTooltip="Reach Tier 1 to gain edit access"
                  {...formik.getFieldProps('firstName')}
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="profile_center_content_form_error">{formik.errors.firstName}</div>
                )}
              </div>
            </div>
            <div className="profile_center_content_form_col">
              <div className="profile_center_content_form_group">
                <label htmlFor="lastname" className="profile_center_content_form_label">
                  Last Name
                </label>
                <PrimaryInput
                  id="lastname"
                  value="Nkosi"
                  placeholder=""
                  className={'profile_center_content_form_control'}
                  locked={user.tierId == '1' ? true : false}
                  lockTooltip="Reach Tier 1 to gain edit access"
                  {...formik.getFieldProps('surname')}
                />
                {formik.touched.surname && formik.errors.surname && (
                  <div className="profile_center_content_form_error">{formik.errors.surname}</div>
                )}
              </div>
            </div>
          </div>
          <div className="profile_center_content_form_row">
            <div className="profile_center_content_form_col">
              <div className="profile_center_content_form_group">
                <label htmlFor="nickname" className="profile_center_content_form_label">
                  Nickname
                </label>
                <PrimaryInput
                  id="nickname"
                  value="Kabelo"
                  placeholder=""
                  className={'profile_center_content_form_control'}
                  {...formik.getFieldProps('nickname')}
                />
                {formik.touched.nickname && formik.errors.nickname && (
                  <div className="profile_center_content_form_error">{formik.errors.nickname}</div>
                )}
              </div>
            </div>
          </div>
          <div className="profile_center_content_form_row">
            <div className="profile_center_content_form_col">
              <div className="profile_center_content_form_group">
                <label htmlFor="contact_number" className="profile_center_content_form_label">
                  Contact Number
                </label>
                <PrimaryInput
                  id="contact_number"
                  placeholder=""
                  className={'profile_center_content_form_control'}
                  {...formik.getFieldProps('cellphone')}
                />
                {formik.touched.cellphone && formik.errors.cellphone && (
                  <div className="profile_center_content_form_error">{formik.errors.cellphone}</div>
                )}
              </div>
            </div>
          </div>
          <div className="profile_center_content_form_row">
            <div className="profile_center_content_form_col">
              <div className="profile_center_content_form_group">
                <label htmlFor="email" className="profile_center_content_form_label">
                  Email Address
                </label>
                <PrimaryInput
                  id="email"
                  placeholder=""
                  className={'profile_center_content_form_control'}
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="profile_center_content_form_error">{formik.errors.email}</div>
                )}
              </div>
            </div>
          </div>
          <div className="profile_center_content_form_row">
            <div className="profile_center_content_form_col">
              <div className="profile_center_content_form_group">
                <label htmlFor="address" className="profile_center_content_form_label">
                  Address
                </label>
                <LoadScript googleMapsApiKey="AIzaSyDmDAuTgalz6N6pE_phk4ZYcodweRAi1YQ">
                  <AutoComplete value={user.address} locked />
                </LoadScript>
              </div>
            </div>
          </div>
          <div className="profile_center_content_form_row">
            <div className="profile_center_content_form_col">
              <div className="profile_center_content_form_group">
                <label htmlFor="id_type" className="profile_center_content_form_label">
                  ID Type
                </label>
                <PrimaryInput
                  id="id_type"
                  value="South African ID"
                  placeholder=""
                  className={'profile_center_content_form_control'}
                />
              </div>
            </div>
          </div>
          <div className="profile_center_content_form_row">
            <div className="profile_center_content_form_col">
              <div className="profile_center_content_form_group">
                <label htmlFor="id_number" className="profile_center_content_form_label">
                  ID Number
                </label>
                <PrimaryInput
                  id="id_number"
                  value="810609**********"
                  placeholder=""
                  className={'profile_center_content_form_control'}
                />
              </div>
            </div>
          </div>
          <div className="profile_center_content_form_actions">
            <PrimaryButton type="submit" text="Save" className="profile_center_content_form_save" />
            <button className="profile_center_content_form_delete" onClick={() => setVisible(true)}>
              Delete Profile
            </button>
          </div>
        </form>
      </div>
      <DeleteProfileModal visible={visible} onCloseModal={() => setVisible(false)} />
    </div>
  );
};

export default Content;
