import React from 'react';
import ArrowLeftRed from 'src/assets/icons/arrow-left-red.svg';
const Header = ({ title }) => {
  return (
    <div className="task_layout_header">
      <div className="task_layout_header_left">
        <a href="/dashboard">
          <button className="task_layout_header_close_btn">
            <img src={ArrowLeftRed} alt="" className="task_layout_header_close_icon" />
            <span>Exit task</span>
          </button>
        </a>
      </div>
      <div className="task_layout_header_title">{title}</div>
      <div className="task_layout_header_right"></div>
    </div>
  );
};

export default Header;
