import React, { useState } from 'react';
import InfoSuccessIcon from 'src/assets/images/info-success-icon.svg';
import InfoPrimaryIcon from 'src/assets/images/info-primary-icon.svg';
import InfoWarnIcon from 'src/assets/images/Info-warn-icon.svg';
import closeSuccessIcon from 'src/assets/images/close-success-icon.svg';
import closeWarnIcon from 'src/assets/images/arrow-right-warn.svg';
import closePrimaryIcon from 'src/assets/images/close-primary-icon.svg';

const Notification = ({ text, type = 'success', className }) => {
  const [visible, setVisible] = useState(true);
  const icons = {
    success: InfoSuccessIcon,
    primary: InfoPrimaryIcon,
    warn: InfoWarnIcon
  };

  const endIcons = {
    success: closeSuccessIcon,
    primary: closePrimaryIcon,
    warn: closeWarnIcon
  };

  return (
    <div
      className={`notification_container notification_container_${type} ${className || ''}`}
      hidden={!visible}>
      <div className={'notification_prefix_icon'}>
        <img src={icons[type]} alt="" />
      </div>
      <div className={'notification_text'}>{text}</div>
      <div className={'notification_suffix_icon'}>
        <img src={endIcons[type]} alt="" onClick={() => setVisible(false)} />
      </div>
    </div>
  );
};

export default Notification;
