import React from 'react';
import CompleteLessonImg from 'src/assets/images/login pages/account-delete-success.svg';
import CompleteLessonImgMobile from 'src/assets/images/login pages/account-delete-success-mobile.svg';
import FooterLogo from 'src/assets/images/go-auth-footer-logo.png';
import { SecondaryButton } from 'src/components/Common/Button';

const Content = () => {
  return (
    <div className="account_deleted_callback">
      <div className="account_deleted_callback_body">
        <div className="account_deleted_callback_wrapper">
          <img
            src={CompleteLessonImg}
            alt="Image"
            className="account_deleted_callback_img account_deleted_callback_img_desktop"
          />
          <img
            src={CompleteLessonImgMobile}
            alt="Image"
            className="account_deleted_callback_img account_deleted_callback_img_mobile"
          />
          <div className="account_deleted_callback_title">We will be in touch with you shortly</div>
          <div className="account_deleted_callback_actions">
            <a href="https://dev1.agent.instaccess.com" target="_blank" rel="noreferrer">
              <SecondaryButton text="Visit our website" />
            </a>
          </div>
        </div>
      </div>
      <div className="account_deleted_callback_footer">
        <img src={FooterLogo} className="account_deleted_callback_footer_logo" alt="Logo" />
        <div className="account_deleted_callback_footer_text">
          © 2021 Go121 powered by Flash. Visit the <a href="https://go121.co.za/">Go121 website</a>{' '}
          for more information
        </div>
      </div>
    </div>
  );
};

export default Content;
