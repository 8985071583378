import React from 'react';

const CheckBoxItem = ({ name, text, selected, onClick }) => {
  return (
    <div className={`training_quiz_question_chechbox_list ${selected ? 'selected' : ''}`}>
      <input type="checkbox" name={name} id={name} onChange={() => onClick && onClick(name)} />
      <label htmlFor={name}>{text}</label>
    </div>
  );
};

export default CheckBoxItem;
