import React from 'react';

const Body = ({ children }) => {
  return (
    <div className={`nabar-section dashboard_layout_body`}>
      <div className="grid">{children}</div>
    </div>
  );
};

export default Body;
