import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import settingReducer from './setting/settingSlice';
import trainingReducer from './training/trainingSlice';
import taskReducer from './task/taskSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    setting: settingReducer,
    training: trainingReducer,
    task: taskReducer
  }
});
