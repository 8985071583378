import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const TaskItem = ({ task }) => {
  const history = useHistory();
  const ActionButton = ({ status }) => {
    switch (status) {
      case 'go': {
        return (
          <div className="task_item_action_btn go_btn" onClick={() => handleClick()}>
            <span>Go!</span>
            <img src={'/assets/icons/yellow-arrow-right.svg'} alt="Left" />
          </div>
        );
      }
      case 'done': {
        return (
          <div className="task_item_action_btn done_btn">
            <span>Done</span>
            <img src={'/assets/icons/yellow_checked_icon.svg'} alt="Left" />
          </div>
        );
      }
      case 'locked': {
        return (
          <div className="task_item_action_btn locked_btn">
            <span>Locked</span>
            <img src={'/assets/icons/green-lock-icon.svg'} alt="Left" />
          </div>
        );
      }
      case 'hold': {
        return (
          <div className="task_item_action_btn hold_btn">
            <span>On hold</span>
            <img src={'/assets/icons/yellow-spread-icon.svg'} alt="Left" />
          </div>
        );
      }
      case 'retry': {
        return (
          <div className="task_item_action_btn retry_btn">
            <span>Retry</span>
            <img src={'/assets/icons/yellow-arrow-right.svg'} alt="Left" />
          </div>
        );
      }
      case 'review': {
        return (
          <div className="task_item_action_btn review_btn">
            <span>Review</span>
            <img src={'/assets/icons/yellow_checked_icon.svg'} alt="Left" />
          </div>
        );
      }
      default: {
        return (
          <div className="task_item_action_btn go_btn" onClick={() => handleClick()}>
            <span>Go!</span>
            <img src={'/assets/icons/yellow-arrow-right.svg'} alt="Left" />
          </div>
        );
      }
    }
  };

  const handleClick = () => {
    console.log(task.type);
    switch (task.type) {
      case 'Course': {
        break;
      }
      case 'Action: Selfie': {
        history.push('/task/take-selfie');
        break;
      }
      case 'Action: ID': {
        history.push('/task/submit-id');
        break;
      }
      case 'Action: Address': {
        break;
      }
      case 'Action: Starter pack payment': {
        break;
      }
      default: {
        return;
      }
    }
  };

  return (
    <div className="task_item">
      <div className="task_item_divider"></div>
      <div className="task_item_container">
        <div
          className="task_item_index"
          style={{
            backgroundColor: '#055CF5'
          }}>
          {task.order}
        </div>
        <div className="task_item_right_container">
          <div className="task_item_text_container">
            {task.topTitle && <div className="task_item_top_title">{task.topTitle}</div>}
            <div className="task_item_title">{task.title}</div>
            <div className="task_item_description">{task.description}</div>
          </div>
          <ActionButton
            status={task.type == 'Action: ID' ? 'go' : task.isLocked ? 'locked' : task.status}
          />
        </div>
      </div>
    </div>
  );
};

TaskItem.propTypes = {
  task: PropTypes.any
};

export default TaskItem;
