import React from 'react';
import CompleteLessonImg from 'src/assets/images/training/failed.svg';
import FooterLogo from 'src/assets/images/go-auth-footer-logo.png';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';
import { Link, useLocation } from 'react-router-dom';

const Content = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return (
    <div className="training_quiz_failed">
      <div className="training_quiz_failed_body">
        <div className="training_quiz_failed_wrapper">
          <img src={CompleteLessonImg} alt="Image" className="training_quiz_failed_img" />
          <div className="training_quiz_failed_title">Almost...</div>
          <div className="training_quiz_failed_description">
            You didn’t score enough points to pass.
            <br /> Your quiz score was {parseFloat(params.get('mark')).toFixed(2)}%. You can always
            re-take the quiz when you’re ready.
          </div>
          <div className="training_quiz_failed_actions">
            <Link
              to={`/training/course/${params.get('courseCode')}/dashboard`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <PrimaryButton text="Review course lessons" noIcon={true} />
            </Link>
            <Link
              to={`/training/course/${params.get('courseCode')}/quiz-start`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <SecondaryButton text="Retry quiz" />
            </Link>
          </div>
        </div>
      </div>
      <div className="training_quiz_failed_footer">
        <img src={FooterLogo} className="training_quiz_failed_footer_logo" alt="Logo" />
        <div className="training_quiz_failed_footer_text">
          © 2021 Go121 powered by Flash. Visit the <a href="https://go121.co.za/">Go121 website</a>{' '}
          for more information
        </div>
      </div>
    </div>
  );
};

export default Content;
