import React from 'react';

const Avatar = ({ src, showBadge, badge, className, size = 'middle' }) => {
  return (
    <div className={`avatar avatar_${size} ${className}`}>
      <div className="avatar_wrapper">
        <img src={src} alt="Avatar" />
      </div>
      {showBadge && <div className="avatar_badge">{badge}</div>}
    </div>
  );
};

export default Avatar;
