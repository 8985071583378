import React from 'react';
import InCorrectIcon from 'src/assets/images/training/incorrect-icon.svg';
import CircleRedIcon from 'src/assets/images/training/close-circle-red.svg';
import { SecondaryButton } from 'src/components/Common/Button';
import { Link } from 'react-router-dom';

const IncorrectModal = ({ visible, onCloseModal, question, continueLink }) => {
  return (
    <div
      className="training_course_lesson_check_in_incorrect_modal"
      style={{ visibility: visible ? 'visible' : 'hidden' }}>
      <div
        className="training_course_lesson_check_in_incorrect_modal_backdrop"
        onClick={() => onCloseModal()}
      />
      <div className="training_course_lesson_check_in_incorrect_modal_body">
        <div className="training_course_lesson_check_in_incorrect">
          <button
            className="training_course_lesson_check_in_incorrect_modal_close_btn"
            onClick={() => onCloseModal()}>
            <img src={CircleRedIcon} alt="Close" />
          </button>
          <img
            src={InCorrectIcon}
            className="training_course_lesson_check_in_incorrect_icon"
            alt="Icon"
          />
          <div className="training_course_lesson_check_in_incorrect_title">Incorrect!</div>
          <div className="training_course_lesson_check_in_incorrect_description">{question}</div>
          <Link
            to={continueLink}
            style={{ width: '100%' }}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}>
            <SecondaryButton
              className="training_course_lesson_check_in_incorrect_btn"
              text="Continue"
              noIcon={true}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default IncorrectModal;
