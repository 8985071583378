import React from 'react';
import InlineGroupItem from './GroupItem';

const InlineGroupWrapper = ({ group }) => {
  return (
    <>
      {group.lessonStepItemGroupItems.map((itemGroupItem, key) => (
        <InlineGroupItem item={itemGroupItem} key={key} />
      ))}
    </>
  );
};

export default InlineGroupWrapper;
