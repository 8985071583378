import React from 'react';

const FamilarItem = ({ title, description }) => {
  return (
    <div>
      <div className="training_course_terms_to_know_familar_item">
        <div className="training_course_terms_to_know_familar_item_title">{title}</div>
        <div className="training_course_terms_to_know_familar_item_description">{description}</div>
      </div>
      <div className={`training_course_terms_to_know_familar_item_divider`} />
    </div>
  );
};

export default FamilarItem;
