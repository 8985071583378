import React from 'react';
import { CircularProgress } from '@mui/material';
import NumberOneImage from 'src/assets/images/dashboard/number-one.svg';
import QRCode1Image from 'src/assets/images/dashboard/qr-code-1.svg';
import QRCode2Image from 'src/assets/images/dashboard/qr-code-2.svg';
import QRCode3Image from 'src/assets/images/dashboard/qr-code-3.svg';
import QRCode4Image from 'src/assets/images/dashboard/sim-qr.svg';
import QRCode5Image from 'src/assets/images/dashboard/catalogue-qr.svg';
import QRCode6Image from 'src/assets/images/dashboard/surveys-qr.svg';
import QRCode7Image from 'src/assets/images/dashboard/data-qr.svg';
import QRCode8Image from 'src/assets/images/dashboard/airtime-qr.svg';
import QRCode9Image from 'src/assets/images/dashboard/electricity-qr.svg';
import QRCodeItem from './QRCodeItem';

const Content = () => {
  let qrCodes = [
    {
      image: QRCode1Image,
      description: 'Publicly-accessible agent page',
      bgColor: '#055CF5'
    },
    {
      image: QRCode2Image,
      description: '1ForYou leadgen',
      bgColor: '#FF4600'
    },
    {
      image: QRCode3Image,
      description: 'Betway leadgen',
      bgColor: '#41AD49'
    }
  ];
  let lockedQRCodes = [
    {
      image: QRCode4Image,
      description: 'Sims & Rica',
      bgColor: '#1C1CA8'
    },
    {
      image: QRCode5Image,
      description: 'Catalogue',
      bgColor: '#69BFED'
    },
    {
      image: QRCode6Image,
      description: 'Surveys',
      bgColor: '#055CF5'
    },
    {
      image: QRCode7Image,
      description: 'Data',
      bgColor: '#8484E8'
    },
    {
      image: QRCode8Image,
      description: 'Airtime',
      bgColor: '#41AD49'
    },
    {
      image: QRCode9Image,
      description: 'Electricity',
      bgColor: '#FFBF00'
    }
  ];

  return (
    <div className="qr_code_center_content">
      <div className="qr_code_center_content_statistic_container">
        <div className="qr_code_center_content_top_user_level_container">
          <div className="qr_code_center_content_top_user_level">Level 0</div>
        </div>
        <div className="qr_code_center_content_user_level_mobile">
          <div className="qr_code_center_content_user_level">Tier 0</div>
        </div>
        <div className="qr_code_center_content_statistic_container_div">
          <div className="qr_code_center_content_statistic_percent_container">
            <CircularProgress
              className="qr_code_center_content_bg_circular_pg"
              thickness={4}
              variant="determinate"
              value={100}
              size={137}
            />
            <div className="qr_code_center_content_text_pg_container">
              <div className="qr_code_center_content_statistic_percent_text">0</div>
              <div className="qr_code_center_content_statistic_percent_symbol">%</div>
            </div>
          </div>
          <div className="qr_code_center_content_static_right_container">
            <div className="qr_code_center_content_static_text_container">
              <div className="qr_code_center_content_user_level">Tier 0</div>
              <div className="qr_code_center_content_points_earned">
                300/300
                <br className="qr_code_center_content_mobile_enter" /> Points earned
              </div>
              <div className="qr_code_center_content_tasks_done">
                5/7
                <br className="qr_code_center_content_mobile_enter" /> Tasks done
              </div>
            </div>
            <div className="qr_code_center_content_statistic_icon_container">
              <img src={NumberOneImage} alt="Number one" />
            </div>
          </div>
        </div>
      </div>
      <div className="qr_code_center_content_form_container">
        <div className="qr_code_center_content_form_title">QR Codes</div>
        <div className="qr_code_center_content_form_category_title">Your QR CODES</div>
        <div className="qr_code_center_content_form_category_container">
          {qrCodes.map((qrCode, index) => (
            <QRCodeItem key={index} {...qrCode} />
          ))}
        </div>
        <div className="qr_code_center_content_form_category_title">LOCKED QR CODES</div>
        <div className="qr_code_center_content_form_category_container">
          {lockedQRCodes.map((qrCode, index) => (
            <QRCodeItem key={index} {...qrCode} locked />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Content;
