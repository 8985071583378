import React from 'react';
import CheckGrayIcon from 'src/assets/icons/check-gray.svg';
import CheckGreenIcon from 'src/assets/icons/check-green.svg';

const StepItem = ({ text, active }) => {
  return (
    <div className="task_take_selfie_main_next_step_list">
      <img src={active ? CheckGreenIcon : CheckGrayIcon} alt="" />
      <span>{text}</span>
    </div>
  );
};

export default StepItem;
