import React, { useState, useEffect } from 'react';
import DocumentIcon from 'src/assets/images/training/document-icon-active.svg';
import CorrectIcon from 'src/assets/images/training/correct-icon.svg';
import InCorrectIcon from 'src/assets/images/training/incorrect-icon.svg';
import { SecondaryButton } from 'src/components/Common/Button';
import GoodJobModal from './GoodJobModal';
import IncorrectModal from './IncorrectModal';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'src/hooks';
import { addCourseActivity } from 'src/store/training/trainingSlice';

const Content = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    status: '',
    done: false
  });

  const lesson = useAppSelector((state) => state.training.currentLesson);
  const course = useAppSelector((state) => state.training.currentCourse);
  const [visible, setVisible] = useState(false);
  const [userAnswer, setUserAnswer] = useState('');

  useEffect(() => {
    if (userAnswer != '') {
      let correctAnswer = true;
      let tmpUserAnswer = userAnswer == 'true';
      if (lesson.lessonCheckInItems.length > 0) {
        correctAnswer = lesson.lessonCheckInItems[0].answerValue;
      }

      setVisible(false);
      setState({
        status: correctAnswer == tmpUserAnswer ? 'correct' : 'incorrect',
        done: false
      });

      setTimeout(() => {
        setVisible(true);
        setState({
          status: correctAnswer == tmpUserAnswer ? 'correct' : 'incorrect',
          done: true
        });
      }, 500);
    }
  }, [userAnswer, lesson]);

  const handleClick = () => {
    const data = {
      courseKey: course.courseId,
      retries: 0,
      lessonKey: lesson.lessonId,
      lessonCheckInKey: lesson.lessonCheckInItems[0].lessonCheckInItemId
    };

    dispatch(addCourseActivity(data))
      .then(() => {
        history.push(`/training/course/${course.courseCode}/lesson/${lesson.lessonId}/complete`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (lesson.lessonCheckInItems.length == 0) {
    return (
      <Redirect to={`/training/course/${course.courseCode}/lesson/${lesson.lessonId}`}></Redirect>
    );
  } else {
    return (
      <>
        <div className="training_course_lesson_check_in">
          <div className="training_course_lesson_check_in_content">
            <div className="training_course_lesson_check_in_title">LESSON CHECK-IN</div>
            <div className="training_course_lesson_check_in_question">
              Is the following statement true or false?
            </div>
            <div className="training_course_lesson_check_in_description">
              {lesson.lessonCheckInItems.length > 0 && lesson.lessonCheckInItems[0].question}
            </div>
            <div className="training_course_lesson_check_in_actions">
              <button
                className={`training_course_lesson_check_in_action_btn training_course_lesson_check_in_action_btn_true ${
                  userAnswer == 'true' ? 'active' : ''
                } ${state.done === true ? 'done' : ''}`}
                onClick={() => setUserAnswer('true')}>
                True
              </button>
              <button
                className={`training_course_lesson_check_in_action_btn training_course_lesson_check_in_action_btn_false ${
                  userAnswer == 'false' ? 'active' : ''
                } ${state.done === true ? 'done' : ''}`}
                onClick={() => setUserAnswer('false')}>
                False
              </button>
            </div>
            <div
              className={`training_course_lesson_check_in_footer ${
                state.status !== '' && state.done === true ? 'active' : ''
              }`}>
              Don’t know the answer? <a href="#">Go back and do the lesson again</a>
            </div>
            {state.status === 'correct' && state.done === true && (
              <div className="training_course_lesson_check_in_good_job">
                <img
                  src={CorrectIcon}
                  className="training_course_lesson_check_in_good_job_icon"
                  alt="Icon"
                />
                <div className="training_course_lesson_check_in_good_job_title">Good job!</div>
                <div className="training_course_lesson_check_in_good_job_description">
                  {lesson.lessonCheckInItems.length > 0 &&
                    lesson.lessonCheckInItems[0].successMessage}
                </div>
                <SecondaryButton
                  className="training_course_lesson_check_in_good_job_btn"
                  text="Continue"
                  noIcon={true}
                  onClick={handleClick}
                />
              </div>
            )}
            {state.status === 'incorrect' && state.done === true && (
              <div className="training_course_lesson_check_in_incorrect">
                <img
                  src={InCorrectIcon}
                  className="training_course_lesson_check_in_incorrect_icon"
                  alt="Icon"
                />
                <div className="training_course_lesson_check_in_incorrect_title">Incorrect!</div>
                <div className="training_course_lesson_check_in_incorrect_description">
                  {lesson.lessonCheckInItems.length > 0 &&
                    lesson.lessonCheckInItems[0].successMessage}
                </div>
                <Link
                  to={`/training/course/${course.courseCode}/dashboard`}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}>
                  <SecondaryButton
                    className="training_course_lesson_check_in_incorrect_btn"
                    text="Continue"
                    noIcon={true}
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="training_course_lesson_right">
          <div className="training_course_lesson_right_content">
            <div className="training_course_lesson_right_title">{`Lesson ${lesson.lessonNumber} progress`}</div>
            <div className="training_course_lesson_right_progress">
              {lesson.lessonSteps.map((e, key) => (
                <div className="training_course_lesson_right_progress_item complete" key={key}>
                  {e.title}
                </div>
              ))}
              <div className="training_course_lesson_right_progress_item active completed with_icon">
                <img src={DocumentIcon} alt="" className="" />
                <span>Lesson check-in</span>
              </div>
            </div>
          </div>
        </div>
        <GoodJobModal
          visible={visible && state.status === 'correct' && state.done == true}
          question={
            lesson.lessonCheckInItems.length > 0 ? lesson.lessonCheckInItems[0].successMessage : ''
          }
          handleClick={handleClick}
          onCloseModal={() => setVisible(false)}
        />
        <IncorrectModal
          visible={visible && state.status === 'incorrect' && state.done == true}
          question={
            lesson.lessonCheckInItems.length > 0 ? lesson.lessonCheckInItems[0].successMessage : ''
          }
          continueLink={`/training/course/${course.courseCode}/dashboard`}
          onCloseModal={() => setVisible(false)}
        />
      </>
    );
  }
};

export default Content;
