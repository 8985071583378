import Home from '../pages/Home';
// import FindKioskCopy from '../pages/TasksCopy/FindKiosk';
import CellPhone from '../pages/Auth/Login/CellPhone';
import OTP from '../pages/Auth/Login/OTP';
import Password from '../pages/Auth/Login/Password';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import TrainingCourseDashboard from '../pages/Training/Course/Dashboard';
import TrainingCourseTermsToKnow from '../pages/Training/Course/TermsToKnow';
import TrainingCourseLesson from '../pages/Training/Course/Lesson';
import TrainingCourseLessonCheckIn from 'src/pages/Training/Course/LessonCheckin';
import AuthLayout from '../layout/Auth';
import TrainingQuizStart from '../pages/Training/Course/QuizStart';
import TrainingQuizQuestion from '../pages/Training/Course/QuizQuestion';
import TrainingQuizReview from 'src/pages/Training/Course/QuizResult';
import TrainingQuizPassed from 'src/pages/Training/Quiz/Passed';
import TrainingQuizFailed from 'src/pages/Training/Quiz/Failed';
import Dashboard from '../pages/Dashboard';
import DashboardTraining from '../pages/Dashboard/Training';
import DashboardLayout from 'src/layout/Dashboard';
import TaskLayout from 'src/layout/Task';
import TakeSelfie from 'src/pages/Tasks/TakeSelfie';
import TakeSelfieComplete from 'src/pages/Tasks/TakeSelfie/Complete';
import FindKioskComplete from 'src/pages/Tasks/FindKiosk/Complete';
import SubmitID from 'src/pages/Tasks/SubmitID';
import SubmitIDComplete from 'src/pages/Tasks/SubmitID/Complete';
import BuyStarterKit from 'src/pages/Tasks/BuyStarterKit';
import BuyStarterKitPayment from 'src/pages/Tasks/BuyStarterKit/Payment';
import BuyStarterKitComplete from 'src/pages/Tasks/BuyStarterKit/Complete';
import FindKiosk from 'src/pages/Tasks/FindKiosk';
import TrainingCourseLessonComplete from 'src/pages/Training/Course/LessonComplete';
import Profile from 'src/pages/Dashboard/Profile';
import QRCode from 'src/pages/Dashboard/QRCode';
import Settings from 'src/pages/Dashboard/Settings';
import AccountDeleted from 'src/pages/Auth/AccountDeleted';
import AccountDeletedCallBack from 'src/pages/Auth/AccountDeleted/Callback';
import AccountDeletedDoNotCallBack from 'src/pages/Auth/AccountDeleted/DoNotCallback';
import NotFound from 'src/pages/NotFound';

// routes
const routes = [
  {
    path: '/auth/login/cellphone',
    component: CellPhone,
    auth: false,
    exact: true,
    layout: AuthLayout
  },
  { path: '/auth/login/otp', component: OTP, auth: false, exact: true, layout: AuthLayout },
  {
    path: '/auth/login/password',
    component: Password,
    auth: false,
    exact: true,
    layout: AuthLayout
  },
  {
    path: '/auth/forgot-password',
    component: ForgotPassword,
    auth: false,
    exact: true,
    layout: AuthLayout
  },
  {
    path: '/account-deleted',
    component: AccountDeleted,
    auth: false,
    exact: true,
    layout: AuthLayout
  },
  {
    path: '/account-deleted/call-back',
    component: AccountDeletedCallBack,
    auth: false,
    exact: true
  },
  {
    path: '/account-deleted/do-not-call-back',
    component: AccountDeletedDoNotCallBack,
    auth: false,
    exact: true
  },
  {
    path: '/dashboard',
    component: Dashboard,
    auth: true,
    exact: true,
    layout: DashboardLayout,
    topTitle: 'Dashboard',
    layoutClassName: 'dashboard_dashboard_layout'
  },
  {
    path: '/profile',
    component: Profile,
    auth: true,
    exact: true,
    layout: DashboardLayout,
    topTitle: 'Profile',
    layoutClassName: 'dashboard_profile_layout'
  },
  {
    path: '/qr-codes',
    component: QRCode,
    auth: true,
    exact: true,
    layout: DashboardLayout,
    topTitle: 'QR Codes',
    layoutClassName: 'dashboard_qr_layout'
  },
  {
    path: '/settings',
    component: Settings,
    auth: true,
    exact: true,
    layout: DashboardLayout,
    topTitle: 'Settings',
    layoutClassName: 'dashboard_qr_layout'
  },
  {
    path: '/training-course',
    component: DashboardTraining,
    auth: true,
    exact: true,
    layout: DashboardLayout,
    topTitle: 'Training courses',
    title: 'Training courses',
    description: 'The more training courses you complete, the more products you can sell!',
    layoutClassName: 'dashboard_training_layout'
  },
  {
    path: '/training/course/:courseCode/dashboard',
    component: TrainingCourseDashboard,
    auth: true,
    exact: true
  },
  {
    path: '/training/course/:courseCode/terms-to-know',
    component: TrainingCourseTermsToKnow,
    auth: true,
    exact: true
  },
  {
    path: '/training/course/:courseCode/lesson/:lessonId',
    component: TrainingCourseLesson,
    auth: true,
    exact: true
  },
  {
    path: '/training/course/:courseCode/lesson/:lessonId/check-in',
    component: TrainingCourseLessonCheckIn,
    auth: true,
    exact: true
  },
  {
    path: '/training/course/:courseCode/lesson/:lessonId/complete',
    component: TrainingCourseLessonComplete,
    auth: true,
    exact: true
  },
  {
    path: '/training/course/:courseCode/quiz-start',
    component: TrainingQuizStart,
    auth: true,
    exact: true
  },
  {
    path: '/training/course/:courseCode/quiz-question',
    component: TrainingQuizQuestion,
    auth: true,
    exact: true
  },
  {
    path: '/training/course/:courseCode/quiz-result',
    component: TrainingQuizReview,
    auth: true,
    exact: true
  },
  {
    path: '/training/quiz/passed',
    component: TrainingQuizPassed,
    auth: true,
    exact: true
  },
  {
    path: '/training/quiz/failed',
    component: TrainingQuizFailed,
    auth: true,
    exact: true
  },
  {
    path: '/task/take-selfie',
    component: TakeSelfie,
    auth: true,
    exact: true,
    layout: TaskLayout,
    title: 'Take a selfie'
  },
  {
    path: '/task/take-selfie/complete',
    component: TakeSelfieComplete,
    auth: true,
    exact: true
  },
  {
    path: '/task/submit-id',
    component: SubmitID,
    auth: true,
    exact: true,
    layout: TaskLayout,
    title: 'Submit your ID'
  },
  {
    path: '/task/submit-id/complete',
    component: SubmitIDComplete,
    auth: true,
    exact: true
  },
  {
    path: '/task/buy-starter-kit',
    component: BuyStarterKit,
    auth: true,
    exact: true,
    layout: TaskLayout,
    title: 'Buy a starter kit'
  },
  {
    path: '/task/buy-starter-kit/payment',
    component: BuyStarterKitPayment,
    auth: true,
    exact: true,
    layout: TaskLayout,
    title: 'Buy a starter kit'
  },
  {
    path: '/task/buy-starter-kit/complete',
    component: BuyStarterKitComplete,
    auth: true,
    exact: true
  },
  {
    path: '/task/find-kiosk',
    component: FindKiosk,
    auth: true,
    exact: true,
    layout: TaskLayout,
    title: 'Find a Kiosk'
  },
  {
    path: '/task/find-kiosk/complete',
    component: FindKioskComplete,
    auth: true,
    exact: true
  },
  // need research about urls
  // { path: '/find-kiosk-copy', component: FindKioskCopy, exact: true },
  { path: '/', component: Home, auth: false, exact: true },
  { component: NotFound, auth: false }
];

export default routes;
