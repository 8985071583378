import { Tooltip } from '@mui/material';
import React from 'react';
import LockIcon from 'src/assets/icons/lock.svg';
import InfoWarnIcon from 'src/assets/images/Info-warn-icon.svg';

const PrimaryInput = ({
  placeholder = '',
  type = 'text',
  value = '',
  onChange,
  className,
  locked = false,
  lockTooltip = '',
  ...rest
}) => {
  return (
    <div className="input">
      <input
        type={type}
        className={`input_field ${className || ''}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={locked || rest.disabled}
        {...rest}
      />
      {locked && (
        <Tooltip
          title={
            <>
              <img src={InfoWarnIcon} alt="Icon" />
              <span>{lockTooltip}</span>
            </>
          }
          componentsProps={{ tooltip: { className: 'input_lock_tooltip_container' } }}
          placement="bottom-start"
          followCursor>
          <img src={LockIcon} alt="Icon" className="input_lock_icon" />
        </Tooltip>
      )}
    </div>
  );
};

export default PrimaryInput;
