import React, { useState } from 'react';

const QuestionTrueFalseType = ({ question, onChangeAnwer }) => {
  const [state, setState] = useState({
    status: ''
  });

  const hanldeCallAPI = (status) => {
    setState({
      status: status
    });
    onChangeAnwer(question.answerValue == (status == 'true'));
  };
  return (
    <>
      <div className="training_quiz_question_question">
        Is the following statement true or false?
      </div>
      <div className="training_quiz_question_description">{question.question}</div>
      <div className="training_quiz_question_actions">
        <button
          className={`training_quiz_question_action_btn training_quiz_question_action_btn_true ${
            state.status == 'true' ? 'active' : ''
          }`}
          onClick={() => hanldeCallAPI('true')}>
          True
        </button>
        <button
          className={`training_quiz_question_action_btn training_quiz_question_action_btn_false ${
            state.status == 'false' ? 'active' : ''
          }`}
          onClick={() => hanldeCallAPI('false')}>
          False
        </button>
      </div>
    </>
  );
};

export default QuestionTrueFalseType;
