import React from 'react';
import CompleteLessonImg from 'src/assets/images/training/complete-lesson.svg';
import CompleteLessonImgMobile from 'src/assets/images/training/complete-lesson-mobile.svg';
import FooterLogo from 'src/assets/images/go-auth-footer-logo.png';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';
import { Link, useLocation, useHistory } from 'react-router-dom';

const Content = () => {
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  return (
    <div className="training_quiz_passed">
      <div className="training_quiz_passed_body">
        <div className="training_quiz_passed_wrapper">
          <img
            src={CompleteLessonImg}
            alt="Image"
            className="training_quiz_passed_img training_quiz_passed_img_desktop"
          />
          <img
            src={CompleteLessonImgMobile}
            alt="Image"
            className="training_quiz_passed_img training_quiz_passed_img_mobile"
          />
          <div className="training_quiz_passed_title">You’ve passed!</div>
          <div className="training_quiz_passed_description">
            You have passed Airtime and Data Sales!
            <br /> Your quiz score was {parseFloat(params.get('mark')).toFixed(2)}% and you’ve
            earned {params.get('pointsEarned')} points.
          </div>
          <div className="training_quiz_passed_actions">
            <PrimaryButton
              text="All training courses"
              noIcon={true}
              onClick={() => history.push('/training-course')}
            />
            <Link
              to={`/training/course/${params.get('courseCode')}/quiz-result`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <SecondaryButton text="Review answers" />
            </Link>
          </div>
        </div>
      </div>
      <div className="training_quiz_passed_footer">
        <img src={FooterLogo} className="training_quiz_passed_footer_logo" alt="Logo" />
        <div className="training_quiz_passed_footer_text">
          © 2021 Go121 powered by Flash. Visit the <a href="https://go121.co.za/">Go121 website</a>{' '}
          for more information
        </div>
      </div>
    </div>
  );
};

export default Content;
