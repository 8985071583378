import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackgroundDesktop from 'src/assets/images/login pages/background-desktop.png';
import BackgroundTablet from 'src/assets/images/login pages/background-tablet.png';
import BackgroundMobile from 'src/assets/images/not_found/arrow.png';
import NotFoundImg from 'src/assets/images/not_found/not_found.svg';
import { SecondaryButton } from '../Common/Button';

const NotFoundContent = () => {
  const [tierID, setTierID] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if (accessToken === 'true' && user) {
      setTierID(user.tierId);
    }
  }, []);
  return (
    <div className="nabar-section">
      <div className={`not_found grid`}>
        <div className="not_found_left_side_form">
          <div className="not_found_container">
            <img src={NotFoundImg} alt="Not found" className="not_found_img" />
            <div className="not_found_title">404 Error</div>
            <div className="not_found_description">Sorry, something went wrong.</div>
            <Link
              to={tierID === null ? '/auth/login/cellphone' : '/dashboard'}
              style={{ width: '100%' }}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <SecondaryButton
                className="not_found_btn"
                text={tierID === null ? 'Log In' : 'Home'}
              />
            </Link>
          </div>
        </div>
        <div className="not_found_background_img_desktop">
          <img src={BackgroundDesktop} alt="loading" />
        </div>
        <div className="not_found_background_img_tablet">
          <img src={BackgroundTablet} alt="loading" />
        </div>
        <div className="not_found_background_img_mobile">
          <img src={BackgroundMobile} alt="loading" />
        </div>
      </div>
    </div>
  );
};

export default NotFoundContent;
