import React from 'react';
import InfoIcon from 'src/assets/images/training/info-icon.svg';
import TrainingIcon from 'src/assets/images/training/training-icon.svg';
import CloseIconWhite from 'src/assets/images/training/close-icon-white.svg';
import SideBarProgressBar from 'src/components/Training/Sidebar/SideBarProgressBar';
import CategoryItem from 'src/components/Training/Sidebar/CategoryItem';
import MenuItem from 'src/components/Training/Sidebar/MenuItem';
import { PrimaryButton } from 'src/components/Common/Button';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { hideMenu } from 'src/store/setting/settingSlice';
import { baseURL } from 'src/utils/api';
import { Link, useLocation } from 'react-router-dom';
import { getQuizDetail } from 'src/store/training/trainingSlice';

const SideBar = ({ menu }) => {
  const dispatch = useAppDispatch();
  const course = useAppSelector((state) => state.training.currentCourse);
  const quiz = useAppSelector(getQuizDetail);
  const location = useLocation();

  if (course) {
    return (
      <div className="training_layout_sidebar">
        <div className="training_layout_sidebar_content">
          <div className="training_layout_sidebar_content_header">
            <div className="training_layout_sidebar_overview">
              <div
                className={`training_layout_sidebar_overview_icon${menu ? ' icon_menu' : ''}`}
                style={{ backgroundColor: course.defaultBackgroundColour }}>
                <img src={`${baseURL}image/${course.defaultIcon}`} alt="Icon" />
              </div>
              <span className="training_layout_sidebar_overview_left_lessons">
                {course.lessons.length} lessons
              </span>
              <span className="training_layout_sidebar_overview_divider" />
              <span className="training_layout_sidebar_overview_points">
                {course.points} points
              </span>
            </div>
            <div className="training_layout_sidebar_title">{course.name}</div>
            <SideBarProgressBar total={4} done={0} />
            {menu && (
              <button
                className="training_layout_sidebar_close_btn"
                onClick={() => dispatch(hideMenu())}>
                <img src={CloseIconWhite} alt="" className="training_layout_sidebar_close_icon" />
              </button>
            )}
          </div>
          <div className="training_layout_sidebar_content_body">
            <div className="training_layout_sidebar_categories">
              <Link
                to={`/training/course/${course.courseCode}/dashboard`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}>
                <CategoryItem
                  icon={InfoIcon}
                  name="Course Dashboard"
                  active={location.pathname == `/training/course/${course.courseCode}/dashboard`}
                />
              </Link>
              <Link
                to={`/training/course/${course.courseCode}/terms-to-know`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}>
                <CategoryItem
                  icon={TrainingIcon}
                  name="Terms to Know"
                  active={
                    location.pathname == `/training/course/${course.courseCode}/terms-to-know`
                  }
                />
              </Link>
            </div>
            <div className="training_layout_sidebar_lessons">
              {course.lessons.map((lesson, index) => (
                <MenuItem
                  id={lesson.lessonId}
                  title={`Up Next: LESSON ${lesson.lessonNumber}`}
                  name={lesson.title}
                  type={'ongoing'}
                  key={index}
                />
              ))}
              {quiz && (
                <MenuItem
                  title={`${course.points} POINTS`}
                  name={quiz.name}
                  type={'ongoing'}
                  quiz
                />
              )}
            </div>
            <Link
              to={'/training-course'}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <PrimaryButton text="All training courses" />
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SideBar;
