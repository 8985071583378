import React from 'react';
import Footer from './Footer';
import Header from './Header';

const TaskLayout = ({ children, title }) => {
  return (
    <div className="task_layout">
      <Header title={title} />
      {children}
      <Footer />
    </div>
  );
};

export default TaskLayout;
