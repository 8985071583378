import React from 'react';
import ChecklistProgressGroupWrapper from './ChecklistProgressGroup/Wrapper';
import InlineGroupWrapper from './InlineGroup/Wrapper';
import VerticalListGroupWrapper from './VerticalListGroupWrapper/Wrapper';
import IconListGroupWrapper from './IconListGroupWrapper/Wrapper';
import AccordinGroupWrapper from './AccordinGroupWrapper/Wrapper';
import ChecklistImmediateGroupWrapper from './ChecklistImmediateGroup/Wrapper';
import CardGroupWrapper from './CardGroupWrapper/Wrapper';

const ItemGroups = ({ group, onCompleteToggle }) => {
  switch (group.groupPresentationType) {
    case 'Inline': {
      return <InlineGroupWrapper group={group} />;
    }
    case 'Checklist Progressive': {
      return <ChecklistProgressGroupWrapper group={group} onCompleteToggle={onCompleteToggle} />;
    }
    case 'Checklist Immediate': {
      return <ChecklistImmediateGroupWrapper group={group} onCompleteToggle={onCompleteToggle} />;
    }
    case 'Vertical List': {
      return <VerticalListGroupWrapper group={group} />;
    }
    case 'Icon List': {
      return <IconListGroupWrapper group={group} />;
    }
    case 'Accordion': {
      return <AccordinGroupWrapper group={group} />;
    }
    case 'Card': {
      return <CardGroupWrapper group={group} />;
    }
    default: {
      return null;
    }
  }
};

export default ItemGroups;
