import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

const AuthLayout = ({ children }) => {
  const location = useLocation();

  return (
    <>
      {children}
      {location.hash !== '#/nav-link' && location.hash !== '#/error-404' && <Footer />}
    </>
  );
};

export default AuthLayout;
