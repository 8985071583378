import React, { useEffect, useState } from 'react';
import UnionImage from 'src/assets/images/dashboard/banner-union.svg';
import UnionImageMobile from 'src/assets/images/dashboard/banner-union-mobile.svg';
import { useAppSelector } from 'src/hooks';

const Banner = ({ title, description }) => {
  // Temporary solution before complete authentication start
  const tier = useAppSelector((state) => state.task.tier);
  const [firstName, setFirstName] = useState('Kabelo');
  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if (accessToken === 'true' && user) {
      setFirstName(user.firstName);
    }
  }, []);
  // Temporary solution before complete authentication end

  return (
    <div className="nabar-section dashboard_layout_banner">
      <div className="grid">
        <div className="dashboard_layout_banner_container">
          <div className="dashboard_layout_banner_title">
            {title ? title : <>Welcome {firstName}!</>}
          </div>
          <div className="dashboard_layout_banner_description">
            {description ? description : tier?.description}
          </div>
          <img
            src={UnionImage}
            alt="Union"
            className="dashboard_layout_banner_union_image_desktop"
          />
          <img
            src={UnionImageMobile}
            alt="Union"
            className="dashboard_layout_banner_union_image_mobile"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
