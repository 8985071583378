import React from 'react';
import { baseURL } from 'src/utils/api';
import { markDownFormat } from 'src/utils/formatstring';
// import TickBlueIcon from 'src/assets/icons/tick-blue.svg';

const IconListGroupItem = ({ item, selected, onClick }) => {
  return (
    <div
      className={`training_course_lesson_icon_list_multiple_item ${selected ? 'active' : ''}`}
      onClick={() => onClick()}>
      {item.titleIcon && item.titleIcon != '' && (
        <img
          src={`${baseURL}image/${item.titleIcon}`}
          alt="Title Icon"
          className="training_course_lesson_icon_list_multiple_item_icon"
        />
      )}
      {item.title != '' && (
        <div className="training_course_lesson_icon_list_multiple_item_title">
          <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.title) }} />
        </div>
      )}
    </div>
  );
};

export default IconListGroupItem;
