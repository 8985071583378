import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/api';

const initialState = {
  courses: [],
  coursesByAgent: [],
  quizState: localStorage.getItem('quizState') ? JSON.parse(localStorage.getItem('quizState')) : [],
  currentCourse: null,
  currentLesson: null,
  loading: 'idle',
  error: null
};

export const fetchCourses = createAsyncThunk(
  'training/fetchCourses',
  async (Courses, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const response = await axios.get(
        `api/LMS/GetCourses?entityID=6&includeTest=${
          process.env.REACT_APP_IS_CLIENT == 'true' ? 'false' : 'true'
        }`
      );
      const responseByAgent = await axios.get(
        `${process.env.REACT_APP_PROXY_API_URL}api/proxy_get/AgentCourse/GetAgentCourses?agentId=${state.auth.user.id}`
      );
      return { courses: response.data, coursesByAgent: responseByAgent.data };
    } catch (error) {
      console.log('response.data', error);
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

export const fetchCoursesByAgent = createAsyncThunk(
  'training/fetchCoursesByAgent',
  async (Courses, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const response = await axios.get(
        `${process.env.REACT_APP_PROXY_API_URL}api/proxy_get/AgentCourse/GetAgentCourses?agentId=${state.auth.user.id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

export const fetchCourseFull = createAsyncThunk(
  'training/fetchCourseFull',
  async (courseCode, { rejectWithValue, getState }) => {
    let state = getState();
    let course = state.training.courses.find((e) => e.courseCode == courseCode);
    if (course) {
      try {
        const response = await axios.get(`/api/LMS/GetCourseFull?courseId=${course.courseId}`);
        return response.data;
      } catch (error) {
        return rejectWithValue({
          data: error.response.data,
          status: error.response.status
        });
      }
    } else {
      return rejectWithValue({
        data: {
          message: "course doesn't exist"
        }
      });
    }
  }
);
export const addCourseActivity = createAsyncThunk(
  'training/addCourseActivity',
  async (data, { rejectWithValue, getState }) => {
    let state = getState();
    let agentId = state.auth.user.id;
    try {
      const url =
        process.env.REACT_APP_PROXY_API_URL + 'api/proxy_post/AgentCourse/AddCourseActivity';
      data = { ...data, agentId };
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);
export const addQuizResult = createAsyncThunk(
  'training/addQuizResult',
  async (data, { rejectWithValue, getState }) => {
    let state = getState();
    let agentId = state.auth.user.id;
    try {
      const url = process.env.REACT_APP_PROXY_API_URL + 'api/proxy_post/AgentCourse/AddQuizResult';
      data = { ...data, agentId };
      const response = await axios.post(url, data);
      console.log('==============>', response);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

export const trainingSlice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    setLesson: (state, action) => {
      state.currentLesson = state.currentCourse.lessons.find(
        (e) => e.lessonId === action.payload.lessonId
      );
    },
    setQuizState: (state, action) => {
      let indexCourse = state.quizState.findIndex((e) => e.courseId == action.payload.courseId);
      if (indexCourse === -1) {
        state.quizState.push({
          courseId: action.payload.courseId,
          quizAnswers: []
        });
        indexCourse = state.quizState.length - 1;
      }
      let indexQuiz = state.quizState[indexCourse].quizAnswers.findIndex(
        (e) => e.quizQuestionId == action.payload.quizQuestionId
      );
      if (indexQuiz !== -1) {
        state.quizState[indexCourse].quizAnswers[indexQuiz].isCorrect = action.payload.isCorrect;
      } else {
        state.quizState[indexCourse].quizAnswers.push({
          quizQuestionId: action.payload.quizQuestionId,
          isCorrect: action.payload.isCorrect
        });
      }
      localStorage.setItem('quizState', JSON.stringify(state.quizState));
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCourses.pending, (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    });
    builder.addCase(fetchCourses.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        state.courses = action.payload.courses;
        state.coursesByAgent = action.payload.coursesByAgent;
        state.loading = 'idle';
      }
    });
    builder.addCase(fetchCourses.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = action.payload;
      }
    });
    builder.addCase(fetchCourseFull.pending, (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    });
    builder.addCase(fetchCourseFull.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        let course = { ...action.payload };
        course.lessons = course.lessons.sort((a, b) => a.lessonNumber - b.lessonNumber);
        course.lessons.forEach((lesson) => {
          lesson.lessonSteps = lesson.lessonSteps.sort((a, b) => a.stepNumber - b.stepNumber);
          lesson.lessonSteps.forEach((step) => {
            step.lessonStepItemGroups = step.lessonStepItemGroups.sort(
              (a, b) => a.groupNumber - b.groupNumber
            );
            step.lessonStepItemGroups.forEach((itemGroup) => {
              itemGroup.lessonStepItemGroupItems = itemGroup.lessonStepItemGroupItems.sort(
                (a, b) => a.itemNumber - b.itemNumber
              );
            });
          });
        });
        if (course.quizzes.length) {
          course.quizzes[0].quizQuestions = course.quizzes[0].quizQuestions.sort(
            (a, b) => a.questionNumber - b.questionNumber
          );
        }
        state.currentCourse = course;
        state.loading = 'idle';
      }
    });
    builder.addCase(fetchCourseFull.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = action.payload;
      }
    });
    builder.addCase(fetchCoursesByAgent.pending, (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    });
    builder.addCase(fetchCoursesByAgent.fulfilled, (state, action) => {
      console.log('=============>', action.payload);
      if (state.loading === 'pending') {
        state.coursesByAgent = action.payload;
        state.loading = 'idle';
      }
    });
    builder.addCase(fetchCoursesByAgent.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = action.payload;
      }
    });
  }
});

//actions
export const { setLesson, setQuizState } = trainingSlice.actions;

//selectors
export const fullCourses = (state) => {
  const data = state.training.courses.map((course) => {
    const courseByAgent = state.training.coursesByAgent.find(
      (item) => item.courseKey == course.courseId
    );
    if (courseByAgent) {
      return { ...course, status: courseByAgent.status };
    }
    return course;
  });
  return data;
};
export const getCurrentLessonStep = (lessonStepId) => (state) => {
  if (state.training.currentLesson) {
    let currentLessonStep = state.training.currentLesson.lessonSteps.find(
      (e) => e.lessonStepId === lessonStepId
    );
    if (currentLessonStep) {
      return currentLessonStep;
    } else {
      return state.training.currentLesson.lessonSteps[0];
    }
  } else {
    return null;
  }
};
export const getNextLessonStep = (lessonStepId) => (state) => {
  for (let index = 0; index < state.training.currentLesson.lessonSteps.length; index++) {
    if (
      index > 0 &&
      state.training.currentLesson.lessonSteps[index - 1].lessonStepId == lessonStepId
    ) {
      return state.training.currentLesson.lessonSteps[index];
    }
  }
  return null;
};
export const getPrevLessonStep = (lessonStepId) => (state) => {
  for (let index = 1; index < state.training.currentLesson.lessonSteps.length; index++) {
    if (state.training.currentLesson.lessonSteps[index].lessonStepId == lessonStepId) {
      return state.training.currentLesson.lessonSteps[index - 1];
    }
  }
  return null;
};
export const getQuizDetail = (state) => {
  if (state.training.currentCourse && state.training.currentCourse.quizzes.length) {
    return state.training.currentCourse.quizzes[0];
  }
  return null;
};
export const getCurrentQuizQuestion = (questionId) => (state) => {
  if (state.training.currentCourse && state.training.currentCourse.quizzes.length) {
    let quesQuestions = state.training.currentCourse.quizzes[0].quizQuestions;
    let question = quesQuestions.find((e) => e.quizQuestionId === questionId);
    if (question) {
      return question;
    } else {
      return quesQuestions[0];
    }
  } else {
    return null;
  }
};
export const getNextQuizQuestion = (questionId) => (state) => {
  if (state.training.currentCourse && state.training.currentCourse.quizzes.length) {
    let quesQuestions = state.training.currentCourse.quizzes[0].quizQuestions;
    for (let index = 0; index < quesQuestions.length; index++) {
      if (index > 0 && quesQuestions[index - 1].quizQuestionId == questionId) {
        return quesQuestions[index];
      }
    }
    return null;
  }
  return null;
};
export const getPrevQuizQuestion = (questionId) => (state) => {
  if (state.training.currentCourse && state.training.currentCourse.quizzes.length) {
    let quesQuestions = state.training.currentCourse.quizzes[0].quizQuestions;
    for (let index = 1; index < quesQuestions.length; index++) {
      if (quesQuestions[index].quizQuestionId == questionId) {
        return quesQuestions[index - 1];
      }
    }
    return null;
  }
  return null;
};
export const getNextLesson = (lessonId) => (state) => {
  if (state.training.currentCourse && state.training.currentCourse.lessons.length) {
    let lessons = state.training.currentCourse.lessons;
    for (let index = 0; index < lessons.length; index++) {
      if (index > 0 && lessons[index - 1].lessonId == lessonId) {
        return lessons[index];
      }
    }
    return null;
  }
  return null;
};
export const getPrevLesson = (lessonId) => (state) => {
  if (state.training.currentCourse && state.training.currentCourse.lessons.length) {
    let lessons = state.training.currentCourse.lessons;
    for (let index = 1; index < lessons.length; index++) {
      if (lessons[index].lessonId == lessonId) {
        return lessons[index - 1];
      }
    }
    return null;
  }
  return null;
};

export const getCourseQuizAnswers = (courseId) => (state) => {
  let indexCourse = state.training.quizState.findIndex((e) => e.courseId === courseId);
  if (indexCourse !== -1) {
    return state.training.quizState[indexCourse];
  } else {
    return null;
  }
};

export const isLoading = (state) => state.training.loading == 'pending';

export default trainingSlice.reducer;
