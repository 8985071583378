import React, { useEffect, useState } from 'react';
import CircleRedIcon from 'src/assets/images/training/close-circle-red.svg';
import HandCircleRedIcon from 'src/assets/icons/hand-circle-red.svg';
import UnCheckedIcon from 'src/assets/icons/unchecked.svg';
import CheckGreenIcon from 'src/assets/icons/check-green.svg';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';
import { Link } from 'react-router-dom';

const AccountDeletedModal = ({ visible, onClose }) => {
  const answers = [
    'I registered by mistake.',
    'I don’t have any spare time to do this as a side hustle.',
    'I don’t want to be a Go121 agent anymore.',
    'Other'
  ];
  const [answer, setAnswer] = useState('');
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (visible) {
      document.getElementById('root').classList.add('position-fixed');
    } else {
      document.getElementById('root').classList.remove('position-fixed');
    }
  }, [visible]);

  const onCloseModal = () => {
    onClose();
  };

  return (
    <div className="modal account_deleted_modal" style={{ display: visible ? 'block' : 'none' }}>
      <div className="modal_backdrop" onClick={() => onCloseModal()} />
      <div className="modal_body">
        <div className="modal_content">
          <button
            className="modal_close_btn account_deleted_modal_close_btn"
            onClick={() => onCloseModal()}>
            <img src={CircleRedIcon} alt="Close" />
          </button>
          <img src={HandCircleRedIcon} alt="" className="account_deleted_modal_icon" />
          {submitted ? (
            <>
              <div className="account_deleted_modal_title">Thank you for your feedback!</div>
              <div className="account_deleted_modal_question">
                Do we have permission to call you to discuss the reason for deleting your account?
              </div>
              <div className="account_deleted_modal_actions">
                <Link
                  to={'/account-deleted/do-not-call-back'}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}>
                  <PrimaryButton text="Please don't call me" noIcon />
                </Link>
                <Link
                  to={'/account-deleted/call-back'}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}>
                  <SecondaryButton text="Yes, I’d like to discuss" noIcon />
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className="account_deleted_modal_title">
                Your account
                <br /> has been deleted
              </div>
              <div className="account_deleted_modal_question">
                What made you delete your account?
              </div>
              <div className="account_deleted_modal_answers">
                {answers.map((e, index) => (
                  <div
                    className={`account_deleted_modal_answer ${e == answer ? 'active' : ''}`}
                    key={index}
                    onClick={() => setAnswer(e)}>
                    <div className="account_deleted_modal_answer_text">{e}</div>
                    <img
                      src={answer == e ? CheckGreenIcon : UnCheckedIcon}
                      alt=""
                      className="account_deleted_modal_answer_checkbox"
                    />
                  </div>
                ))}
                <input
                  type="text"
                  className="account_deleted_modal_answer_other"
                  hidden={answer != 'Other'}
                />
              </div>
              <SecondaryButton
                text="Submit"
                className="account_deleted_modal_answer_submit_btn"
                noIcon
                onClick={() => setSubmitted(true)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountDeletedModal;
