import React, { useState } from 'react';
import Notification from '../../Notification';
import Logo from 'src/assets/images/login pages/go121-logo.png';
import BackgroundDesktop from 'src/assets/images/login pages/background-desktop.png';
import BackgroundTablet from 'src/assets/images/login pages/background-tablet.png';
import BackgroundMobile from 'src/assets/images/login pages/background-mobile.png';

const PasswordContent = () => {
  const [notification, setNotification] = useState({
    text: '',
    type: 'success',
    visible: false
  });
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="nabar-section">
      <div className="login_password_form_notification_container">
        <div className="grid">
          {notification.visible && (
            <Notification
              type={notification.type}
              text={notification.text}
              onClose={() => setNotification({ ...notification, visible: false })}
            />
          )}
        </div>
      </div>
      <div
        className={`login_password_form grid ${
          notification.visible ? 'login_password_form_padding' : ''
        }`}>
        <div className="login_password_form_left_side_form">
          <div className="login_password_form_container">
            <div className="login_password_form_header">
              <img src={Logo} alt="" className="login_password_form_logo" />
              <div className="login_password_form_step">AGENT</div>
            </div>
            <div className="login_password_form_title">Login to your account</div>
            <div className="login_password_form_body">
              <div className="login_password_form_group">
                <label htmlFor="" className={`login_password_form_label`}>
                  Password
                </label>
                <div className="login_password_input_wrapper">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className={`login_password_form_input`}
                    placeholder="Password"
                  />
                  <svg
                    onClick={() => setShowPassword(!showPassword)}
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none">
                    <g opacity="0.6">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.68857 3.00085C4.88218 3.01031 5.0642 3.09832 5.19458 3.24551L19.8099 19.7454C19.9403 19.8926 20.0084 20.0869 19.9992 20.2856C19.99 20.4843 19.9042 20.6711 19.7608 20.8049C19.6174 20.9387 19.428 21.0086 19.2344 20.9992C19.0408 20.9897 18.8588 20.9017 18.7284 20.7545L16.7175 18.4843C15.2198 19.1636 13.5989 19.5097 11.9615 19.5C8.77554 19.5 5.88039 18.257 3.58907 15.9057C1.86866 14.1402 1.09507 12.3795 1.06296 12.3053C1.02145 12.2094 1 12.1057 1 12.0007C1 11.8958 1.02145 11.792 1.06296 11.6961C1.12649 11.5495 2.55289 8.32857 5.87066 6.2388L4.11306 4.2546C3.98267 4.1074 3.9146 3.91308 3.92382 3.71438C3.93304 3.51568 4.01879 3.32888 4.16221 3.19506C4.30563 3.06125 4.49497 2.99139 4.68857 3.00085ZM10.2568 14.8867C10.7709 15.2064 11.3605 15.3753 11.9615 15.375C12.5276 15.3755 13.0842 15.2251 13.5766 14.9384L9.26467 10.0705C8.92067 10.5763 8.71839 11.1693 8.67982 11.7849C8.64125 12.4005 8.76787 13.0151 9.0459 13.562C9.32394 14.1089 9.74276 14.5671 10.2568 14.8867ZM20.5218 7.56437C22.1686 9.31514 22.909 11.0612 22.9397 11.1346C22.9795 11.2297 23 11.3326 23 11.4366C23 11.5407 22.9795 11.6435 22.9397 11.7386C22.903 11.8265 22.0175 13.911 20.0229 15.8103C19.8853 15.9412 19.7047 16.0091 19.5203 15.999C19.3359 15.989 19.1627 15.9019 19.0385 15.7566L10.1819 5.40032C10.0974 5.30146 10.0394 5.18021 10.0142 5.04931C9.98901 4.91842 9.99743 4.78273 10.0386 4.6565C10.0798 4.53026 10.1522 4.41816 10.2482 4.33196C10.3443 4.24576 10.4604 4.18866 10.5843 4.16666C11.22 4.05498 11.8632 3.99924 12.5075 4.00001C15.5572 4.00001 18.3285 5.23258 20.5218 7.56437Z"
                        fill="#1C1CA8"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div className="login_password_form_group">
                <button type="submit" className="login_password_form_submit_btn">
                  <span>Next</span>
                  <svg
                    width="22"
                    height="24"
                    viewBox="0 0 22 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18"
                      stroke="#FFBF00"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div className="login_password_form_footer">
                <a href="/auth/forgot-password">Forgot your password?</a>
              </div>
            </div>
          </div>
        </div>
        <div className="login_password_form_background_img_desktop">
          <img src={BackgroundDesktop} alt="loading" />
        </div>
        <div className="login_password_form_background_img_tablet">
          <img src={BackgroundTablet} alt="loading" />
        </div>
        <div className="login_password_form_background_img_mobile">
          <img src={BackgroundMobile} alt="loading" />
        </div>
      </div>
    </div>
  );
};

export default PasswordContent;
