import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/api';

const initialState = {
  menuVisible: false,
  logoutModalVisible: false,
  agentCommunicationFlags: null,
  loading: 'idle',
  error: null
};

export const fetchAgentCommunicationFlags = createAsyncThunk(
  'settings/fetchAgentCommunicationFlags',
  async (_, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const response = await axios.get(
        `${process.env.REACT_APP_PROXY_API_URL}api/proxy_get/Agent/${state.auth.user.id}/AgentCommunicationFlags`
      );
      return { data: response.data };
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

export const updateAgentCommunicationFlags = createAsyncThunk(
  'settings/updateAgentCommunicationFlags',
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const url = `${process.env.REACT_APP_PROXY_API_URL}api/proxy_post/Agent/${state.auth.user.id}/AgentCommunicationFlags`;
      const response = await axios.post(url, data);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

export const updateAgentProductFlag = createAsyncThunk(
  'settings/updateAgentProductFlag',
  async (data, { rejectWithValue, getState }) => {
    try {
      const state = getState();
      const url = `${process.env.REACT_APP_PROXY_API_URL}api/proxy_post/Agent/AgentProductFlag`;
      const response = await axios.post(url, { ...data, agentId: state.auth.user.id });
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    showMenu: (state) => {
      state.menuVisible = true;
    },
    hideMenu: (state) => {
      state.menuVisible = false;
    },
    showLogoutModal: (state) => {
      state.logoutModalVisible = true;
    },
    hideLogoutModal: (state) => {
      state.logoutModalVisible = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAgentCommunicationFlags.pending, (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    });
    builder.addCase(fetchAgentCommunicationFlags.fulfilled, (state, action) => {
      if (state.loading === 'pending') {
        state.agentCommunicationFlags = action.payload.data;
        state.loading = 'idle';
      }
    });
    builder.addCase(fetchAgentCommunicationFlags.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = action.payload;
      }
    });
  }
});

//actions
export const { showMenu, hideMenu, showLogoutModal, hideLogoutModal } = settingSlice.actions;

//selectors
export const menuVisible = (state) => state.setting.menuVisible;
export const logoutModalVisible = (state) => state.setting.logoutModalVisible;

export default settingSlice.reducer;
