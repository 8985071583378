import React from 'react';

const ListItem = ({ text, type }) => {
  return (
    <div className={`list_item list_item_${type}`}>
      <div className="list_item_icon" />
      <div className="list_item_text" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default ListItem;
