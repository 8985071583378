import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';

const RenderComponent = ({ Component, auth, accessToken, user, ...props }) => {
  console.log({ Component, auth, accessToken, user, ...props });
  if (auth) {
    if (accessToken && user) {
      return <Component {...props} />;
    } else {
      return <Redirect to={'/auth/login/cellphone'}></Redirect>;
    }
  } else {
    if (accessToken && user) {
      return <Redirect to={'/dashboard'}></Redirect>;
    } else {
      return <Component {...props} />;
    }
  }
};

const PrivateRoute = ({ component: Component, auth, path, exact, ...rest }) => {
  const user = useAppSelector((state) => state.auth.user);
  const accessToken = useAppSelector((state) => state.auth.access_token);

  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(props) => (
        <RenderComponent
          {...props}
          auth={auth}
          accessToken={accessToken}
          user={user}
          Component={Component}
        />
      )}
    />
  );
};

export default PrivateRoute;
