import React from 'react';
import CompleteLessonImg from 'src/assets/images/tasks/task-complete.png';
import CompleteLessonImgMobile from 'src/assets/images/tasks/task-complete-mobile.png';
import FooterLogo from 'src/assets/images/go-auth-footer-logo.png';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';

const Content = () => {
  return (
    <div className="task_buy_starter_kit_complete">
      <div className="task_buy_starter_kit_complete_body">
        <div className="task_buy_starter_kit_complete_wrapper">
          <img
            src={CompleteLessonImg}
            alt="Image"
            className="task_buy_starter_kit_complete_img task_buy_starter_kit_complete_img_desktop"
          />
          <img
            src={CompleteLessonImgMobile}
            alt="Image"
            className="task_buy_starter_kit_complete_img task_buy_starter_kit_complete_img_mobile"
          />
          <div className="task_buy_starter_kit_complete_title">Success!</div>
          <div className="task_buy_starter_kit_complete_description">
            You can now collect your starter kit from your closest Flash Kiosk. Take your cellphone
            with you as we will be sending a unique pin to your phone. The Kiosk agent will use this
            pin to verify you.
          </div>
          <div className="task_buy_starter_kit_complete_actions">
            <PrimaryButton text="Back to Dashboard" noIcon={true} />
            <SecondaryButton text="Find a kiosk" />
          </div>
        </div>
      </div>
      <div className="task_buy_starter_kit_complete_footer">
        <img src={FooterLogo} className="task_buy_starter_kit_complete_footer_logo" alt="Logo" />
        <div className="task_buy_starter_kit_complete_footer_text">
          © 2021 Go121 powered by Flash. Visit the <a href="https://go121.co.za/">Go121 website</a>{' '}
          for more information
        </div>
      </div>
    </div>
  );
};

export default Content;
