import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/api';

const user = localStorage.getItem('user') || null;
const initialState = {
  loading: 'idle',
  user: user ? JSON.parse(user) : null,
  error: null,
  access_token: localStorage.getItem('access_token')
};

export const findByCellphone = createAsyncThunk(
  'auth/findByCellphone',
  async (cellphone, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PROXY_API_URL}api/proxy_get/Agent/FindByCellphone?Cellphone=${cellphone}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

export const findAgentById = createAsyncThunk(
  'auth/findAgentById',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PROXY_API_URL}api/proxy_get/Agent/${userId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

export const putAgentById = createAsyncThunk(
  'auth/putAgentById',
  async (args, { rejectWithValue }) => {
    try {
      const { userId, formData } = args;
      const response = await axios.put(
        `${process.env.REACT_APP_PROXY_API_URL}api/proxy_put/Agent/${userId}`,
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

export const VerifyOtp = createAsyncThunk(
  'auth/VerifyOtp',
  async ({ agentId, otpId, pin }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PROXY_API_URL}api/proxy_post/Agent/${agentId}/VerifyOtp`,
        {
          otpId: otpId,
          pin: pin
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response.data,
        status: error.response.status
      });
    }
  }
);

// export const setUser = (user) => ({
//   type: 'auth/setUser',
//   user
// });

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action) => {
      state.user = null;
      state.access_token = '';
      localStorage.clear();
      action.payload.history.push('/auth/login/cellphone');
    }
  },
  extraReducers: {
    [findByCellphone.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    },
    [findByCellphone.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        localStorage.setItem('user', JSON.stringify(action.payload));
        state.loading = 'idle';
        state.user = action.payload;
      }
    },
    [findByCellphone.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        localStorage.removeItem('user');
        state.loading = 'idle';
        state.user = null;
        state.error = action.payload;
      }
    },
    [VerifyOtp.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    },
    [VerifyOtp.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        localStorage.setItem('access_token', 'true');
        state.loading = 'idle';
        state.access_token = action.payload;
      }
    },
    [VerifyOtp.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        localStorage.removeItem('access_token');
        state.loading = 'idle';
        state.access_token = null;
        state.error = action.payload;
      }
    },
    [findAgentById.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    },
    [findAgentById.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.user = action.payload;
        localStorage.setItem('user', JSON.stringify(action.payload));
      }
    },
    [findAgentById.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = action.payload;
      }
    },
    [putAgentById.pending]: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
        state.error = null;
      }
    },
    [putAgentById.fulfilled]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.user = action.payload;
        localStorage.setItem('user', JSON.stringify(action.payload));
      }
    },
    [putAgentById.rejected]: (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = action.payload;
      }
    }
  }
});

//actions
export const { logout } = authSlice.actions;

// export const logoutModalVisible = (state) => state.setting.logoutModalVisible;

export const selectors = {
  getUser: createSelector(
    (state) => state.user,
    (user) => user
  )
};

export default authSlice.reducer;
