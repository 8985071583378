import React from 'react';
import CompleteLessonImg from 'src/assets/images/training/complete-lesson.svg';
import CompleteLessonImgMobile from 'src/assets/images/training/complete-lesson-mobile.svg';
import FooterLogo from 'src/assets/images/go-auth-footer-logo.png';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';
import { useAppSelector } from 'src/hooks';
import { getNextLesson } from 'src/store/training/trainingSlice';
import { Link } from 'react-router-dom';

const Content = () => {
  const course = useAppSelector((state) => state.training.currentCourse);
  const lesson = useAppSelector((state) => state.training.currentLesson);
  const nextLesson = useAppSelector(getNextLesson(lesson.lessonId));

  return (
    <div className="training_course_lesson_complete">
      <div className="training_course_lesson_complete_body">
        <div className="training_course_lesson_complete_wrapper">
          <img
            src={CompleteLessonImg}
            alt="Image"
            className="training_course_lesson_complete_img training_course_lesson_complete_img_desktop"
          />
          <img
            src={CompleteLessonImgMobile}
            alt="Image"
            className="training_course_lesson_complete_img training_course_lesson_complete_img_mobile"
          />
          <div className="training_course_lesson_complete_title">Lesson complete!</div>
          <div className="training_course_lesson_complete_description">
            You have completed Lesson {lesson.lessonNumber}
            {nextLesson && (
              <>
                <br /> Next up: Lesson {nextLesson.lessonNumber} - {nextLesson.title}
              </>
            )}
          </div>
          <div className="training_course_lesson_complete_actions">
            <Link
              to={`/training/course/${course.courseCode}/dashboard`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <PrimaryButton text="Back to course Dashboard" noIcon={true} />
            </Link>
            {nextLesson && (
              <Link
                to={`/training/course/${course.courseCode}/lesson/${nextLesson.lessonId}`}
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}>
                <SecondaryButton text={`Go to lesson ${nextLesson.lessonNumber}`} />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className="training_course_lesson_complete_footer">
        <img src={FooterLogo} className="training_course_lesson_complete_footer_logo" alt="Logo" />
        <div className="training_course_lesson_complete_footer_text">
          © 2021 Go121 powered by Flash. Visit the <a href="https://go121.co.za/">Go121 website</a>{' '}
          for more information
        </div>
      </div>
    </div>
  );
};

export default Content;
