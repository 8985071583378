import React from 'react';
import MenuIcon from 'src/assets/images/training/menu-icon.svg';
import CloseIconRed from 'src/assets/images/training/close-icon-red.svg';
import SideBar from './SideBar';
import Footer from './Footer';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { hideMenu, menuVisible, showMenu } from 'src/store/setting/settingSlice';
import { useState } from 'react';
import ExitLessonModal from 'src/components/Training/Course/ExitLessonModal';
import { useEffect } from 'react';

const Header = ({ title }) => {
  const dispatch = useAppDispatch();
  const isOpenMenu = useAppSelector(menuVisible);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    dispatch(hideMenu());
  }, []);

  return (
    <>
      <div className="training_layout_header">
        <div className="training_layout_header_left">
          <button className="training_layout_header_menu_btn" onClick={() => dispatch(showMenu())}>
            <img src={MenuIcon} alt="" className="training_layout_header_menu_icon" />
          </button>
        </div>
        <div className="training_layout_header_title">{title}</div>
        <div className="training_layout_header_right">
          <button className="training_layout_header_close_btn" onClick={() => setVisible(true)}>
            <span>Exit course</span>
            <img src={CloseIconRed} alt="" className="training_layout_header_close_icon" />
          </button>
        </div>
      </div>
      <div className={`training_layout_header_sidebar_wrapper ${isOpenMenu ? 'show' : 'hide'}`}>
        <SideBar menu />
        <Footer />
      </div>
      <ExitLessonModal onCloseModal={() => setVisible(false)} visible={visible} />
    </>
  );
};

export default Header;
