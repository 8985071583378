import React from 'react';
import CheckGrayIcon from 'src/assets/icons/check-gray.svg';
import CheckGreenIcon from 'src/assets/icons/check-green.svg';
import InfoIcon from 'src/assets/images/training/round-info-blue-icon.svg';
import { baseURL } from 'src/utils/api';
import { markDownFormat } from 'src/utils/formatstring';

const ChecklistGroupItem = ({ item, checked, onChange }) => {
  console.log(item);
  return (
    <>
      <div className="training_course_lesson_checklist_item">
        <div className="training_course_lesson_checklist_item_left">
          {item.titleIcon && item.titleIcon != '' && (
            <img
              src={`${baseURL}image/${item.titleIcon}`}
              alt="Icon"
              className="training_course_lesson_checklist_item_icon"
            />
          )}
          <div className="training_course_lesson_checklist_item_left_wrapper">
            {item.title && item.title != '' && (
              <div className="training_course_lesson_checklist_item_title">
                <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.title) }} />
              </div>
            )}
            <div className="training_course_lesson_checklist_item_body">
              <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.body) }} />
            </div>
          </div>
        </div>
        <div className="training_course_lesson_checklist_item_checkbox" onClick={() => onChange()}>
          <img src={checked ? CheckGreenIcon : CheckGrayIcon} alt="" />
        </div>
      </div>
      {item.tipContent && item.tipContent != '' && (
        <div className="training_course_lesson_message">
          <img src={InfoIcon} alt="Icon" className="training_course_lesson_message_icon" />
          <pre className="training_course_lesson_message_text">
            <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.tipContent) }} />
          </pre>
        </div>
      )}
    </>
  );
};

export default ChecklistGroupItem;
