import React from 'react';
import { SecondaryButton } from 'src/components/Common/Button';
import { getQuizDetail } from 'src/store/training/trainingSlice';
import { useAppSelector } from 'src/hooks';
import { baseURL } from 'src/utils/api';
import { Link } from 'react-router-dom';

const Content = () => {
  const quiz = useAppSelector(getQuizDetail);
  const course = useAppSelector((state) => state.training.currentCourse);

  return (
    <>
      <div className="training_quiz">
        <div className="training_quiz_content">
          <div className="training_quiz_title">{quiz.title}</div>
          <div className="training_quiz_img">
            <img src={`${baseURL}image/${quiz.image}`} alt="" />
          </div>
          <div className="training_quiz_description">
            {`In order to finish this course, you must pass this quiz with ${quiz.minPercentage}%.`}
          </div>
          <div className="training_quiz_next">
            <Link
              to={`/training/course/${course.courseCode}/quiz-question`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <SecondaryButton text={'Let’s go!'} />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
