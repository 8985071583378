import React from 'react';
import { Switch } from 'react-router-dom';
import Routes from './routes';

//fonts
import './assets/fonts/AzoSans-Black.ttf';
import './assets/fonts/AzoSans-BlackItalic.ttf';
import './assets/fonts/AzoSans-Bold.ttf';
import './assets/fonts/AzoSans-BoldItalic.ttf';
import './assets/fonts/AzoSans-Italic.ttf';
import './assets/fonts/AzoSans-Light.ttf';
import './assets/fonts/AzoSans-LightItalic.ttf';
import './assets/fonts/AzoSans-Medium.ttf';
import './assets/fonts/AzoSans-MediumItalic.ttf';
import './assets/fonts/AzoSans-Regular.ttf';
import './assets/fonts/AzoSans-Thin.ttf';
import './assets/fonts/AzoSans-ThinItalic.ttf';

const App = () => {
  return (
    <Switch>
      <Routes />
    </Switch>
  );
};

export default App;
