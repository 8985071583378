import React from 'react';
import { useAppSelector } from 'src/hooks';
import { menuVisible } from 'src/store/setting/settingSlice';

const Body = ({ children }) => {
  const isOpenMenu = useAppSelector(menuVisible);

  return (
    <div className={`nabar-section training_layout_body ${isOpenMenu ? 'position-fixed' : ''}`}>
      <div className="grid">{children}</div>
    </div>
  );
};

export default Body;
