import React from 'react';
import Header from './Header';
import Footer from './Footer';
import BreadCrumb from './BreadCrumb';
import Body from './Body';
import SideBar from './SideBar';

const TrainingLayout = ({ children, title, breadcrumb }) => {
  return (
    <>
      <Header title={title} />
      <BreadCrumb items={breadcrumb} />
      <Body>
        <SideBar />
        {children}
      </Body>
      <Footer />
    </>
  );
};

export default TrainingLayout;
