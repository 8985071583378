import React from 'react';
import NotFoundContent from 'src/components/NotFound/Content';
import AuthLayout from 'src/layout/Auth';

const NotFound = () => {
  return (
    <AuthLayout>
      <NotFoundContent />
    </AuthLayout>
  );
};

export default NotFound;
