import React, { useEffect, useState } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import LocationWarnIcon from 'src/assets/images/location-warn-icon.svg';
import MarkerActiveIcon from 'src/assets/images/tasks/marker-active-icon.svg';
import MarkerIcon from 'src/assets/images/tasks/marker-icon.svg';
import AutoComplete from './AutoComplete';
import LocationCard from './LocationCard';
import FindKioskModal from './FindKioskModal';
import axios from 'src/utils/api';
import KioskConfirmModal from './KioskConfirmModal';

const Content = () => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [currentAddress, setCurrentAddress] = useState('');
  const [address, setAddress] = useState('');
  const [visible, setVisible] = useState(true);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [location, setLocation] = useState(null);
  const [kioskCards, setKioskCards] = useState([]);
  const [selectedKioskCard, setSelectedKioskCard] = useState(null);
  // const locations = [
  //   {
  //     title: 'Khayelitsha Kiosk',
  //     description: 'Cnr of Spine and Govan Mbeki Rd, Town 2, Cape Town, Western Cape',
  //     active: false
  //   },
  //   {
  //     title: 'Teddy’s Spaza',
  //     description: 'Cnr of Spine and Govan Mbeki Rd, Town 2, Cape Town, Western Cape',
  //     active: true
  //   },
  //   {
  //     title: 'Langa Grocer',
  //     description: 'Cnr of Spine and Govan Mbeki Rd, Town 2, Cape Town, Western Cape',
  //     active: false
  //   },
  //   {
  //     title: 'Mike’s Place',
  //     description: 'Cnr of Spine and Govan Mbeki Rd, Town 2, Cape Town, Western Cape',
  //     active: false
  //   },
  //   {
  //     title: 'Langa Grocer',
  //     description: 'Cnr of Spine and Govan Mbeki Rd, Town 2, Cape Town, Western Cape',
  //     active: false
  //   }
  // ];

  useEffect(() => {
    if (window.navigator) {
      window.navigator.geolocation.getCurrentPosition((e) => {
        geocodeLatLng({
          lat: e.coords.latitude,
          lng: e.coords.longitude
        });
        setCurrentPosition({
          lat: e.coords.latitude,
          lng: e.coords.longitude
        });
      });
    }
    // getTopUpSites(); // need to be removed when push
  }, [window]);

  useEffect(() => {
    if (location) {
      getTopUpSites();
    }
  }, [location]);

  useEffect(() => {
    if (selectedKioskCard) {
      setConfirmModalVisible(true);
    } else {
      setConfirmModalVisible(false);
    }
  }, [selectedKioskCard]);

  const handleUseCurrentLocation = () => {
    if (currentPosition) {
      setUseCurrentLocation(!useCurrentLocation);
      setAddress(currentAddress);
    }
  };

  const getTopUpSites = async () => {
    try {
      let result = await axios.get(
        // `api/Catalog/GetTopUpSites?entityID=9&countryCode=ZA&longitude=18.505955&latitude=-33.895899&radius=`
        `api/Catalog/GetTopUpSites?entityID=9&countryCode=ZA&longitude=${location.lng}&latitude=${location.lat}&radius=`
      );

      setKioskCards(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const geocodeLatLng = (coordinate) => {
    if (window.google) {
      let geocoder = new window.google.maps.Geocoder();
      try {
        geocoder
          .geocode({ location: coordinate })
          .then((response) => {
            if (response.results?.length > 0) {
              setAddress(response.results[0].formatted_address);
              setCurrentAddress(response.results[0].formatted_address);
            }
          })
          .catch((e) => window.alert('Geocoder failed due to: ' + e));
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="task_find_kiosk_body">
      <div className="grid">
        <div className="task_find_kiosk_main">
          <div className="task_find_kiosk_main_header">
            <div className="task_find_kiosk_main_location_autocomplete">
              <AutoComplete value={address} onChange={(e) => setLocation(e)} />
            </div>
            <div
              className="task_find_kiosk_main_header_right"
              onClick={() => handleUseCurrentLocation()}>
              <span>Use current location</span>
              <img src={LocationWarnIcon} alt="" />
            </div>
          </div>
          <div className="task_find_kiosk_main_body">
            {kioskCards.map((card, index) => (
              <LocationCard
                {...card}
                key={index}
                onClick={() => setSelectedKioskCard(card)}
                active={selectedKioskCard === card}
              />
            ))}
          </div>
        </div>
        <div className="task_find_kiosk_map">
          <GoogleMap
            mapContainerStyle={{
              height: '100%',
              minHeight: '500PX',
              width: '100%'
            }}
            zoom={11}
            center={{ lat: 37.558689, lng: -121.986029 }}
            options={{
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              controlSize: 36,
              gestureHandling: 'cooperative'
            }}>
            {kioskCards.map((card, index) => (
              <Marker
                position={{ lat: card.latitude, lng: card.longitude }}
                icon={card === selectedKioskCard ? MarkerActiveIcon : MarkerIcon}
                onClick={() => setSelectedKioskCard(card)}
                key={index}
              />
            ))}
          </GoogleMap>
        </div>
      </div>
      <FindKioskModal visible={visible} onCloseModal={() => setVisible(false)}></FindKioskModal>
      <KioskConfirmModal
        visible={confirmModalVisible}
        onCloseModal={() => setConfirmModalVisible(false)}
        card={selectedKioskCard}
      />
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDmDAuTgalz6N6pE_phk4ZYcodweRAi1YQ',
  libraries: ['places']
})(Content);
