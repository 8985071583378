import React, { useState } from 'react';
import ThumbsDownIcon from 'src/assets/images/training/thumbs-down-icon.svg';
import ThumbsUpIcon from 'src/assets/images/training/thumbs-up-icon.svg';
import ArrowDownRedIcon from 'src/assets/images/training/arrow-down-red-icon.svg';
import ArrowUpRedIcon from 'src/assets/images/training/arrow-up-red-icon.svg';
import ArrowDownBlueIcon from 'src/assets/images/training/arrow-down-blue-icon.svg';
import ArrowUpBlueIcon from 'src/assets/images/training/arrow-up-blue-icon.svg';

const QuestionItem = ({ title, description, isCorrect }) => {
  const [open, setOpen] = useState(false);

  const onToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="training_quiz_review_question">
      <div className="training_quiz_review_question_header" onClick={() => onToggle()}>
        <div className="training_quiz_review_question_header_left">
          <img
            src={isCorrect ? ThumbsUpIcon : ThumbsDownIcon}
            alt=""
            className="training_quiz_review_question_header_left_img"
          />
          <span className="training_quiz_review_question_header_left_text">{title}</span>
        </div>
        {isCorrect ? (
          <img
            src={open ? ArrowUpBlueIcon : ArrowDownBlueIcon}
            alt=""
            className="training_quiz_review_question_header_right_img"
          />
        ) : (
          <img
            src={open ? ArrowUpRedIcon : ArrowDownRedIcon}
            alt=""
            className="training_quiz_review_question_header_right_img"
          />
        )}
      </div>
      {open && (
        <div className="training_quiz_review_question_body">
          <div className="training_quiz_review_question_body_text">{description}</div>
        </div>
      )}
    </div>
  );
};

export default QuestionItem;
