import React from 'react';
import { Link } from 'react-router-dom';
import { SecondaryButton } from '../../../Common/Button';
import FamilarItem from './FamilarItem';
import IndexItem from './IndexItem';
import { useAppSelector } from 'src/hooks';

const Content = () => {
  const course = useAppSelector((state) => state.training.currentCourse);

  return (
    <>
      <div className="training_course_terms_to_know">
        <div className="training_course_terms_to_know_content">
          <div className="training_course_terms_to_know_title">{course.title}</div>
          <div className="training_course_terms_to_know_description">
            Please familiarise yourself with these terms. You can access this glossary throughout
            this course if you need to.
          </div>
          <div className="training_course_terms_to_know_familar_items">
            {course.glossaryTerms.map((item, index) => (
              <FamilarItem {...item} key={index} />
            ))}
          </div>
          <div className="training_course_terms_to_know_footer">
            <Link
              to={`/training/course/${course.courseCode}/lesson/${course.lessons[0].lessonId}`}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <SecondaryButton text="Start Lesson" />
            </Link>
          </div>
        </div>
      </div>
      <div className="training_course_terms_to_know_right">
        <div className="training_course_terms_to_know_right_content">
          <div className="training_course_terms_to_know_right_title">Terms to know index</div>
          <div className="training_course_terms_to_know_index_items">
            {course.glossaryTerms.map((item, index) => (
              <IndexItem
                title={item.title}
                end={index === course.glossaryTerms.length - 1}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
