import React from 'react';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';

const CourseDashboardSkeleton = () => {
  return (
    <>
      {/* header */}
      <div className="training_layout_header">
        <div className="training_layout_header_left">
          <Skeleton>
            <button className="training_layout_header_menu_btn">
              <img src="" alt="" className="training_layout_header_menu_icon" />
            </button>
          </Skeleton>
        </div>
        <Skeleton>
          <div className="training_layout_header_title">title</div>
        </Skeleton>
        <div className="training_layout_header_right">
          <Skeleton>
            <button className="training_layout_header_close_btn">
              <span>Exit course</span>
              <img src="" alt="" className="training_layout_header_close_icon" />
            </button>
          </Skeleton>
        </div>
      </div>

      {/* breadcrumb */}

      <div className="training_layout_breadcrumb grid">
        <Skeleton width={'100%'} style={{ maxWidth: 400 }} height={12}></Skeleton>
      </div>

      {/* body */}
      <div className={`nabar-section training_layout_body`}>
        <div className="grid">
          <div className="training_layout_sidebar">
            <div className="training_layout_sidebar_content">
              <div className="training_layout_sidebar_content_header">
                <div className="training_layout_sidebar_overview">
                  <div className={`training_layout_sidebar_overview_icon`}>
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                  </div>
                  <Skeleton style={{ display: 'inline' }} width={'100%'}>
                    <span className="training_layout_sidebar_overview_left_lessons">
                      <span>1 lessons</span>
                    </span>
                  </Skeleton>
                </div>
                <Skeleton>
                  <div className="training_layout_sidebar_title">{'course.name'}</div>
                </Skeleton>
                <Skeleton width={'100%'} height={12}></Skeleton>
              </div>
              <div className="training_layout_sidebar_content_body">
                <div className="training_layout_sidebar_categories">
                  <Skeleton width={'100%'}>
                    <div className={`training_layout_sidebar_category`}>
                      <img
                        src={'icon'}
                        alt="Icon"
                        className="training_layout_sidebar_category_icon"
                      />
                      <span className="training_layout_sidebar_category_name">{name}</span>
                    </div>
                  </Skeleton>
                  <Skeleton width={'100%'}>
                    <div className={`training_layout_sidebar_category`}>
                      <img
                        src={'icon'}
                        alt="Icon"
                        className="training_layout_sidebar_category_icon"
                      />
                      <span className="training_layout_sidebar_category_name">{name}</span>
                    </div>
                  </Skeleton>
                </div>
                <div className="training_layout_sidebar_lessons">
                  <Skeleton width={'100%'}>
                    <div className={`training_layout_sidebar_lesson`}>
                      <div className="training_layout_sidebar_lesson_body">
                        <div className="training_layout_sidebar_lesson_title">{'title'}</div>
                        <span className="training_layout_sidebar_lesson_name">{'name'}</span>
                      </div>
                      <img
                        src={'icon[type]'}
                        alt="Icon"
                        className="training_layout_sidebar_lesson_icon"
                      />
                    </div>
                  </Skeleton>
                  <Skeleton width={'100%'}>
                    <div className={`training_layout_sidebar_lesson`}>
                      <div className="training_layout_sidebar_lesson_body">
                        <div className="training_layout_sidebar_lesson_title">{'title'}</div>
                        <span className="training_layout_sidebar_lesson_name">{'name'}</span>
                      </div>
                      <img
                        src={'icon[type]'}
                        alt="Icon"
                        className="training_layout_sidebar_lesson_icon"
                      />
                    </div>
                  </Skeleton>
                  <Skeleton width={'100%'}>
                    <div className={`training_layout_sidebar_lesson`}>
                      <div className="training_layout_sidebar_lesson_body">
                        <div className="training_layout_sidebar_lesson_title">{'title'}</div>
                        <span className="training_layout_sidebar_lesson_name">{'name'}</span>
                      </div>
                      <img
                        src={'icon[type]'}
                        alt="Icon"
                        className="training_layout_sidebar_lesson_icon"
                      />
                    </div>
                  </Skeleton>
                </div>
                <Link
                  to={'/training-course'}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                  }}>
                  {/* <PrimaryButton text="All training courses" /> */}
                </Link>
              </div>
            </div>
          </div>
          <div className="training_course_dashboard">
            <div className="training_course_dashboard_content">
              <Skeleton variant="rounded">
                <div className="training_course_dashboard_title">{'course.title'}</div>
              </Skeleton>
              <Skeleton>
                <div className="training_course_dashboard_description">{'course.body'}</div>
              </Skeleton>
              <div className="training_course_dashboard_lessons">
                {Array.apply(null, Array(3)).map((lesson, index) => (
                  <Skeleton variant="rounded" width={'100%'} height={60} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDashboardSkeleton;
