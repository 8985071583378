import { Tooltip } from '@mui/material';
import React from 'react';
import InfoWarnIcon from 'src/assets/images/Info-warn-icon.svg';

const QRCodeItem = ({ image, description, bgColor, locked = false }) => {
  if (locked) {
    return (
      <Tooltip
        title={
          <>
            <img src={InfoWarnIcon} alt="Icon" />
            <span>Reach Tier 1 to gain edit access</span>
          </>
        }
        componentsProps={{ tooltip: { className: 'input_lock_tooltip_container' } }}
        placement="bottom-start"
        followCursor>
        <div className={`qr_code_center_content_form_qr_item ${locked ? 'locked' : ''}`}>
          <div
            className="qr_code_center_content_form_qr_item_image"
            style={{ backgroundColor: bgColor }}>
            <img src={image} alt="Icon" />
          </div>
          <div className="qr_code_center_content_form_qr_item_description">{description}</div>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <div className={`qr_code_center_content_form_qr_item ${locked ? 'locked' : ''}`}>
        <div
          className="qr_code_center_content_form_qr_item_image"
          style={{ backgroundColor: bgColor }}>
          <img src={image} alt="Icon" />
        </div>
        <div className="qr_code_center_content_form_qr_item_description">{description}</div>
      </div>
    );
  }
};

export default QRCodeItem;
