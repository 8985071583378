import React, { useRef, useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import { useAppSelector } from 'src/hooks';
import StepItem from './StepItem';
import ListItem from 'src/components/Common/ListItem';
import TakeSelfieModal from './TakeSelfieModal';
import { PrimaryButton } from 'src/components/Common/Button';
import Notification from 'src/components/Common/Notification';
import axios from 'src/utils/api';
import getCroppedImg from 'src/utils/cropImage';
import PhoneImage from 'src/assets/icons/phone.svg';
import cameraIcon from 'src/assets/icons/camera.svg';
import UploadIcon from 'src/assets/icons/upload-icon.svg';
import CloseIcon from 'src/assets/icons/close.svg';
import InfoMarkIcon from 'src/assets/icons/Info-warn.svg';

const Content = () => {
  const webcamRef = React.useRef(null);
  const user = useAppSelector((state) => state.auth.user);
  const steps = [
    { text: 'Picture uploaded', state: 1 },
    { text: 'Picture is being reviewed', state: 2 },
    { text: 'Approved*', state: 3 }
  ];
  const tips = [
    'Your picture must show your entire face and must be clearly visible in daylight.',
    'Only you must be in your picture.',
    'No drinking or smoking in your selfie.',
    'Your picture must be recent.'
  ];
  const uploadImageRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [state, setState] = useState({ step: 0, approved: 'active' });

  useEffect(() => {
    if (user) fetchSelfieStatus();
  }, [user]);

  const fetchSelfieStatus = () => {
    axios
      .get(
        `${process.env.REACT_APP_PROXY_API_URL}api/proxy_get/AgentSelfie/GetSelfieInReview/${user.id}`
      )
      .then(({ data }) => {
        setCroppedImage(data.image);
        if (data.status == 'Uploaded, awaiting review') setState({ step: 2, approved: 'active' });
        if (data.status == 'In Review') setState({ step: 3, approved: 'active' });
        if (data.status == 'Active') setState({ step: 4, approved: 'active' });
        if (data.status == 'Image declined') setState({ step: 4, approved: 'declined' });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCompleteSelfie = async (imageSrc, croppedAreaPixels, rotation) => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      setCroppedImage(croppedImage);
      setVisible(false);
      setState({ step: 1, approved: 'active' });
    } catch (e) {
      console.error(e);
    }
  };

  const closeSelfie = () => {
    setCroppedImage(null);
    setState({ step: 0, approved: 'active' });
  };

  const submitSelfie = () => {
    axios
      .post(`${process.env.REACT_APP_PROXY_API_URL}api/proxy_post/AgentSelfie/CreateSelfie`, {
        image: croppedImage,
        agentId: user.id
      })
      .then(({ data }) => {
        if (data.status == 'Uploaded, awaiting review') setState({ step: 2, approved: 'active' });
        if (data.status == 'In Review') setState({ step: 3, approved: 'active' });
        if (data.status == 'Active') setState({ step: 4, approved: 'active' });
        if (data.status == 'Image declined') setState({ step: 4, approved: 'declined' });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setCroppedImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    setState({ step: 1, approved: 'active' });
  };

  return (
    <div className="task_take_selfie_body">
      <div className="grid">
        <div className="task_take_selfie_main">
          <div className="task_take_selfie_main_container">
            <img src={PhoneImage} alt="Icon" className="task_take_selfie_main_icon" />
            <div className="task_take_selfie_main_title">Set your profile picture</div>
            <div className="task_take_selfie_main_description">
              Upload a recent picture by browsing your files or take a new selfie with your camera.
            </div>
            {state.step == 3 && (
              <Notification
                text="Your picture is being reviewed"
                type="success"
                className="task_take_selfie_main_notification"
              />
            )}
            {state.step == 4 && state.approved == 'declined' && (
              <Notification
                text={
                  <div>
                    Please make sure your entire face is showing.{' '}
                    <u onClick={() => closeSelfie()} style={{ cursor: 'pointer' }}>
                      Try again
                    </u>
                  </div>
                }
                type="warn"
                className="task_take_selfie_main_notification"
              />
            )}
            {state.step > 0 && (
              <>
                <div className="task_take_selfie_main_image_section">
                  <img src={croppedImage} alt="Crop" className="task_take_selfie_main_image" />
                  {state.step <= 2 && (
                    <button
                      className="task_take_selfie_main_image_delete_btn"
                      onClick={() => closeSelfie()}>
                      <img src={CloseIcon} alt="" />
                    </button>
                  )}
                </div>
              </>
            )}
            {state.step == 1 && (
              <div className="task_take_selfie_main_actions">
                <PrimaryButton
                  text="Submit"
                  noIcon
                  className="task_take_selfie_main_action_submit"
                  onClick={() => submitSelfie()}
                />
              </div>
            )}
            {state.step == 0 && (
              <div className="task_take_selfie_main_actions">
                <button
                  className="task_take_selfie_main_action_btn task_take_selfie_main_action_camera"
                  onClick={() => setVisible(true)}>
                  <img src={cameraIcon} alt="Camera" />
                  <span>Take a selfie</span>
                </button>
                <button
                  className="task_take_selfie_main_action_btn task_take_selfie_main_action_upload"
                  onClick={() => uploadImage()}>
                  <img src={UploadIcon} alt="Upload" />
                  <span>Upload a picture</span>
                </button>
                <input
                  type="file"
                  hidden
                  ref={uploadImageRef}
                  accept="image/*"
                  onChange={(e) => handleUploadImage(e)}
                />
              </div>
            )}
            <div className="task_take_selfie_main_next_steps">
              <div className="task_take_selfie_main_next_step_title">Next steps</div>
              <div className="task_take_selfie_main_next_step_lists">
                {steps.map((step, key) => (
                  <StepItem key={key} text={step.text} active={step.state < state.step} />
                ))}
              </div>
              {state.approved == 'active' ? (
                <div className="task_take_selfie_main_next_step_description">
                  *Please note: If your picture is not approved we will notify you to try again.
                  Read the useful tips to make sure your picture does not get rejected.
                </div>
              ) : (
                <div className="task_take_selfie_main_next_step_description">
                  *The selfie did not meet our requirements.
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="task_take_selfie_tips">
          <div className="task_take_selfie_tips_container">
            <div className="task_take_selfie_tip_header">
              <img src={InfoMarkIcon} alt="" />
              <span>Useful tips</span>
            </div>
            <div className="task_take_selfie_tip_title">
              Tips to ensure that your picture does not get rejected
            </div>
            <div className="task_take_selfie_tip_lists">
              {tips.map((tip, key) => (
                <ListItem type="orange" text={tip} key={key} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        style={{ visibility: 'hidden', position: 'fixed', top: 0 }}>
        {() => (
          <TakeSelfieModal
            visible={visible}
            onCloseModal={() => setVisible(false)}
            onComplete={(imageSrc, cropArea, rotation) =>
              handleCompleteSelfie(imageSrc, cropArea, rotation)
            }
            webcamRef={webcamRef}
          />
        )}
      </Webcam>
    </div>
  );
};

export default Content;
