import React, { useState, useRef } from 'react';
import InfoMarkIcon from 'src/assets/icons/Info-warn.svg';
import UploadIcon from 'src/assets/icons/upload-icon.svg';
import CloseIcon from 'src/assets/icons/close.svg';
import IDImage from 'src/assets/images/tasks/ID.svg';
import Dropdown from 'src/components/Common/Dropdown';
import PrimaryInput from 'src/components/Common/Input';
import ListItem from 'src/components/Common/ListItem';
// import IDCardImage from 'src/assets/images/tasks/ZA_Smart_ID_Front 1.svg';
import StepItem from './StepItem';
import Notification from 'src/components/Common/Notification';
// import { Link } from 'react-router-dom';
import moment from 'moment';
import { useAppSelector } from 'src/hooks';
import axios from 'src/utils/api';
import { useEffect } from 'react';

const Content = () => {
  const user = useAppSelector((state) => state.auth.user);
  const steps = [
    { text: 'ID submitted', statusText: ['Uploaded, awaiting review', 'In review'] },
    { text: 'ID is being reviewed', statusText: ['In review'] },
    { text: 'Approved*', statusText: [] }
  ];

  const dangerMsg = {
    limitedNumber:
      'Sorry, the ID number you have entered is not the correct length or format. Please check your number and try again.',
    limitedAge: 'Sorry, we only allow agents who are 18 years and older.',
    invalidIdNumber: 'Sorry, the id card number is invalid.',
    invalidExtension:
      'Sorry, we only accept PNG, JPEG or PDF file types. Please make sure your document is one of these before uploading.',
    unselectedType: 'Please select id type.',
    unInputNumber: 'Please input id number.'
  };

  const tips = [
    'The image must not be blurry or cut off.',
    'The image must be in full colour',
    'Your ID or passport document must not have expired. ',
    'Driver’s Licences will not be accepted.',
    'The number on your ID and the ID number given must match.'
  ];

  const idTypes = [
    {
      label: 'South African ID',
      value: '1'
    },
    {
      label: 'Passport',
      value: '2'
    }
  ];
  const [documentStatus, setDocumentStatus] = useState(null);
  const [state, setState] = useState(0);
  const [uploading, setUploading] = useState(false);
  const fileRef = useRef(null);
  const [IDType, setIDType] = useState('');
  const [IDNumber, setIDNumber] = useState('');
  const [IDCardImage, setIDCardImage] = useState(null);
  const [typeDanger, setTypeDanger] = useState('');
  const [inputDanger, setInputDanger] = useState('');
  const [extDanger, setExtDanger] = useState('');

  useEffect(() => {
    if (user) fetchDocumentStatus();
  }, [user]);

  const fetchDocumentStatus = () => {
    axios
      .get(
        `${process.env.REACT_APP_PROXY_API_URL}api/proxy_get/AgentIdDocument/GetIdForVerification?agentId=${user.id}`
      )
      .then(({ data }) => {
        setDocumentStatus({ ...data, status: '' });
        console.log(data);
      })
      .catch((error) => {
        // temporary document status
        setDocumentStatus({
          id: 10,
          statusId: 1,
          documentId: 33,
          document: {
            id: 33,
            fileName: 'Task complete - Submit your ID.png',
            contentType: 'image/png',
            fileLenght: 41920,
            hash: 'd41d8cd98f00b204e9800998ecf8427e',
            statusId: 1,
            status: 'Uploaded',
            statusReason: null
          },
          status: 'Uploaded, awaiting review'
        });
        console.log(error);
      });
  };

  const handleFileUpload = (e) => {
    const extension = e.target.value.split('.').pop();
    if (['png', 'jpg', 'jpeg', 'pdf'].indexOf(extension) > -1) {
      setExtDanger('');
      setUploading(true);
      setTimeout(() => {
        setUploading(false);
        setState(1);
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
          setIDCardImage({ file: e.target.files[0], preview: reader.result });
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      }, 1000);
    } else {
      setExtDanger('invalidExtension');
    }
  };

  const handleSubmit = async () => {
    let isValid = true;
    if (!IDType) {
      setTypeDanger('unselectedType');
      isValid = false;
    }
    if (IDNumber == '') {
      setInputDanger('unInputNumber');
      isValid = false;
    }
    if (extDanger) isValid = false;
    if (inputDanger) isValid = false;

    if (isValid) {
      let formdata = new FormData();
      formdata.append('Image', IDCardImage.file);
      try {
        let res = await axios.post(
          `${process.env.REACT_APP_PROXY_API_URL}api/proxy_post_formData/AgentIdDocument/AddAgentIdDocument?AgentId=${user.id}&IdTypeId=${IDType}&IdNumber=${IDNumber}`,
          formdata
        );
        setDocumentStatus({ ...res, status: '' });
      } catch (error) {
        // need to change api response, temporary solution
        setDocumentStatus({
          id: 10,
          statusId: 1,
          documentId: 33,
          document: {
            id: 33,
            fileName: 'Task complete - Submit your ID.png',
            contentType: 'image/png',
            fileLenght: 41920,
            hash: 'd41d8cd98f00b204e9800998ecf8427e',
            statusId: 1,
            status: 'Uploaded',
            statusReason: null
          },
          status: 'Uploaded, awaiting review'
        });
        console.log(error);
      }
    }
  };

  const handleDeleteID = () => {
    fileRef.current.value = null;
    setState(0);
  };

  const handleIDNumberChange = (e) => {
    if (/^[0-9\b]+$/.test(e.target.value) || e.target.value === '') {
      setIDNumber(e.target.value);
      let currentYear = moment().years().toString();
      let cYear = currentYear.substr(0, 2);
      if (parseInt(e.target.value.substr(0, 2)) > parseInt(currentYear.substr(2, 2))) {
        cYear--;
      }
      if (
        !moment().diff(moment(`${cYear}${e.target.value.substr(0, 6)}`), 'years') ||
        moment().diff(moment(`${cYear}${e.target.value.substr(0, 6)}`), 'years') < 18 ||
        moment().diff(moment(`${cYear}${e.target.value.substr(0, 6)}`), 'years') > 60
      ) {
        setInputDanger('limitedAge');
      } else if (e.target.value.length != 13) {
        setInputDanger('limitedNumber');
      } else {
        setInputDanger('');
      }
    }
  };

  return (
    <div className="task_submit_id_body">
      <div className="grid">
        <div className="task_submit_id_main">
          <div className="task_submit_id_main_container">
            <img src={IDImage} alt="Icon" className="task_submit_id_main_icon" />
            <div className="task_submit_id_main_title">Submit your ID</div>
            <div className="task_submit_id_main_description">
              We need a full colour image of your ID as proof of who you are
            </div>
            {documentStatus?.status == '' && (
              <>
                <div className="task_submit_id_main_form_group">
                  <label
                    htmlFor="id_type"
                    className={`task_submit_id_main_form_label ${typeDanger ? 'danger' : ''}`}>
                    ID type
                  </label>
                  <Dropdown
                    value={IDType}
                    onSelect={(e) => {
                      setIDType(e);
                      setTypeDanger('');
                    }}
                    options={idTypes}
                    className={`task_submit_id_main_form_control ${typeDanger ? 'danger' : ''}`}
                    placeholder={'Select your id type'}
                  />
                  {typeDanger && (
                    <div className="task_submit_id_main_form_control_danger_message">
                      {dangerMsg[typeDanger]}
                    </div>
                  )}
                </div>
                <div className="task_submit_id_main_form_group">
                  <label
                    htmlFor="id_number"
                    className={`task_submit_id_main_form_label ${inputDanger ? 'danger' : ''}`}>
                    ID number
                  </label>
                  <PrimaryInput
                    id="id_number"
                    placeholder="Enter your ID number"
                    className={`task_submit_id_main_form_control ${inputDanger ? 'danger' : ''}`}
                    value={IDNumber}
                    onChange={(e) => handleIDNumberChange(e)}
                  />
                  {inputDanger && (
                    <div className="task_submit_id_main_form_control_danger_message">
                      {dangerMsg[inputDanger]}
                    </div>
                  )}
                </div>
                {state == 0 && (
                  <div className="task_submit_id_main_upload_btn_wrap">
                    <button
                      className="task_submit_id_main_upload_btn"
                      onClick={() => fileRef.current.click()}>
                      <img src={UploadIcon} alt="" />
                      <div>{uploading ? 'Uploading...' : 'Upload a file'}</div>
                    </button>
                    {extDanger && (
                      <div className="task_submit_id_main_form_control_danger_message">
                        {dangerMsg[extDanger]}
                      </div>
                    )}
                  </div>
                )}
                {state == 1 && (
                  <>
                    <div className="task_submit_id_main_image_section">
                      <img
                        src={IDCardImage?.preview}
                        alt="ID"
                        className="task_submit_id_main_image"
                      />
                      <button
                        className="task_submit_id_main_image_delete_btn"
                        onClick={() => handleDeleteID()}>
                        <img src={CloseIcon} alt="Icon" />
                      </button>
                    </div>
                    <div className="task_submit_id_main_image_description">
                      By clicking “Submit” you agree to our{' '}
                      <a href="https://go121.co.za/privacy-policy" target="_blank" rel="noreferrer">
                        Privacy Policy
                      </a>
                    </div>
                    <button
                      className="task_submit_id_main_submit_btn"
                      onClick={() => handleSubmit()}>
                      Submit
                    </button>
                  </>
                )}
              </>
            )}
            {documentStatus?.status == 'In review' && (
              <Notification
                text="Your picture is being reviewed"
                type="success"
                className="task_submit_id_main_notification"
              />
            )}
            {(documentStatus?.status == 'Uploaded, awaiting review' ||
              documentStatus?.status == 'In review') && (
              <>
                <div className="task_submit_id_main_submitted_form">
                  <div className="task_submit_id_main_submitted_form_group">
                    <div className="task_submit_id_main_submitted_form_label">ID type</div>
                    <div className="task_submit_id_main_submitted_form_field">{IDType}</div>
                  </div>
                  <div className="task_submit_id_main_submitted_form_group">
                    <div className="task_submit_id_main_submitted_form_label">ID number</div>
                    <div className="task_submit_id_main_submitted_form_field">{IDNumber}</div>
                  </div>
                </div>
                <div className="task_submit_id_main_submitted_form_main_image">
                  <img src={IDCardImage?.preview} alt="Photo" />
                </div>
              </>
            )}
            <input type="file" ref={fileRef} hidden onChange={(e) => handleFileUpload(e)} />
            <div className="task_submit_id_main_next_steps">
              <div className="task_submit_id_main_next_step_title">Next steps</div>
              <div className="task_submit_id_main_next_step_lists">
                {steps.map((step, index) => (
                  <StepItem
                    key={index}
                    text={step.text}
                    active={step.statusText.find((text) => text == documentStatus?.status)}
                  />
                ))}
              </div>
              <div className="task_submit_id_main_next_step_description">
                *Please note: If your ID is not approved we will notify you to try again. Read the
                useful tips to make sure your ID does not get rejected.
              </div>
            </div>
          </div>
        </div>
        <div className="task_submit_id_tips">
          <div className="task_submit_id_tips_container">
            <div className="task_submit_id_tip_header">
              <img src={InfoMarkIcon} alt="" />
              <span>Useful tips</span>
            </div>
            <div className="task_submit_id_tip_title">
              Tips to ensure that your ID does not get rejected
            </div>
            <div className="task_submit_id_tip_lists">
              {tips.map((tip, key) => (
                <ListItem type="orange" text={tip} key={key} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
