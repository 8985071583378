import React, { useEffect } from 'react';
import { useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import CircleRedIcon from 'src/assets/images/training/close-circle-red.svg';
import ExitIcon from 'src/assets/images/training/lesson1-exit-icon.svg';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';
import Notification from 'src/components/Notification';

const DeleteProfileModal = ({ visible, onCloseModal }) => {
  const [otpSent, setOTPSent] = useState(false);
  const [otpSubmit, setOTPSubmit] = useState(false);
  const [otp, setOTP] = useState('');
  useEffect(() => {
    if (visible) {
      document.getElementById('root').classList.add('position-fixed');
    } else {
      document.getElementById('root').classList.remove('position-fixed');
    }
  }, [visible]);

  const handleDelete = () => {
    if (otpSent) {
      setOTPSubmit(true);
    } else {
      setOTPSent(true);
    }
  };

  return (
    <div className="profile_delete_modal" style={{ display: visible ? 'block' : 'none' }}>
      <div className="profile_delete_modal_backdrop" onClick={() => onCloseModal()} />
      <div className="profile_delete_modal_body">
        <div className="profile_delete_modal_content">
          <button className="profile_delete_modal_close_btn" onClick={() => onCloseModal()}>
            <img src={CircleRedIcon} alt="Close" />
          </button>
          <img src={ExitIcon} alt="Icon" className="profile_delete_modal_icon" />
          {otpSent && !otpSubmit ? (
            <>
              <div className="profile_delete_modal_title_otp">Enter one-time pin</div>
              <div className="profile_delete_modal_description_otp">
                We’ve sent a one-time PIN to the cellphone number +27 074 1234.
              </div>
              <ReactCodeInput
                type="number"
                fields={4}
                className="profile_delete_otp_input_container"
                onChange={(e) => setOTP(e)}
              />
              <div className="profile_delete_modal_resend_otp">Resend OTP?</div>
            </>
          ) : (
            <>
              <div className="profile_delete_modal_title">Delete Profile?</div>
              <div className="profile_delete_modal_description">
                Are you sure you want to delete your profile? This action cannot be undone.
              </div>
            </>
          )}
          {otpSubmit && (
            <Notification
              text="You have R453 in your app wallet. To not lose this money, please go to Agent app to make a withdrawl."
              type="warn"
              className="profile_delete_modal_notification"
            />
          )}
          <div className="profile_delete_modal_actions">
            <PrimaryButton text="Cancel" noIcon className="profile_delete_modal_cancel" />
            <SecondaryButton
              text="Delete Profile"
              noIcon
              className="profile_delete_modal_delete"
              onClick={() => handleDelete()}
              disabled={otpSent && otp.length != 4}
            />
          </div>
          {(otpSent || otpSubmit) && (
            <div className="profile_delete_modal_footer_otp">
              {otpSubmit
                ? 'Once your profile is deleted you will not be able to create a new Agent profile with the same ID number for the next 3 months.'
                : 'This action cannot be undone.'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteProfileModal;
