import React from 'react';

const ProgressBar = ({ total = 4, done = 0 }) => {
  return (
    <div className="progress_bar">
      {[...Array(total)].map((e, index) => (
        <div className={`progress_item ${index < done ? 'active' : ''}`} key={index} />
      ))}
    </div>
  );
};

export default ProgressBar;
