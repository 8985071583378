import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
  fetchAgentCommunicationFlags,
  updateAgentCommunicationFlags,
  updateAgentProductFlag
} from 'src/store/setting/settingSlice';
import NumberOneImage from 'src/assets/images/dashboard/number-one.svg';
import InfoPrimaryIcon from 'src/assets/icons/info-secondary.svg';

const Content = () => {
  const dispatch = useAppDispatch();
  const agentCommunicationFlags = useAppSelector((state) => state.setting.agentCommunicationFlags);
  const [data, setData] = useState({});

  useEffect(() => {
    dispatch(fetchAgentCommunicationFlags());
  }, []);

  useEffect(() => {
    if (agentCommunicationFlags) {
      setData({
        marketingAndCommunication: agentCommunicationFlags.marketingAndCommunication,
        marketingAndCommunicationPartners:
          agentCommunicationFlags.marketingAndCommunicationPartners,
        productPreferences: false,
        requestedBy: 'string'
      });
    }
  }, [agentCommunicationFlags]);

  const handleChange = (e, key) => {
    const agentCommunicationFlagsData = { ...data };
    agentCommunicationFlagsData[key] = e.target.checked;
    setData(agentCommunicationFlagsData);
    if (key != 'productPreferences')
      dispatch(updateAgentCommunicationFlags(agentCommunicationFlagsData));
    else dispatch(updateAgentProductFlag({ code: 'string', show: e.target.checked }));
  };

  return (
    <div className="settings_center_content">
      <div className="settings_center_content_statistic_container">
        <div className="settings_center_content_top_user_level_container">
          <div className="settings_center_content_top_user_level">Level 0</div>
        </div>
        <div className="settings_center_content_user_level_mobile">
          <div className="settings_center_content_user_level">Tier 0</div>
        </div>
        <div className="settings_center_content_statistic_container_div">
          <div className="settings_center_content_statistic_percent_container">
            <CircularProgress
              className="settings_center_content_bg_circular_pg"
              thickness={4}
              variant="determinate"
              value={100}
              size={137}
            />
            <div className="settings_center_content_text_pg_container">
              <div className="settings_center_content_statistic_percent_text">0</div>
              <div className="settings_center_content_statistic_percent_symbol">%</div>
            </div>
          </div>
          <div className="settings_center_content_static_right_container">
            <div className="settings_center_content_static_text_container">
              <div className="settings_center_content_user_level">Tier 0</div>
              <div className="settings_center_content_points_earned">
                0/300
                <br className="settings_center_content_mobile_enter" /> Points earned
              </div>
              <div className="settings_center_content_tasks_done">
                0/7
                <br className="settings_center_content_mobile_enter" /> Tasks done
              </div>
            </div>
            <div className="settings_center_content_statistic_icon_container">
              <img src={NumberOneImage} alt="Number one" />
            </div>
          </div>
        </div>
      </div>
      <div className="settings_center_content_form_container">
        <div className="settings_center_content_form_title">
          <span>My Settings</span>
          <img src={InfoPrimaryIcon} alt="" />
        </div>
        <div className="settings_center_content_form_category">
          <div className="settings_center_content_form_category_title">CONSENTS</div>
          <div className="settings_center_content_form_item">
            <div className="settings_center_content_form_item_title">
              Receive marketing from Go121
            </div>
            <Switch
              className="settings_center_content_form_item_switch"
              focusVisibleClassName=".Mui-focusVisible"
              checked={data?.marketingAndCommunication}
              onChange={(e) => handleChange(e, 'marketingAndCommunication')}
            />
          </div>
          <div className="settings_center_content_form_item">
            <div className="settings_center_content_form_item_title">
              Receive marketing from Go121’s partners
            </div>
            <Switch
              className="settings_center_content_form_item_switch"
              focusVisibleClassName=".Mui-focusVisible"
              checked={data?.marketingAndCommunicationPartners}
              onChange={(e) => handleChange(e, 'marketingAndCommunicationPartners')}
            />
          </div>
        </div>
        <div className="settings_center_content_form_category">
          <div className="settings_center_content_form_category_title">PRODUCT PREFERENCES</div>
          <div className="settings_center_content_form_item">
            <div className="settings_center_content_form_item_title">
              Don’t show me any products and training for gambling / lottery products
            </div>
            <Switch
              className="settings_center_content_form_item_switch"
              focusVisibleClassName=".Mui-focusVisible"
              checked={data?.productPreferences}
              onChange={(e) => handleChange(e, 'productPreferences')}
            />
          </div>
        </div>
        <div className="settings_center_content_form_category">
          <div className="settings_center_content_form_category_title">POLICIES</div>
          <div className="settings_center_content_form_item">
            <div className="settings_center_content_form_item_title">View Terms & Conditions</div>
            <a
              href="https://go121.co.za/terms-conditions"
              target="_blank"
              className="settings_center_content_form_item_button"
              rel="noreferrer">
              View
            </a>
          </div>
          <div className="settings_center_content_form_item">
            <div className="settings_center_content_form_item_title">View Privacy Policy</div>
            <a
              href="https://go121.co.za/privacy-policy"
              target="_blank"
              className="settings_center_content_form_item_button"
              rel="noreferrer">
              View
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
