import React, { useMemo } from 'react';
import UpIcon from 'src/assets/images/dropdown-up-icon.svg';
import DownIcon from 'src/assets/images/dropdown-down-icon.svg';
import { useState } from 'react';

const Dropdown = ({ options, value, placeholder, onSelect, className }) => {
  const [open, setOpen] = useState(false);
  let selectedOption = useMemo(() => {
    if (options.find((e) => e.value == value)) {
      return options.find((e) => e.value == value);
    } else {
      return null;
    }
  }, [value, options]);

  const handleSelect = (option) => {
    if (onSelect) onSelect(option.value);
    setOpen(false);
  };

  return (
    <div className={`dropdown ${className || ''}`}>
      <div className={`dropdown-select ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
        <div className="dropdown-select-text">
          {selectedOption ? selectedOption.label : placeholder}
        </div>
        <img src={open ? UpIcon : DownIcon} className="dropdown-select-icon" alt="Icon" />
      </div>
      {open && (
        <div className="dropdown-menu">
          {options.map((option, key) => (
            <div className="dropdown-menu-item" key={key} onClick={() => handleSelect(option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
