import React from 'react';
import Content from 'src/components/Dashboard/Training/Content';
import RightContent from 'src/components/Dashboard/Training/RightContent';

const DashboardTraining = () => {
  return (
    <>
      <Content />
      <RightContent />
    </>
  );
};

export default DashboardTraining;
