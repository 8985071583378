import React from 'react';
import DocumentIcon from 'src/assets/images/training/document-icon-active.svg';
import { useAppSelector } from 'src/hooks';
import { getCourseQuizAnswers, getQuizDetail } from 'src/store/training/trainingSlice';
import QuestionItem from './QuestionItem';

const Content = () => {
  const quiz = useAppSelector(getQuizDetail);
  const course = useAppSelector((state) => state.training.currentCourse);
  const courseQuizAnswer = useAppSelector(getCourseQuizAnswers(course.courseId));
  const isCorrectQuestion = (questionId) => {
    if (courseQuizAnswer) {
      let indexQuestionAnswer = courseQuizAnswer.quizAnswers.find(
        (e) => e.quizQuestionId == questionId
      );
      if (indexQuestionAnswer && indexQuestionAnswer.isCorrect) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <div className="training_quiz_review">
        <div className="training_quiz_review_content">
          <div className="training_quiz_review_title">Let’s review your answers</div>
          <div className="training_quiz_review_question_lists">
            {quiz.quizQuestions.map((question, key) => (
              <QuestionItem
                title={`Question ${key + 1}`}
                description={question.question}
                key={key}
                isCorrect={isCorrectQuestion(question.quizQuestionId)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="training_quiz_review_right">
        <div className="training_quiz_review_right_content">
          <div className="training_quiz_review_right_title">Quiz progress</div>
          <div className="training_quiz_review_right_progress">
            {quiz.quizQuestions.map((e, key) => (
              <div className={`training_quiz_question_right_progress_item complete`} key={key}>
                {`Question ${e.questionNumber}`}
              </div>
            ))}
            <div className="training_quiz_review_right_progress_item active with_icon">
              <img src={DocumentIcon} alt="" className="" />
              <span>Quiz Result</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
