import React from 'react';
import Footer from '../Footer';
import Banner from './Banner';
import Body from './Body';
import SideBar from './SideBar';
import LogoutModal from 'src/components/Dashboard/LogoutModal';
import Header from './Header';
import { useAppSelector } from 'src/hooks';
import { logoutModalVisible } from 'src/store/setting/settingSlice';

const DashboardLayout = ({ children, topTitle, title, description, layoutClassName }) => {
  const visible = useAppSelector(logoutModalVisible);

  return (
    <div className={`dashboard_layout ${layoutClassName ? layoutClassName : ''}`}>
      <Header title={topTitle} />
      <Banner title={title} description={description} />
      <Body>
        <SideBar />
        {children}
      </Body>
      <Footer />

      <LogoutModal visible={visible} />
    </div>
  );
};

export default DashboardLayout;
