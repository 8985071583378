import React, { useId } from 'react';
import { baseURL } from 'src/utils/api';
import InfoIcon from 'src/assets/images/training/round-info-blue-icon.svg';
import { useState } from 'react';
import { markDownFormat } from 'src/utils/formatstring';

const InlineGroupItem = ({ item }) => {
  const id = useId();
  const [desktopWidth, setDesktopWidth] = useState(0);
  const [mobileWidth, setMobileWidth] = useState(0);
  const css = `
    @media only screen and (max-width: 480px) {
      img[nonce='${id}mobile'] {
        width: ${(mobileWidth * 100) / 360}vw !important;
      }
    }
    @media only screen and (min-width: 481px) and (max-width: 840px) {
      img[nonce='${id}desktop'] {
        width: ${(desktopWidth * 100) / 1440}vw !important;
      }
    }
    @media only screen and (min-width: 841px) and (max-width: 1440px) {
      img[nonce='${id}desktop'] {
        width: ${(desktopWidth * 100) / 1440}vw !important;
      }
    }
    @media only screen and (min-width: 1441px) {
      img[nonce='${id}desktop'] {
        width: ${desktopWidth}px !important;
      }
    }
  `;
  return (
    <>
      <style scoped>{css}</style>
      {item.title && item.title != '' && (
        <div className="training_course_lesson_sub_title">
          <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.title) }} />
        </div>
      )}
      <div
        className="training_course_lesson_body"
        placement={item.desktopImagePlacement?.toLowerCase()}
        mobile-placement={item.mobileImagePlacement?.toLowerCase()}>
        {item.desktopImage && (
          <div className="training_course_lesson_img desktop">
            <img
              src={`${baseURL}image/${item.desktopImage}`}
              alt=""
              nonce={id + 'desktop'}
              onLoad={(e) => {
                setDesktopWidth(e.target.naturalWidth);
              }}
            />
          </div>
        )}
        {item.mobileImage && (
          <div className="training_course_lesson_img mobile">
            <img
              src={`${baseURL}image/${item.mobileImage}`}
              alt=""
              nonce={id + 'mobile'}
              onLoad={(e) => {
                setMobileWidth(e.target.naturalWidth);
              }}
            />
          </div>
        )}
        {item.body && item.body != '' && (
          <div className="training_course_lesson_description">
            <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.body) }} />
          </div>
        )}
      </div>
      {item.tipContent && item.tipContent != '' && (
        <div className="training_course_lesson_message">
          <img src={InfoIcon} alt="Icon" className="training_course_lesson_message_icon" />
          <pre className="training_course_lesson_message_text">
            <div dangerouslySetInnerHTML={{ __html: markDownFormat(item.tipContent) }} />
          </pre>
        </div>
      )}
    </>
  );
};

export default InlineGroupItem;
