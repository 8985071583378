import React from 'react';
import { useHistory } from 'react-router-dom';
import DocumentIcon from 'src/assets/images/training/document-icon.svg';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';
import { useAppSelector, useAppDispatch } from 'src/hooks';
import {
  getNextQuizQuestion,
  getPrevQuizQuestion,
  getQuizDetail,
  getCourseQuizAnswers,
  addCourseActivity,
  addQuizResult,
  setQuizState
} from 'src/store/training/trainingSlice';
import QuestionMultiChoiceManyType from './QuestionMultiChoiceManyType';
import QuestionMultiChoiceSingleType from './QuestionMultiChoiceSingleType';
import QuestionTrueFalseType from './QuestionTrueFalseType';
import { useState } from 'react';

const Content = ({ question }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const quiz = useAppSelector(getQuizDetail);
  const prevQuestion = useAppSelector(getPrevQuizQuestion(question.quizQuestionId));
  const nextQuestion = useAppSelector(getNextQuizQuestion(question.quizQuestionId));
  const course = useAppSelector((state) => state.training.currentCourse);
  const quizAnswers = useAppSelector(getCourseQuizAnswers(course.courseId));
  const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);

  const handleGoBack = () => {
    let params = new URLSearchParams();
    params.append('questionId', prevQuestion.quizQuestionId);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const handleGoNext = () => {
    const data = {
      courseKey: course.courseId,
      retries: 0,
      quizKey: question.quizQuestionId
    };

    dispatch(
      setQuizState({
        courseId: course.courseId,
        quizQuestionId: question.quizQuestionId,
        isCorrect: isCorrectAnswer
      })
    );

    dispatch(addCourseActivity(data))
      .then(() => {
        if (nextQuestion) {
          let params = new URLSearchParams();
          params.append('questionId', nextQuestion.quizQuestionId);
          history.push(`${location.pathname}?${params.toString()}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGoResult = () => {
    dispatch(
      setQuizState({
        courseId: course.courseId,
        quizQuestionId: question.quizQuestionId,
        isCorrect: isCorrectAnswer
      })
    );

    let sCorrect = 0;
    quizAnswers.quizAnswers.map((item) => (sCorrect += item.isCorrect));
    let mark = (sCorrect / quizAnswers.quizAnswers.length) * 100;

    const formData = {
      courseKey: course.courseId,
      mark: mark,
      pointsEarned: 0,
      passed: quiz.minPercentage <= mark
    };
    dispatch(addQuizResult(formData))
      .then(() => {
        let params = new URLSearchParams();
        params.append('courseCode', course.courseCode);
        params.append('mark', mark);
        params.append('pointsEarned', course.points);

        if (mark < quiz.minPercentage) history.push(`/training/quiz/failed?${params.toString()}`);
        else history.push(`/training/quiz/passed?${params.toString()}`);
        // history.push(`/training/course/${course.courseCode}/quiz-result`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="training_quiz_question">
        <div className="training_quiz_question_content">
          <div className="training_quiz_question_title">{`Question ${question.questionNumber}`}</div>
          {question.answerType == 'True/False (True is the correct answer)' && (
            <QuestionTrueFalseType
              onChangeAnwer={(e) => setIsCorrectAnswer(e)}
              question={{ ...question, answerValue: true }}
            />
          )}
          {question.answerType == 'True/False (False is the correct answer)' && (
            <QuestionTrueFalseType
              onChangeAnwer={(e) => setIsCorrectAnswer(e)}
              question={{ ...question, answerValue: false }}
            />
          )}
          {question.answerType == 'Multiple Choice Many' && (
            <QuestionMultiChoiceManyType
              onChangeAnwer={(e) => setIsCorrectAnswer(e)}
              question={question}
            />
          )}
          {question.answerType == 'Multiple Choice Single' && (
            <QuestionMultiChoiceSingleType
              onChangeAnwer={(e) => setIsCorrectAnswer(e)}
              question={question}
            />
          )}
          <div className={`training_quiz_question_footer`}>
            {prevQuestion && (
              <PrimaryButton
                text="Back"
                className={'back_btn'}
                onClick={() => handleGoBack()}
                noIcon
              />
            )}
            {nextQuestion ? (
              <SecondaryButton text="Next" className={'next_btn'} onClick={() => handleGoNext()} />
            ) : (
              <SecondaryButton
                text="Quiz Result"
                className={'next_btn'}
                onClick={() => handleGoResult()}
              />
            )}
          </div>
        </div>
      </div>
      <div className="training_quiz_question_right">
        <div className="training_quiz_question_right_content">
          <div className="training_quiz_question_right_title">Quiz progress</div>
          <div className="training_quiz_question_right_progress">
            {quiz.quizQuestions.map((e, key) => (
              <div
                className={`training_quiz_question_right_progress_item${
                  e.questionNumber < question.questionNumber ? ' complete' : ''
                }${e.questionNumber == question.questionNumber ? ' active' : ''}`}
                key={key}>
                {`Question ${e.questionNumber}`}
              </div>
            ))}
            <div className="training_quiz_question_right_progress_item with_icon">
              <img src={DocumentIcon} alt="" className="" />
              <span>Quiz Result</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
