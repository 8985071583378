import React from 'react';
import CorrectIcon from 'src/assets/images/training/correct-icon.svg';
import CircleRedIcon from 'src/assets/images/training/close-circle-red.svg';
import { SecondaryButton } from 'src/components/Common/Button';

const GoodJobModal = ({ visible, onCloseModal, question, handleClick }) => {
  return (
    <div
      className="training_course_lesson_check_in_good_job_modal"
      style={{ visibility: visible ? 'visible' : 'hidden' }}>
      <div
        className="training_course_lesson_check_in_good_job_modal_backdrop"
        onClick={() => onCloseModal()}
      />
      <div className="training_course_lesson_check_in_good_job_modal_body">
        <div className="training_course_lesson_check_in_good_job">
          <button
            className="training_course_lesson_check_in_good_job_modal_close_btn"
            onClick={() => onCloseModal()}>
            <img src={CircleRedIcon} alt="Close" />
          </button>
          <img
            src={CorrectIcon}
            className="training_course_lesson_check_in_good_job_icon"
            alt="Icon"
          />
          <div className="training_course_lesson_check_in_good_job_title">Good job!</div>
          <div className="training_course_lesson_check_in_good_job_description">{question}</div>
          <SecondaryButton
            className="training_course_lesson_check_in_good_job_btn"
            text="Continue"
            noIcon={true}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default GoodJobModal;
