import React from 'react';
import { useAppSelector } from 'src/hooks';
import { getQuizDetail } from 'src/store/training/trainingSlice';
import LessonItem from '../../LessonItem';

const Content = () => {
  const course = useAppSelector((state) => state.training.currentCourse);
  const quiz = useAppSelector(getQuizDetail);

  if (course) {
    return (
      <div className="training_course_dashboard">
        <div className="training_course_dashboard_content">
          <div className="training_course_dashboard_title">{course.title}</div>
          <div className="training_course_dashboard_description">{course.body}</div>
          <div className="training_course_dashboard_lessons">
            {course.lessons.map((lesson, index) => (
              <LessonItem
                id={lesson.lessonId}
                title={`Up Next: LESSON ${lesson.lessonNumber}`}
                name={lesson.title}
                type={'ongoing'}
                key={index}
                total={4}
                done={0}
              />
            ))}
            {quiz && (
              <LessonItem
                title={`${course.points} POINTS`}
                name={quiz.name}
                type={'ongoing'}
                total={4}
                done={0}
                quiz
              />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Content;
