import React from 'react';

const CategoryItem = ({ name, icon, active }) => {
  return (
    <div className={`training_layout_sidebar_category ${active ? 'active' : ''}`}>
      {icon && <img src={icon} alt="Icon" className="training_layout_sidebar_category_icon" />}
      <span className="training_layout_sidebar_category_name">{name}</span>
    </div>
  );
};

export default CategoryItem;
