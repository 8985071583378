import React, { useState } from 'react';
import Notification from '../../Notification';
import Logo from 'src/assets/images/login pages/go121-logo.png';
import BackgroundDesktop from 'src/assets/images/login pages/background-desktop.png';
import BackgroundTablet from 'src/assets/images/login pages/background-tablet.png';
import BackgroundMobile from 'src/assets/images/login pages/background-mobile.png';
import IconCall from 'src/assets/icons/icon-calls.svg';

const ForgotPasswordContent = () => {
  const [notification, setNotification] = useState({
    text: '',
    type: 'success',
    visible: false
  });

  return (
    <div className="nabar-section">
      {notification.visible && (
        <Notification
          type={notification.type}
          text={notification.text}
          onClose={() => setNotification({ ...notification, visible: false })}
        />
      )}
      <div className="forgot_password_form grid">
        <div className="forgot_password_form_left_side_form">
          <div className="forgot_password_form_container">
            <div className="forgot_password_form_header">
              <img src={Logo} alt="" className="forgot_password_form_logo" />
            </div>
            <div className="forgot_password_form_title">Forgot login details?</div>
            <div className="forgot_password_form_description">
              Forgotten your login details? No problem, please give us a call and one of our
              friendly agents will help you.
            </div>
            <div className="forgot_password_form_body">
              <div className="forgot_password_call_numbers">
                <div className="forgot_password_call_numbers_title">Call Centre Numbers</div>
                <a href="tel:0839035274">
                  <div className="forgot_password_call_numbers_number">
                    <img src={IconCall} alt="Call Icon" />
                    <span>083 903 5274 (Free on MTN)</span>
                  </div>
                </a>
                <div className="forgot_password_call_numbers_or">
                  <span>OR</span>
                </div>
                <a href="tel:0839035274">
                  <div className="forgot_password_call_numbers_number">
                    <img src={IconCall} alt="Call Icon" />
                    <span>083 903 5274 (Free on MTN)</span>
                  </div>
                </a>
              </div>
              <div className="forgot_password_operating_hours">
                <div className="forgot_password_operating_hours_title">operating hours</div>
                <div className="forgot_password_operating_hours_body">
                  <div className="forgot_password_operating_hours_days">
                    <div className="forgot_password_operating_hours_content">Monday - Friday</div>
                    <div className="forgot_password_operating_hours_content">Saturday</div>
                    <div className="forgot_password_operating_hours_content">Sunday</div>
                    <div className="forgot_password_operating_hours_content">Public Holidays</div>
                  </div>
                  <div className="forgot_password_operating_hours_times">
                    <div className="forgot_password_operating_hours_content">08h00 – 20h00</div>
                    <div className="forgot_password_operating_hours_content">09h00 – 18h00</div>
                    <div className="forgot_password_operating_hours_content">09h00 – 17h00</div>
                    <div className="forgot_password_operating_hours_content">09h00 – 17h00</div>
                  </div>
                </div>
              </div>
              <div className="forgot_password_form_footer">
                <a href="/auth/login/cellphone">Back to Log In</a>
              </div>
            </div>
          </div>
        </div>
        <div className="forgot_password_form_background_img_desktop">
          <img src={BackgroundDesktop} alt="loading" />
        </div>
        <div className="forgot_password_form_background_img_tablet">
          <img src={BackgroundTablet} alt="loading" />
        </div>
        <div className="forgot_password_form_background_img_mobile">
          <img src={BackgroundMobile} alt="loading" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordContent;
