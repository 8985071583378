import React from 'react';
import Content from 'src/components/Dashboard/Profile/Content';
import RightContent from 'src/components/Dashboard/Profile/RightContent';

const Profile = () => {
  return (
    <>
      <Content />
      <RightContent />
    </>
  );
};

export default Profile;
