import React, { useState } from 'react';
import CheckBoxItem from './CheckBoxItem';
import { useEffect } from 'react';

const QuestionMultiChoiceManyType = ({ question, onChangeAnwer }) => {
  const [selectedOption, setSelectedOption] = useState([]);

  useEffect(() => {
    handleCheckIsCorrect();
  }, [selectedOption]);

  const isSelected = (name) => {
    return selectedOption.find((e) => e == name);
  };

  const toggleCheck = (name) => {
    if (isSelected(name)) {
      setSelectedOption(selectedOption.filter((e) => e !== name));
    } else {
      setSelectedOption([...selectedOption, name]);
    }
  };

  const handleCheckIsCorrect = () => {
    let isCorrect = true;
    for (let index = 0; index < question.quizAnswerChoices.length; index++) {
      const choice = question.quizAnswerChoices[index];
      if (isSelected(choice.quizAnswerChoiceId) && !choice.isCorrect) {
        isCorrect = false;
        break;
      } else if (!isSelected(choice.quizAnswerChoiceId) && choice.isCorrect) {
        isCorrect = false;
        break;
      }
    }
    onChangeAnwer(isCorrect);
  };

  return (
    <>
      <div className="training_quiz_question_question">Choose the correct answers</div>
      <div className="training_quiz_question_description">{question.question}</div>
      <div className="training_quiz_question_chechbox_lists">
        {question.quizAnswerChoices.map((e, index) => (
          <CheckBoxItem
            name={e.quizAnswerChoiceId}
            text={e.choice}
            selected={isSelected(e.quizAnswerChoiceId)}
            onClick={() => toggleCheck(e.quizAnswerChoiceId)}
            key={index}
          />
        ))}
      </div>
    </>
  );
};

export default QuestionMultiChoiceManyType;
