import React, { useState } from 'react';
import Notification from '../../Common/Notification';
import Logo from 'src/assets/images/login pages/go121-logo.png';
import BackgroundDesktop from 'src/assets/images/login pages/background-desktop.png';
import BackgroundTablet from 'src/assets/images/login pages/background-tablet.png';
import BackgroundMobile from 'src/assets/images/login pages/background-mobile.png';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'src/utils/api';
import { useHistory } from 'react-router-dom';
import AccountDeletedModal from './AccountDeletedModal';

const FormSchema = Yup.object().shape({
  cellphone: Yup.string()
    .max(10, 'Please enter a valid 10-digit number.')
    .min(10, 'Please enter a valid 10-digit number.')
    .matches(new RegExp('[0-9]{10}'), 'Please enter a valid 10-digit number.')
    .test('no-leading-zero', 'Cellphone number should start with 0', (value) => {
      return Boolean(value && value.startsWith('0'));
    })
    .required('Please enter a valid 10-digit number.')
    .not(['0000000000'], 'Please enter a valid 10-digit number.')
});

const AccountDeletedContent = () => {
  const proxyApi = process.env.REACT_APP_PROXY_API_URL;
  const history = useHistory();
  const [loading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    text: '',
    type: 'success',
    visible: false
  });

  const formik = useFormik({
    validationSchema: FormSchema,
    initialValues: {
      cellphone: ''
    },
    onSubmit: ({ cellphone }) => {
      //   history.push("/auth/login/otp", { otpId: 121 });
      setIsLoading(true);
      axios
        .get(`${proxyApi}api/proxy_get/Agent/FindByCellphone?Cellphone=${cellphone}`)
        .then(({ data }) => {
          localStorage.setItem('user', JSON.stringify(data));
          if (data.statusId <= 4) {
            location.href = process.env.REACT_APP_CHANGE_CELLPHONE_URL;
          } else {
            if (data.errorCode === 801) {
              setNotification({
                text: 'No agent found for that cellphone number. Please try again.',
                type: 'warn',
                visible: true
              });
              setIsLoading(false);
            } else if (data.errorCode === 802) {
              if (data.statusId === 6) {
                sendOTP(data.id);
              } else if (data.statusId === 7) {
                validateAgent(data.code, data.id);
              }
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          if (error.response.status === 404) {
            setNotification({
              text: 'No agent found for that cellphone number. Please try again.',
              type: 'warn',
              visible: true
            });
          } else {
            setNotification({
              text: "Sorry, we're experiencing problems with our system. Please try again in a few minutes.",
              type: 'warn',
              visible: true
            });
          }
        });
    }
  });

  const validateAgent = (code, id) => {
    axios
      .post(`${proxyApi}api/proxy_post/Agent/${code}/ValidateAgent`)
      .then(({ data }) => {
        if (data.passwordRequired) {
          sendOTP(id);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const sendOTP = (id) => {
    axios
      .post(`${proxyApi}api/proxy_post/Agent/${id}/OtpLogin`)
      .then(({ data }) => {
        setIsLoading(false);
        history.push('/auth/login/otp', {
          otpId: data.otpId,
          agentId: id,
          phoneNumber: data.phoneNumber
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  return (
    <div className="nabar-section">
      <div className="login_cellphone_form_notification_container">
        <div className="grid">
          {notification.visible && (
            <Notification
              type={notification.type}
              text={notification.text}
              onClose={() => setNotification({ ...notification, visible: false })}
            />
          )}
        </div>
      </div>
      <div
        className={`login_cellphone_form grid ${
          notification.visible ? 'login_cellphone_form_padding' : ''
        }`}>
        <div className="login_cellphone_form_left_side_form">
          <div className="login_cellphone_form_container">
            <form onSubmit={formik.handleSubmit}>
              <div className="login_cellphone_form_header">
                <img src={Logo} alt="" className="login_cellphone_form_logo" />
                <div className="login_cellphone_form_step">AGENT</div>
              </div>
              <div className="login_cellphone_form_title">Login to your account</div>
              <div className="login_cellphone_form_body">
                <div className="login_cellphone_form_group">
                  <label
                    htmlFor=""
                    className={`login_cellphone_form_label ${
                      formik.touched.cellphone && formik.errors.cellphone ? 'error' : ''
                    }`}>
                    Cellphone number
                  </label>
                  <input
                    type="text"
                    className={`login_cellphone_form_input ${
                      formik.touched.cellphone && formik.errors.cellphone ? 'error' : ''
                    }`}
                    placeholder="Enter your cellphone number"
                    {...formik.getFieldProps('cellphone')}
                  />
                  {formik.touched.cellphone && formik.errors.cellphone && (
                    <div className="login_cellphone_form_error">{formik.errors.cellphone}</div>
                  )}
                </div>
                <div className="login_cellphone_form_group">
                  <button
                    type="submit"
                    className="login_cellphone_form_submit_btn"
                    disabled={!formik.isValid || loading}>
                    <span>Next</span>
                    {loading ? (
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 100 100"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M100 50C100 22.3913 77.6087 0 50 0C22.3913 0 0 22.3913 0 50M8.47826 50C8.47826 27.1739 26.9565 8.47826 50 8.47826C73.0435 8.47826 91.5217 27.1739 91.5217 50"
                          fill="white">
                          <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="rotate"
                            dur="1s"
                            from="0 50 50"
                            to="360 50 50"
                            repeatCount="indefinite"
                          />
                        </path>
                      </svg>
                    ) : (
                      <svg
                        width="22"
                        height="24"
                        viewBox="0 0 22 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M4.88867 12H19.1109M19.1109 12L13.4969 6M19.1109 12L13.4969 18"
                          stroke="#FFBF00"
                          strokeWidth="3"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                <div className="login_cellphone_form_footer">
                  <div className="login_cellphone_form_footer_left">
                    <a href="/auth/forgot-password">Forgot login details?</a>
                  </div>
                  <div className="login_cellphone_form_footer_right">
                    <span>Not an agent? </span>
                    <a href={process.env.REACT_APP_SIGNUP_URL}>Sign up</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="login_cellphone_form_background_img_desktop">
          <img src={BackgroundDesktop} alt="loading" />
        </div>
        <div className="login_cellphone_form_background_img_tablet">
          <img src={BackgroundTablet} alt="loading" />
        </div>
        <div className="login_cellphone_form_background_img_mobile">
          <img src={BackgroundMobile} alt="loading" />
        </div>
      </div>
      <AccountDeletedModal visible={true} />
    </div>
  );
};

export default AccountDeletedContent;
