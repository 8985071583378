import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { fetchCourseFull, fetchCourses, isLoading } from 'src/store/training/trainingSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import TrainingLayout from 'src/layout/Training';
import Content from 'src/components/Training/Course/Dashboard/Content';
import CourseDashboardSkeleton from 'src/components/Skeletons/Course/Dashboard';

const TrainingCourseDashboard = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const courses = useAppSelector((state) => state.training.courses);
  const course = useAppSelector((state) => state.training.currentCourse);
  const error = useAppSelector((state) => state.training.error);
  const loading = useAppSelector(isLoading);

  useEffect(() => {
    dispatch(fetchCourses());
  }, []);

  useEffect(() => {
    if (courses.length) {
      dispatch(fetchCourseFull(params.courseCode));
    }
  }, [courses]);

  if (error) {
    return <Redirect to={'/training-course'} />;
  }

  if (loading) {
    return <CourseDashboardSkeleton />;
  } else if (course) {
    return (
      <TrainingLayout
        title={course.name}
        breadcrumb={[
          { title: 'Training courses', path: '/training-course' },
          {
            title: course.name,
            path: `/training/course/${course.courseCode}/dashboard`
          },
          { title: 'Course Dashboard', path: '#' }
        ]}>
        <Content />
      </TrainingLayout>
    );
  } else {
    return null;
  }
};

export default TrainingCourseDashboard;
