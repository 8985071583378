import React from 'react';
import { Link } from 'react-router-dom';
import CircleRedIcon from 'src/assets/images/training/close-circle-red.svg';
import MarkerActiveIcon from 'src/assets/images/tasks/marker-active-icon.svg';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';

const KioskConfirmModal = ({ visible, onCloseModal, card }) => {
  console.log(card);
  return (
    <div className="task_find_kiosk_confirm_modal" style={{ display: visible ? 'block' : 'none' }}>
      <div className="task_find_kiosk_confirm_modal_backdrop" onClick={() => onCloseModal()} />
      <div className="task_find_kiosk_confirm_modal_body">
        <div className="task_find_kiosk_confirm_modal_content">
          <button
            className="task_find_kiosk_confirm_modal_close_btn"
            onClick={() => onCloseModal()}>
            <img src={CircleRedIcon} alt="Close" />
          </button>
          <img src={MarkerActiveIcon} alt="point" className="task_find_kiosk_confirm_modal_img" />
          <div className="task_find_kiosk_confirm_modal_title">Confirm pick up?</div>
          <div className="task_find_kiosk_confirm_modal_description">
            You have selected to collect your starter kiosk at:
          </div>
          <div className="task_find_kiosk_confirm_modal_card">
            <div className="task_find_kiosk_confirm_modal_card_title">{card?.name}</div>
            <div
              className="task_find_kiosk_confirm_modal_card_description"
              dangerouslySetInnerHTML={{ __html: card?.address }}></div>
          </div>
          <div className="task_find_kiosk_confirm_modal_actions">
            <SecondaryButton
              className="task_find_kiosk_confirm_modal_action_btn"
              text="Change Location"
              noIcon
              onClick={() => onCloseModal()}
            />
            <Link
              to={'/task/find-kiosk/complete'}
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }}>
              <PrimaryButton
                className="task_find_kiosk_confirm_modal_action_btn"
                text="Confirm selection"
                noIcon
              />
            </Link>
          </div>
          <div className="task_find_kiosk_confirm_modal_tip">
            This may take up to 2 weeks.
            <br /> Remember to take your cellphone with you that you used for registration, as you
            will be required to give an OTP to the Flash kiosk staff when they ask for it.
          </div>
        </div>
      </div>
    </div>
  );
};

export default KioskConfirmModal;
