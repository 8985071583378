export function formatPhoneNumber(phoneNumberString, includeZero = false) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  if (includeZero) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + ' ' + match[2] + ' ' + match[3];
    }
  } else {
    cleaned = '27' + cleaned.slice(1, cleaned.length);
    var match = cleaned.match(/^(\d{2})(\d{2})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4];
    }
  }
  return null;
}

export const markDownFormat = (e) => {
  const toHTML = e
    .replace(/^### (.*$)/gim, '<h3>$1</h3>') // h3 tag
    .replace(/^## (.*$)/gim, '<h2>$1</h2>') // h2 tag
    .replace(/^# (.*$)/gim, '<h1>$1</h1>') // h1 tag
    .replace(/\*\*(.*)\*\*/gim, '<strong>$1</strong>') // bold text
    .replace(/__([^_`]+)__/g, '<i>$1</i>'); // italic text
  return toHTML.trim();

  // const rules = [
  //   //header rules
  //   [/#{6}\s?([^\n]+)/g, '<h6>$1</h6>'],
  //   [/#{5}\s?([^\n]+)/g, '<h5>$1</h5>'],
  //   [/#{4}\s?([^\n]+)/g, '<h4>$1</h4>'],
  //   [/#{3}\s?([^\n]+)/g, '<h3>$1</h3>'],
  //   [/#{2}\s?([^\n]+)/g, '<h2>$1</h2>'],
  //   [/#{1}\s?([^\n]+)/g, '<h1>$1</h1>'],

  //   //bold, italics and paragragh rules
  //   [/\*\*\s?([^\n]+)\*\*/g, '<b>$1</b>'],
  //   [/\*\s?([^\n]+)\*/g, '<i>$1</i>'],
  //   [/__([^_]+)__/g, '<b>$1</b>'],
  //   [/_([^_`]+)_/g, '<i>$1</i>'],
  //   [/([^\n]+\n?)/g, '<p>$1</p>'],

  //   //links
  //   [
  //     /\[([^\]]+)\]\(([^)]+)\)/g,
  //     '<a href="$2" style="color:#2A5DB0;text-decoration: none;">$1</a>'
  //   ],

  //   //highlights
  //   [
  //     /(`)(\s?[^\n,]+\s?)(`)/g,
  //     '<a style="background-color:grey;color:black;text-decoration: none;border-radius: 3px;padding:0 2px;">$2</a>'
  //   ],

  //   //Lists
  //   [/([^\n]+)(\+)([^\n]+)/g, '<ul><li>$3</li></ul>'],
  //   [/([^\n]+)(\*)([^\n]+)/g, '<ul><li>$3</li></ul>'],

  //   //Image
  //   [/!\[([^\]]+)\]\(([^)]+)\s"([^")]+)"\)/g, '<img src="$2" alt="$1" title="$3" />']
  // ];
};
