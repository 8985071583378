import React from 'react';
import CheckGreenIcon from 'src/assets/icons/check-green.svg';
import UnCheckIcon from 'src/assets/images/uncheck.svg';

const OptionalItem = ({ active, product, onSelect }) => {
  return (
    <div
      className={`task_buy_starter_kit_optional_item ${active ? 'active' : ''}`}
      onClick={() => onSelect()}>
      <div className="task_buy_starter_kit_optional_item_header">
        <div className="task_buy_starter_kit_optional_item_name">{product.name}</div>
        <div className="task_buy_starter_kit_optional_item_price">R{product.price}</div>
      </div>
      <div className="task_buy_starter_kit_optional_item_image">
        <img src={product.image} alt="" />
      </div>
      <div className="task_buy_starter_kit_optional_item_add">
        <span>Add to Kit</span>
        <img
          src={active ? CheckGreenIcon : UnCheckIcon}
          className="task_buy_starter_kit_optional_item_add_image"
          alt=""
        />
      </div>
    </div>
  );
};

export default OptionalItem;
