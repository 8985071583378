import React from 'react';
import FooterLogo from 'src/assets/images/go-auth-footer-logo.png';

const Footer = () => {
  return (
    <div className="footer_auth">
      <div className="grid">
        <div className="footer_auth_wrapper">
          <img src={FooterLogo} alt="" className="footer_auth_logo" />
          <div className="footer_auth_content">
            <span>© 2022 Go121 powered by Flash.</span>{' '}
            <span>
              Visit the <a href="https://go121.co.za/">Go121 website</a> for more information
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
