import React, { useEffect } from 'react';
import RoundPhoneIcon from 'src/assets/icons/round-phone.svg';
import CircleRedIcon from 'src/assets/images/training/close-circle-red.svg';
import { PrimaryButton, SecondaryButton } from 'src/components/Common/Button';

const OtpNotifierModal = ({ visible, cellphone, onClose, onResend }) => {
  useEffect(() => {
    if (visible) {
      document.getElementById('root').classList.add('position-fixed');
    } else {
      document.getElementById('root').classList.remove('position-fixed');
    }
  }, [visible]);

  const onCloseModal = () => {
    onClose();
  };

  return (
    <div className="login_otp_notifier_modal" style={{ display: visible ? 'block' : 'none' }}>
      <div className="login_otp_notifier_modal_backdrop" onClick={() => onCloseModal()} />
      <div className="login_otp_notifier_modal_body">
        <div className="login_otp_notifier_modal_content">
          <button className="login_otp_notifier_modal_close_btn" onClick={() => onCloseModal()}>
            <img src={CircleRedIcon} alt="Close" />
          </button>
          <div className="login_otp_notifier_modal_title">Is your cellphone number correct?</div>
          <div className="login_otp_notifier_modal_phone_number">{cellphone}</div>
          <div className="login_otp_notifier_modal_actions">
            <PrimaryButton
              text="Yes. resend the OTP"
              noIcon
              className="login_otp_notifier_modal_yes"
              onClick={() => onResend()}
            />
            <SecondaryButton
              text="No, call us for help"
              noIcon
              className="login_otp_notifier_modal_no"
              onClick={() => onCloseModal()}
            />
          </div>
        </div>
        <div className="login_otp_notifier_modal_footer">
          <span>
            Need Help?{' '}
            <a
              href={process.env.REACT_APP_CONTACT_URL}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              Contact the Call Centre
            </a>
          </span>
          <img src={RoundPhoneIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

export default OtpNotifierModal;
