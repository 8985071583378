import React from 'react';
import { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Content from 'src/components/Training/Course/LessonComplete/Content';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchCourseFull, fetchCourses, setLesson } from 'src/store/training/trainingSlice';

const TrainingCourseLessonComplete = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const courses = useAppSelector((state) => state.training.courses);
  const course = useAppSelector((state) => state.training.currentCourse);
  const lesson = useAppSelector((state) => state.training.currentLesson);
  const error = useAppSelector((state) => state.training.error);

  useEffect(() => {
    dispatch(fetchCourses());
  }, []);

  useEffect(() => {
    if (courses.length) {
      dispatch(fetchCourseFull(params.courseCode));
    }
  }, [courses]);

  useEffect(() => {
    if (course) {
      dispatch(setLesson({ lessonId: params.lessonId }));
    }
  }, [course]);

  if (error) {
    return <Redirect to={'/training-course'} />;
  }

  if (course && lesson) {
    return <Content />;
  } else {
    return null;
  }
};

export default TrainingCourseLessonComplete;
