import React, { useEffect } from 'react';
import IconListGroupItem from './GroupItem';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useState } from 'react';
import { baseURL } from 'src/utils/api';
import { markDownFormat } from 'src/utils/formatstring';

const IconListGroupWrapper = ({ group }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    if (group.lessonStepItemGroupItems.length > 0) {
      setSelectedItem(group.lessonStepItemGroupItems[0]);
    }
  }, [group]);
  return (
    <>
      {selectedItem && (
        <>
          {group.lessonStepItemGroupItems.length == 1 ? (
            <div className="training_course_lesson_icon_list_single">
              <img
                src={`${baseURL}${selectedItem.titleIcon}`}
                alt=""
                className="training_course_lesson_icon_list_single_icon"
              />
              {selectedItem.title != '' && (
                <div className="training_course_lesson_icon_list_single_title">
                  <div dangerouslySetInnerHTML={{ __html: markDownFormat(selectedItem.title) }} />
                </div>
              )}
              {selectedItem.body != '' && (
                <div className="training_course_lesson_icon_list_single_body">
                  <div dangerouslySetInnerHTML={{ __html: markDownFormat(selectedItem.body) }} />
                </div>
              )}
              {selectedItem.tipContent != '' && (
                <pre className="training_course_lesson_icon_list_single_tip">
                  <div
                    dangerouslySetInnerHTML={{ __html: markDownFormat(selectedItem.tipContent) }}
                  />
                </pre>
              )}
            </div>
          ) : (
            <div className="training_course_lesson_icon_list_multiple">
              <div className="training_course_lesson_icon_list_multiple_items">
                {group.lessonStepItemGroupItems.map((itemGroupItem, key) => (
                  <IconListGroupItem
                    item={itemGroupItem}
                    key={key}
                    selected={
                      itemGroupItem.lessonStepItemGroupItemId ==
                      selectedItem.lessonStepItemGroupItemId
                    }
                    onClick={() => setSelectedItem(itemGroupItem)}
                  />
                ))}
              </div>
              {selectedItem.title != '' && (
                <div className="training_course_lesson_icon_list_multiple_title">
                  <div dangerouslySetInnerHTML={{ __html: markDownFormat(selectedItem.title) }} />
                </div>
              )}
              {selectedItem.body != '' && (
                <div className="training_course_lesson_icon_list_multiple_body">
                  <div dangerouslySetInnerHTML={{ __html: markDownFormat(selectedItem.body) }} />
                </div>
              )}
              {selectedItem.tipContent != '' && (
                <pre className="training_course_lesson_icon_list_multiple_tip">
                  <div
                    dangerouslySetInnerHTML={{ __html: markDownFormat(selectedItem.tipContent) }}
                  />
                </pre>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default IconListGroupWrapper;
