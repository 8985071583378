import React from 'react';
import Content from 'src/components/Dashboard/QRCode/Content';
import RightContent from 'src/components/Dashboard/QRCode/RightContent';

const QRCode = () => {
  return (
    <>
      <Content />
      <RightContent />
    </>
  );
};

export default QRCode;
