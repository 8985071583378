import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NumberOneImage from 'src/assets/images/dashboard/number-one.svg';
import TaskItem from 'src/components/Dashboard/TaskItem';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchTier } from 'src/store/task/taskSlice';

const Content = () => {
  const dispatch = useAppDispatch();
  const tier = useAppSelector((state) => state.task.tier);

  // const [tierItems, setTierItems] = useState([]);
  const [trainingPoints, setTrainingPoints] = useState(0);
  const [tierID, setTierID] = useState(1);

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    if (accessToken === 'true' && user) {
      setTierID(user.tierId);
      setTrainingPoints(user.trainingPoints);
    }
  }, []);

  useEffect(() => {
    if (tierID) dispatch(fetchTier(tierID));
  }, [tierID]);

  // const fetchTierItems = () => {
  //   axios
  //     .get(`${proxyApi}api/proxy_get/Tier/${tierID}`)
  //     .then(({ data }) => {
  //       setTierItems(data.tierItems);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <div className="dashboard_center_content">
      <div className="dashboard_center_content_statistic_container">
        <div className="dashboard_center_content_top_user_level_container">
          <div className="dashboard_center_content_top_user_level">Level {tierID - 1}</div>
        </div>
        <div className="dashboard_center_content_user_level_mobile">
          <div className="dashboard_center_content_user_level">Tier {tierID - 1}</div>
        </div>
        <div className="dashboard_center_content_statistic_container_div">
          <div className="dashboard_center_content_statistic_percent_container">
            <CircularProgress
              className="dashboard_center_content_bg_circular_pg"
              thickness={4}
              variant="determinate"
              value={100}
              size={137}
            />
            <div className="dashboard_center_content_text_pg_container">
              <div className="dashboard_center_content_statistic_percent_text">0</div>
              <div className="dashboard_center_content_statistic_percent_symbol">%</div>
            </div>
          </div>
          <div className="dashboard_center_content_static_right_container">
            <div className="dashboard_center_content_static_text_container">
              <div className="dashboard_center_content_user_level">Tier {tierID - 1}</div>
              {trainingPoints > 0 && (
                <div className="dashboard_center_content_points_earned">
                  0/{trainingPoints}
                  <br className="dashboard_center_content_mobile_enter" /> Points earned
                </div>
              )}
              <div className="dashboard_center_content_tasks_done">
                0/6
                <br className="dashboard_center_content_mobile_enter" /> Tasks done
              </div>
            </div>
            <div className="dashboard_center_content_statistic_icon_container">
              <img src={NumberOneImage} alt="Number one" />
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard_center_content_task_container">
        <div className="dashboard_center_content_your_task_container">
          <div className="dashboard_center_content_your_task_title">YOUR TASKS</div>
          {tier?.tierItems
            .filter((task) => task.isLocked == false || task.type == 'Action: ID')
            .map((task, index) => (
              <TaskItem key={index} task={task} />
            ))}
        </div>
        <div className="dashboard_center_content_locked_task_container">
          <div className="dashboard_center_content_locked_task_title">LOCKED TASKS</div>
          {tier?.tierItems
            .filter((task) => task.isLocked == true && task.type != 'Action: ID')
            .map((task, index) => (
              <TaskItem key={index} task={task} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Content;
