import React from 'react';

const IndexItem = ({ title, end }) => {
  return (
    <div>
      <div className="training_course_terms_to_know_index_item">{title}</div>
      {!end && <div className="training_course_terms_to_know_familar_item_divider" />}
    </div>
  );
};

export default IndexItem;
