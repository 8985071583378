import React from 'react';
import QuestionMarkIcon from 'src/assets/images/dashboard/question-mark.svg';
import OneForYouImage from 'src/assets/images/dashboard/for-you.svg';
import ForYouVoucherHandImage from 'src/assets/images/dashboard/for-you-voucher-hand.svg';
import ListItem from 'src/components/Common/ListItem';

const RightContent = () => {
  const benefitItems = [
    'Find customers in your community.',
    'Get info on surveys, specials and offerings from Go121 agents.',
    'Your app wallet will be loaded with R50 so you can start selling!',
    'Get a Go121 t-shirt and cap.'
  ];
  return (
    <div className="dashboard_right_content">
      <div className="dashboard_right_content_benefit">
        <div className="dashboard_right_content_header">
          <img src={QuestionMarkIcon} alt="question" />
          <span>Benefits</span>
        </div>
        <div className="dashboard_right_content_benefit_title">
          The benefits of becoming a tier 1 agent
        </div>
        <div className="dashboard_right_content_benefits">
          {benefitItems.map((item, key) => (
            <ListItem text={item} type="blue" key={key} />
          ))}
        </div>
      </div>
      <div className="dashboard_right_content_extra_container">
        <div className="dashboard_right_content_extra_header">
          <img src={OneForYouImage} alt="" />
          <span>Earn extra imali with 1ForYou!</span>
        </div>
        <div className="dashboard_right_content_extra_description">
          When your customer downloads the 1ForYou app, registers and tops up R50, you earn imali!
        </div>
        <div className="dashboard_right_content_start_training_btn" role={'button'}>
          Start training now
        </div>
        <div className="dashboard_right_content_extra_bottom_circle" />
        <img src={ForYouVoucherHandImage} alt="" className="dashboard_right_content_bottom_image" />
      </div>
    </div>
  );
};

export default RightContent;
