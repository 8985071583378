import React, { useState } from 'react';
import RadioItem from './RadioItem';

const QuestionMultiChoiceSingleType = ({ question, onChangeAnwer }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  return (
    <>
      <div className="training_quiz_question_question">Choose the correct answers</div>
      <div className="training_quiz_question_description">{question.question}</div>
      <div className="training_quiz_question_radio_lists">
        {question.quizAnswerChoices.map((e, index) => (
          <RadioItem
            name={`multi-choice-single-${question.questionNumber}`}
            text={e.choice}
            selected={e.quizAnswerChoiceId == selectedOption}
            onClick={() => {
              setSelectedOption(e.quizAnswerChoiceId);
              onChangeAnwer(e.isCorrect);
            }}
            value={e.answerNumber}
            key={index}
          />
        ))}
      </div>
    </>
  );
};

export default QuestionMultiChoiceSingleType;
