import React from 'react';
import BlueCheckIcon from 'src/assets/icons/blue-check-icon.svg';
import ListItem from 'src/components/Common/ListItem';
import { PrimaryButton } from 'src/components/Common/Button';
import InputMask from 'react-input-mask';
import OneForYouImage from 'src/assets/images/1ForYou.svg';
import FlashImage from 'src/assets/images/flash-prefix-icon.svg';
import { useState } from 'react';

const Content = () => {
  const [oneForyYouPin, setOneForyYouPin] = useState('');
  const [flashPin, setFlashPin] = useState('');
  const tips = [
    'Choose your t-shirt size.',
    'Add optional extras.',
    'Buy a 1ForYou or Flash voucher.',
    'Use the 1ForYou pin to pay for your kit online.',
    'Once the pin has been accepted you can pick up your starter kit at the nearest Go121 or Flash kiosk.'
  ];

  return (
    <div className="task_buy_starter_kit_payment_body">
      <div className="grid">
        <div className="task_buy_starter_kit_payment_main">
          <div className="task_buy_starter_kit_payment_main_container">
            <div className="task_buy_starter_kit_payment_title">Pay with 1ForYou or Flash</div>
            <div className="task_buy_starter_kit_payment_description">
              You can buy a 1ForYou or Flash voucher from any Flash Shop, PEP, PEPhome, PEPcell,
              Shoprite, Checkers, Usave, Dealz, Ackermans, OK, or House & Home store. Alternatively,
              buy a digital voucher on the TymeBank App.
            </div>
            <div className="task_buy_starter_kit_payment_pin_container">
              <div className="task_buy_starter_kit_payment_pin_input">
                <div className="task_buy_starter_kit_payment_pin_input_title">
                  Enter your 1ForYou pin here:
                </div>
                <div className="task_buy_starter_kit_payment_pin_input_container">
                  <div className="task_buy_starter_kit_payment_pin_input_image one_for_you">
                    <img src={OneForYouImage} alt="" />
                  </div>
                  <div className="task_buy_starter_kit_payment_pin_input_wrapper">
                    <InputMask
                      mask="9999-9999-9999"
                      maskChar=""
                      value={oneForyYouPin}
                      onChange={(e) => setOneForyYouPin(e.target.value)}>
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          type="text"
                          onClick={(e) => e.preventDefault()}
                          id="phoneNumber"
                          placeholder="XXXX-XXXX-XXXX"
                        />
                      )}
                    </InputMask>
                  </div>
                </div>
              </div>
              <div className="task_buy_starter_kit_payment_pin_or">OR</div>
              <div className="task_buy_starter_kit_payment_pin_input">
                <div className="task_buy_starter_kit_payment_pin_input_title">
                  Enter your Flash pin here:
                </div>
                <div className="task_buy_starter_kit_payment_pin_input_container">
                  <div className="task_buy_starter_kit_payment_pin_input_image flash">
                    <img src={FlashImage} alt="" />
                  </div>
                  <div className="task_buy_starter_kit_payment_pin_input_wrapper">
                    <InputMask
                      mask="9999-9999-9999"
                      maskChar=""
                      value={flashPin}
                      onChange={(e) => setFlashPin(e.target.value)}>
                      {(inputProps) => (
                        <input
                          {...inputProps}
                          type="text"
                          onClick={(e) => e.preventDefault()}
                          id="phoneNumber"
                          placeholder="XXXX-XXXX-XXXX"
                        />
                      )}
                    </InputMask>
                  </div>
                </div>
              </div>
            </div>
            <div className="task_buy_starter_kit_payment_footer">
              <div className="task_buy_starter_kit_payment_total_price">Total: R1750.00</div>
              <PrimaryButton text="Next" className="task_buy_starter_kit_payment_footer_btn" />
            </div>
          </div>
        </div>
        <div className="task_buy_starter_kit_payment_tips">
          <div className="task_buy_starter_kit_payment_tips_container">
            <div className="task_buy_starter_kit_payment_tip_header">
              <img src={BlueCheckIcon} alt="" />
              <span>Next steps</span>
            </div>
            <div className="task_buy_starter_kit_payment_tip_title">How to buy a kit</div>
            <div className="task_buy_starter_kit_payment_tip_lists">
              {tips.map((tip, key) => (
                <ListItem type="blue" text={tip} key={key} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
