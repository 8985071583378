import React from 'react';
import VerticalListGroupItem from './GroupItem';

const VerticalListGroupWrapper = ({ group }) => {
  return (
    <>
      {group.lessonStepItemGroupItems.map((itemGroupItem, key) => (
        <VerticalListGroupItem item={itemGroupItem} key={key} />
      ))}
    </>
  );
};

export default VerticalListGroupWrapper;
