import React, { useEffect, useState, useCallback, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { PrimaryButton } from 'src/components/Common/Button';
import RotateIcon from 'src/assets/icons/rotate.svg';

const TakeSelfieModal = ({ visible, onCloseModal, onComplete, webcamRef }) => {
  const iRef = useRef(null);
  const [rotate, setRotate] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [cropArea, setCropArea] = useState(1);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (visible) {
      iRef.current = setInterval(() => {
        setImage(webcamRef.current.getScreenshot());
      }, 100);
      document.getElementById('root').classList.add('position-fixed');
    } else {
      clearInterval(iRef.current);
      document.getElementById('root').classList.remove('position-fixed');
    }
  }, [visible]);

  console.log(image, webcamRef.current);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCropArea(croppedAreaPixels);
  }, []);

  const handleRotateLeft = () => {
    setRotate(rotate - 90);
  };

  return (
    <div className="task_take_selfie_modal" style={{ display: visible ? 'block' : 'none' }}>
      <div className="task_take_selfie_modal_backdrop" onClick={() => onCloseModal()} />
      <div className="task_take_selfie_modal_body">
        <div className="task_take_selfie_modal_selfie_container">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            rotation={rotate}
            aspect={1}
            cropShape="round"
            showGrid={false}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            objectFit="vertical-cover"
          />
        </div>
        <div className="task_take_selfie_modal_actions">
          <button className="task_take_selfie_modal_rotate_btn" onClick={() => handleRotateLeft()}>
            <img src={RotateIcon} alt="Icon" />
            <span>Rotate</span>
          </button>
          <PrimaryButton
            text="Set image"
            noIcon
            className="task_take_selfie_modal_set_btn"
            onClick={() => onComplete(image, cropArea, rotate)}
          />
          <button className="task_take_selfie_modal_cancel_btn" onClick={() => onCloseModal()}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TakeSelfieModal;
