import React from 'react';
import { useState } from 'react';
import SizeItem from './SizeItem';
import ProductImage from 'src/assets/images/tasks/Go121Mockup-1.png';
import ProductExtraImage from 'src/assets/images/tasks/optional-extra.png';
import BlueCheckIcon from 'src/assets/icons/blue-check-icon.svg';
import OptionalItem from './OptionalItem';
import ListItem from 'src/components/Common/ListItem';
import { PrimaryButton } from 'src/components/Common/Button';

const Content = () => {
  const [size, setSize] = useState('M');
  const [optional, setOptional] = useState(null);
  const includes = [
    'Go121 branded <b>cap</b>',
    'Go121 branded <b>t-shirt</b>',
    'Go121 branded <b>lanyard</b>',
    'Bulk <b>SIM pack</b>',
    'Access to the <b>app</b>',
    '<b>R50 credit</b> in your wallet so you can start selling!'
  ];

  const sizes = ['S', 'M', 'L', 'XL', 'XXL'];

  const optionals = [
    {
      id: 1,
      name: 'POS device',
      price: 1500,
      image: ProductExtraImage
    },
    {
      id: 2,
      name: 'Powerbank',
      price: 300,
      image: ProductExtraImage
    },
    {
      id: 3,
      name: 'Gazebo',
      price: 850,
      image: ProductExtraImage
    },
    {
      id: 4,
      name: 'Popup shop',
      price: 1500,
      image: ProductExtraImage
    }
  ];

  const tips = [
    'Choose your t-shirt size.',
    'Add optional extras.',
    'Buy a 1ForYou or Flash voucher.',
    'Use the 1ForYou pin to pay for your kit online.',
    'Once the pin has been accepted you can pick up your starter kit at the nearest Go121 or Flash kiosk.'
  ];

  return (
    <div className="task_buy_starter_kit_body">
      <div className="grid">
        <div className="task_buy_starter_kit_main">
          <div className="task_buy_starter_kit_main_container">
            <div className="task_buy_starter_kit_title">Buy a Go121 Starter Kit</div>
            <div className="task_buy_starter_kit_description">
              For only R250 you get access to the app, a t-shirt, cap and R50 loaded to your wallet
              so you can start selling!
            </div>
            <div className="task_buy_starter_kit_product_container">
              <div className="task_buy_starter_kit_product_image_container">
                <img src={ProductImage} alt="" className="task_buy_starter_kit_product_image" />
                <span className="task_buy_starter_kit_product_price">R250</span>
              </div>
              <div className="task_buy_starter_kit_product_include">
                <div className="task_buy_starter_kit_product_include_title">
                  Included in your starter kit:
                </div>
                <div className="task_buy_starter_kit_product_include_list">
                  {includes.map((e, index) => (
                    <ListItem text={e} key={index} type="blue" />
                  ))}
                </div>
              </div>
            </div>
            <div className="task_buy_starter_kit_step1">
              <div className="task_buy_starter_kit_step_number">Step 1</div>
              <div className="task_buy_starter_kit_step_title">Choose your t-shirt size</div>
              <div className="task_buy_starter_kit_size_container">
                {sizes.map((e, index) => (
                  <SizeItem text={e} key={index} active={e === size} onSelect={() => setSize(e)} />
                ))}
              </div>
            </div>
            <div className="task_buy_starter_kit_step2">
              <div className="task_buy_starter_kit_step_number">Step 2</div>
              <div className="task_buy_starter_kit_step_title">
                Add optional extras to your kit:
              </div>
              <div className="task_buy_starter_kit_optionals_list">
                {optionals.map((optionalProduct) => (
                  <OptionalItem
                    active={optionalProduct.id == optional?.id}
                    onSelect={() => setOptional(optionalProduct)}
                    product={optionalProduct}
                    key={optionalProduct.id}
                  />
                ))}
              </div>
            </div>
            <div className="task_buy_starter_kit_footer">
              <div className="task_buy_starter_kit_total_price">Total: R1750.00</div>
              <PrimaryButton text="Next" className="task_buy_starter_kit_footer_btn" />
            </div>
          </div>
        </div>
        <div className="task_buy_starter_kit_tips">
          <div className="task_buy_starter_kit_tips_container">
            <div className="task_buy_starter_kit_tip_header">
              <img src={BlueCheckIcon} alt="" />
              <span>Next steps</span>
            </div>
            <div className="task_buy_starter_kit_tip_title">How to buy a kit</div>
            <div className="task_buy_starter_kit_tip_lists">
              {tips.map((tip, key) => (
                <ListItem type="blue" text={tip} key={key} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
