import React, { useEffect } from 'react';
import CourseItem from './CourseItem';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchCourses, fullCourses } from 'src/store/training/trainingSlice';

const Content = () => {
  const courses = useAppSelector(fullCourses);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCourses());
  }, []);

  return (
    <div className="dashboard_trainig_center_content">
      <div className="dashboard_trainig_center_content_statistic_container">
        <div className="dashboard_trainig_center_content_statistic_left">
          <div className="dashboard_trainig_center_content_statistic_number">200/600</div>
          <div className="dashboard_trainig_center_content_statistic_description">
            Points earned
          </div>
        </div>
        <div className="dashboard_trainig_center_content_statistic_right">
          <div className="dashboard_trainig_center_content_statistic_number">2/6</div>
          <div className="dashboard_trainig_center_content_statistic_description">
            Courses completed
          </div>
        </div>
      </div>
      <div className="dashboard_trainig_center_content_items">
        <div className="dashboard_trainig_center_content_item_category">
          COMPLETE TO EARN POINTS
        </div>
        {courses.map((item, index) => {
          if (item.status != 'Completed and passed') return <CourseItem task={item} key={index} />;
        })}
        {courses.find((item) => item.status == 'Completed and passed') && (
          <div className="dashboard_trainig_center_content_item_category">COMPLETED COURSES</div>
        )}
        {courses.map((item, index) => {
          if (item.status == 'Completed and passed') return <CourseItem task={item} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Content;
