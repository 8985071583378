import React from 'react';
import { useState } from 'react';
import ChecklistGroupItem from './GroupItem';
import CheckGreenIcon from 'src/assets/icons/check-green.svg';
import { useEffect } from 'react';

const ChecklistProgressGroupWrapper = ({ group, onCompleteToggle }) => {
  const [checkedItem, setCheckedItem] = useState(-1);

  const handleCheck = (index) => {
    if (checkedItem == index) {
      setCheckedItem(index - 1);
    } else if (index > checkedItem) {
      setCheckedItem(index);
    } else {
      setCheckedItem(index - 1);
    }
  };

  useEffect(() => {
    if (checkedItem == group.lessonStepItemGroupItems.length - 1) {
      onCompleteToggle(group.lessonStepItemGroupId, true);
    } else {
      onCompleteToggle(group.lessonStepItemGroupId, false);
    }
  }, [checkedItem, group]);

  return (
    <>
      <div className="training_course_lesson_message message_success">
        <img src={CheckGreenIcon} alt="Icon" className="training_course_lesson_message_icon" />
        <pre className="training_course_lesson_message_text">Tick off the content as you go!</pre>
      </div>
      {group.lessonStepItemGroupItems.map((itemGroupItem, key) => (
        <ChecklistGroupItem
          item={itemGroupItem}
          key={key}
          onChange={() => handleCheck(key)}
          checked={key <= checkedItem}
          show={key <= checkedItem + 1}
        />
      ))}
    </>
  );
};

export default ChecklistProgressGroupWrapper;
