import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import MagnifyPrimaryIcon from 'src/assets/images/magnify-primary.svg';

const AutoComplete = (props) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const selectPlace = ({ description, ...rest }) => {
    geocodeByPlaceId(rest.place_id).then(([results]) => {
      const postalCode =
        results.address_components.find((c) => c.types.includes('postal_code')) || {};
      const country = results.address_components.find((c) => c.types.includes('country')) || {};
      const city =
        results.address_components.find(
          (c) => c.types.includes('locality') || c.types.includes('postal_town')
        ) || {};
      const location = {
        address: description || '',
        country: country.long_name ? country.long_name : '',
        city: city.long_name ? city.long_name : '',
        zip: postalCode.long_name ? postalCode.long_name : '',
        lat: results.geometry.location.lat(),
        lng: results.geometry.location.lng()
      };
      setValue(location.address);
      props.onChange(location);
    });
  };

  return (
    <GooglePlacesAutocomplete
      onSelect={(e) => {
        selectPlace(e);
      }}
      autocompletionRequest={{}}
      searchOptions={{ types: props.type ? props.type : ['locality'] }}
      renderInput={(inputProps) => (
        <>
          <div className="task_find_kiosk_googleplace_autocomplete_input_wrapper">
            <input
              {...inputProps}
              onChange={(e) => {
                setValue(e.target.value);
                inputProps.onChange(e);
              }}
              value={value}
              placeholder={props.placeholder}
            />
            <img src={MagnifyPrimaryIcon} alt="" />
          </div>
        </>
      )}
      renderSuggestions={(active, suggestions, onSelectSuggestion) => (
        <>
          {suggestions.length > 0 && (
            <div className="task_find_kiosk_googleplace_autocomplete_menu_wrapper">
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.place_id}
                  onClick={(event) => onSelectSuggestion(suggestion, event)}
                  className="task_find_kiosk_googleplace_autocomplete_menu_item">
                  <div primary={suggestion.description}>{suggestion.description}</div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    />
  );
};
export default AutoComplete;
