import React from 'react';
import CardListGroupItem from './GroupItem';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const CardListGroupWrapper = ({ group }) => {
  return (
    <>
      <div className="training_course_lesson_card_list">
        {group.lessonStepItemGroupItems.map((itemGroupItem, key) => (
          <CardListGroupItem item={itemGroupItem} key={key} />
        ))}
      </div>
      <Carousel
        className="training_course_lesson_card_list_mobile"
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        renderIndicator={(onClickHandler, isSelected, index) => (
          <div
            className={`training_course_lesson_card_list_mobile_indicator ${
              isSelected ? 'selected' : ''
            }`}
            key={index}
            onClick={onClickHandler}></div>
        )}
        centerSlidePercentage={80.8139}
        centerMode>
        {group.lessonStepItemGroupItems.map((itemGroupItem, key) => (
          <CardListGroupItem item={itemGroupItem} key={key} />
        ))}
      </Carousel>
    </>
  );
};

export default CardListGroupWrapper;
