import React from 'react';
import { Link } from 'react-router-dom';

const SidebarMenuItem = ({ path, icon, activeIcon, title, active }) => (
  <Link
    to={path}
    onClick={() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }}>
    <div className={`dashboard_layout_sidebar_menu_list ${active ? 'active' : ''}`}>
      <div className="dashboard_layout_sidebar_menu_list_icon">
        <img src={active ? activeIcon : icon} alt="Icon" />
      </div>
      <div className="dashboard_layout_sidebar_menu_list_text">{title}</div>
    </div>
  </Link>
);

export default SidebarMenuItem;
